const JST_OFFSET = 9 * 3600 * 1000;

/**
 * Date オブジェクトから日付部分を取り出して文字列 YYYY-MM-DD として返す。
 *
 * @param date
 * @returns
 */
export function getDateString(date: Date): string {
  return date.toISOString().split("T")[0];
}

/**
 * UTC から JST に変換する。
 *
 * @param date
 * @returns
 */
export function toJST(date: Date): Date {
  return new Date(date.getTime() + JST_OFFSET);
}

/**
 * JST で当日を示す文字列 YYYY-MM-DD を返す。
 *
 * @returns
 */
export function jstToday() {
  return getDateString(toJST(new Date()));
}
