import React from "react";
import ReactDOM from "react-dom";
import { setDefaultFallback } from "react-suspensive";
import { App } from "./App";
import "jquery";
import "slick-carousel";

const { hot } = module as any;

if (hot) {
  // parcel の自動リロード時のエラー回避。
  hot.accept(() => {
    setTimeout(() => {
      location.reload();
    }, 300);
  });
}

setDefaultFallback(<div></div>);

ReactDOM.render(<App />, document.getElementById("app"));
