import React from "react";
import { GuideLanguage, GuideVersion } from "./common";
import { compareBySortKey } from "./Guides-util";

/**
 * 音声ガイド編集画面 - 言語。
 */
export function GuidesEditPreviewLanguages(props: {
  guide: GuideVersion;
  onSelectLanguage: (language: GuideLanguage) => void;
}) {
  const {
    guide: { guideLanguages },
    onSelectLanguage,
  } = props;

  return (
    <ul className="language">
      {guideLanguages.sort(compareBySortKey).map((language) => (
        <li key={language.guideLanguageId}>
          <a
            href=""
            onClick={(e) => {
              e.preventDefault();
              onSelectLanguage(language);
            }}
          >
            {language.name_ja}
          </a>
        </li>
      ))}
    </ul>
  );
}
