import React from "react";
import { Link, useRouteMatch } from "react-router-dom";

export function QRNav() {
  const { url } = useRouteMatch();
  const listMatch = useRouteMatch({ path: "/:tenantId/qr", exact: true });
  const newMatch = useRouteMatch({
    path: "/:tenantId/qr/new",
    exact: true,
  });

  return (
    <div className="nav2Wrapper">
      <ul id="nav2">
        <li className={newMatch ? "active" : undefined}>
          <Link to={`${url}/new`}>新規作成</Link>
        </li>
        <li className={listMatch ? "active" : undefined}>
          <Link to={url}>一覧</Link>
        </li>
      </ul>
    </div>
  );
}
