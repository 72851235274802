import React, { useCallback, MouseEvent } from "react";
import { openPrintPage, useTenantNavigator } from "./Account-store";
import { notifyError, notifyInfo } from "./components/NotificationOverlay";
import { setProgressTask } from "./components/ProgressOverlay";
import { fetchJsonApi } from "./fetcher";
import {
  currentQRCode,
  fetchQRCode,
  saveQRCode,
  validateQRCode,
} from "./QREdit-store";
import { isPhantomId } from "./utils/phantom-id";

/**
 * QRコード編集画面 - 操作メニュー。
 */
export function QREditOperations(props: { qrCodeId: string }) {
  const tenantNavigator = useTenantNavigator();

  const printClickListener = useCallback(
    (event: MouseEvent) => {
      event.preventDefault();

      setProgressTask(
        (async () => {
          let qrCodeId = currentQRCode.value.qrCodeId;

          // 未印刷の場合のみ、印刷画面に移動するまえに現行のQRコードを保存する。
          if (!currentQRCode.value.printedAt) {
            if (
              !confirm(
                "一度印刷したQRコードの内容は変更できません。印刷しますか？"
              )
            ) {
              return;
            }

            const errors = validateQRCode();

            if (errors.length > 0) {
              notifyError({
                message: "QRコードの保存ができません。",
                listItems: errors,
              });

              return;
            }

            try {
              const item = await fetchJsonApi({
                type: "postQRCode",
                item: {
                  ...currentQRCode.value,
                  printedAt: new Date().toISOString(),
                },
              });

              qrCodeId = item.qrCodeId;
            } catch (err) {
              notifyError({
                message: "QRコードの保存が失敗しました。",
                err,
              });

              return;
            }
          }

          // 印刷画面を新規Windowで表示
          openPrintPage(`/qr/${qrCodeId}`);

          if (isPhantomId(currentQRCode.value.qrCodeId)) {
            // この時点で印刷画面が新規Windowで表示されるが、カレントウィンドウは新規作成画面のままであるため、編集画面に遷移させる。
            tenantNavigator(`/qr/${qrCodeId}`);
          } else {
            // 編集画面のメニュー等の表示を更新
            fetchQRCode(`${qrCodeId}`);
          }
        })()
      );
    },
    [props.qrCodeId]
  );

  const saveClickListener = useCallback(
    (event: MouseEvent) => {
      event.preventDefault();

      const errors = validateQRCode();

      if (errors.length > 0) {
        notifyError({
          message: "QRコードの保存ができません。",
          listItems: errors,
        });

        return;
      }

      const task = saveQRCode()
        .then(() => {
          notifyInfo({
            message: "QRコードの保存が完了しました。",
          });
          tenantNavigator("/qr");
        })
        .catch((err) => {
          notifyError({
            message: "QRコードの保存が失敗しました。",
            err,
          });
        });

      setProgressTask(task);
    },
    [tenantNavigator]
  );

  const deleteClickListener = useCallback(
    (event: MouseEvent) => {
      event.preventDefault();

      if (!confirm("QRコードを削除しますか？")) {
        return;
      }

      const task = fetchJsonApi({
        type: "deleteQRCode",
        qrCodeId: currentQRCode.value.qrCodeId,
      })
        .then(() => {
          notifyInfo({
            message: "QRコードを削除しました。",
          });
          tenantNavigator("/qr");
        })
        .catch((err) => {
          notifyError({
            message: "QRコードの削除が失敗しました。",
            err,
          });
        });

      setProgressTask(task);
    },
    [tenantNavigator]
  );

  const discardClickListener = useCallback(
    (event: MouseEvent) => {
      event.preventDefault();

      tenantNavigator("/qr");
    },
    [tenantNavigator]
  );

  return (
    <ul className="operation">
      {!currentQRCode.value.printedAt && (
        <>
          <li className="discard">
            <a onClick={saveClickListener}>保存</a>
          </li>
          <li className="discard">
            <a onClick={discardClickListener}>変更を破棄</a>
          </li>
        </>
      )}
      <li className="discard">
        <a onClick={printClickListener}>印刷</a>
      </li>
      {!isPhantomId(currentQRCode.value.qrCodeId) && (
        <li className="discard">
          <a onClick={deleteClickListener}>削除</a>
        </li>
      )}
    </ul>
  );
}
