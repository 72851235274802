import {
  AuthenticationDetails,
  CognitoUser,
  CognitoUserSession,
} from "amazon-cognito-identity-js";
import React, { useCallback, useState } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import { cognitoUserPool, setSignedInUser } from "./cognito";
import logoSvg from "./images/logo.svg";
import { getInputValues } from "./utils/dom-util";
import { isRekihaku, throwException } from "./utils/util";

/**
 * ログイン画面の状態。
 */
type LogInState =
  | {
      type: "logIn";
    }
  | {
      type: "newPasswordRequired";
      cognitoUser: CognitoUser;
      requiredAttributes: any;
    };

/**
 * ログイン画面。
 */
export function LogIn() {
  const [state, setState] = useState<LogInState>({ type: "logIn" });
  const history = useHistory();
  const location = useLocation<{ from: any }>();

  const clickListener = useCallback(() => {
    switch (state.type) {
      case "logIn": {
        const values = getInputValues<{ id: string; password: string }>({
          ids: ["id", "password"],
        });

        const cognitoUser = new CognitoUser({
          Pool: cognitoUserPool,
          Username: values.id,
        });

        const authenticationDetails = new AuthenticationDetails({
          Username: values.id,
          Password: values.password,
          ValidationData: {
            hostname: document.location.hostname,
          },
        });

        cognitoUser.authenticateUser(authenticationDetails, {
          onSuccess: onSuccess.bind(null, cognitoUser),
          onFailure,
          newPasswordRequired(_userAttributes, requiredAttributes) {
            setState({
              type: "newPasswordRequired",
              cognitoUser,
              requiredAttributes,
            });
          },
        });
        return;
      }

      case "newPasswordRequired": {
        const values = getInputValues<{ passwd1: string; passwd2: string }>({
          ids: ["passwd1", "passwd2"],
        });

        state.cognitoUser.completeNewPasswordChallenge(
          values.passwd1,
          state.requiredAttributes,
          {
            onSuccess: onSuccess.bind(null, state.cognitoUser),
            onFailure,
          }
        );
        return;
      }

      default:
        throw new Error(`Invalid state`);
    }

    function onSuccess(cognitoUser: CognitoUser, _session: CognitoUserSession) {
      setSignedInUser(cognitoUser);
      history.replace(location.state?.from || { pathname: "/" });
    }

    function onFailure(err: Error) {
      alert(err.message || JSON.stringify(err));
    }
  }, [state, history, location]);

  return (
    <div className="contentsWrapper">
      <div className="contents">
        <div className="login">
          {isRekihaku() ? (
            <span className="login_rehikaku_logo"></span>
          ) : (
            <img className="login_logo" src={logoSvg} alt="" />
          )}
          {state.type === "logIn" ? (
            <LogInForm onClick={clickListener} />
          ) : state.type === "newPasswordRequired" ? (
            <NewPasswordRequiredForm onClick={clickListener} />
          ) : (
            throwException("Invalid state")
          )}
        </div>
      </div>
    </div>
  );
}

/**
 * ログインフォーム。
 *
 * @param props
 * @returns
 */
function LogInForm(props: { onClick: () => void }) {
  return (
    <>
      <label htmlFor="id">ログインID／メールアドレス</label>
      <input type="email" name="" id="id" />
      <label htmlFor="password">パスワード</label>
      <input type="password" name="" id="password" />
      <input
        type="submit"
        value="ログイン"
        className="login"
        onClick={props.onClick}
      />
      <p className="forget">
        <Link to="./forgot-password">パスワードを忘れた方はこちらへ</Link>
      </p>
    </>
  );
}

/**
 * 新パスワード要求フォーム。
 *
 * @param props
 * @returns
 */
function NewPasswordRequiredForm(props: { onClick: () => void }) {
  return (
    <>
      <p className="mb20">新しいパスワードを入力して送信してください。</p>
      <label htmlFor="id">新しいパスワード</label>
      <input type="password" name="" id="passwd1" />
      <label htmlFor="id">新しいパスワード（再入力）</label>
      <input type="password" name="" id="passwd2" />
      <input
        type="submit"
        value="送信"
        className="login"
        onClick={props.onClick}
      />
    </>
  );
}
