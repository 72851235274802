import React from "react";
import { Suspensive, Wait } from "react-suspensive";

const progressTaskSuspensive = new Suspensive<any>(true);

/**
 * 進行中のタスクをセットする。
 *
 * タスクが完了するまで ProgressOverlay が表示され、ユーザーが他の操作ができないようにガードする。
 *
 * @param progressTask 進行中のタスクを表す Promise
 */
export function setProgressTask(progressTask: Promise<any>) {
  progressTaskSuspensive.value = progressTask;
}

/**
 * 何らかのタスクが進行中であることを示すコンポーネント。
 *
 * 画面全体に半透明の div を置くことで、ユーザーが他の操作ができないようにガードする。
 *
 * 進行中のタスクは setProgressTask 関数でセットする。
 */
export function ProgressOverlay() {
  return (
    <Wait
      suspensive={progressTaskSuspensive}
      renderAlways={(waiting) => (
        <div
          className="ProgressOverlay"
          style={{
            display: waiting ? "block" : "none",
          }}
        />
      )}
    />
  );
}
