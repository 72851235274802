import React from "react";
import { useRouteMatch } from "react-router";
import { Link } from "react-router-dom";
import { Suspensive, useLocalSuspensive, Wait } from "react-suspensive";
import { Organization } from "./common";
import { AdminQueryOrganizationsResponse } from "./common/AdminQueryOrganizationsResponse";
import { QUERY_LIMIT } from "./constants";
import { fetchJsonApi } from "./fetcher";
import { useQueryParams } from "./hooks/useQueryParams";
import { safeParseInt } from "./utils/util";

export function AdminOrganizationsList() {
  const query = useQueryParams<{ offset: string }>();

  const offset = safeParseInt(query.offset);

  const organizations = useLocalSuspensive(
    () =>
      fetchJsonApi({
        type: "adminQueryOrganizations",
        limit: QUERY_LIMIT,
        offset,
      }),
    [offset]
  );

  return (
    <>
      <table className="contentsList">
        <AdminOrganizationsListHead />
        <AdminOrganizationsListBody organizations={organizations} />
      </table>

      <Wait
        suspensive={organizations}
        render={(organizations) =>
          organizations.totalCount > 0 && (
            <div className="listPaginate">
              <span className="current">
                {offset + 1}-
                {Math.min(offset + QUERY_LIMIT, organizations.totalCount)}
              </span>
              /<span className="total">{organizations.totalCount}</span>
              <Link
                to={{ search: `?offset=${Math.max(0, offset - QUERY_LIMIT)}` }}
                className="prev"
              />
              <Link
                to={{
                  search: `?offset=${
                    offset + QUERY_LIMIT >= organizations.totalCount
                      ? offset
                      : offset + QUERY_LIMIT
                  }`,
                }}
                className="next"
              />
            </div>
          )
        }
      />
    </>
  );
}

function AdminOrganizationsListHead() {
  return (
    <thead>
      <tr>
        <th>組織名</th>
        <th>テナントID</th>
        <th>郵便番号 住所</th>
        <th>電話番号</th>
        <th></th>
        <th></th>
      </tr>
    </thead>
  );
}

function AdminOrganizationsListBody(props: {
  organizations: Suspensive<AdminQueryOrganizationsResponse>;
}) {
  const { organizations } = props;

  return (
    <Wait
      suspensive={organizations}
      render={(organizations) => (
        <tbody>
          {organizations.items.map((organization) => (
            <AdminOrganizationsListItem
              key={organization.organizationId}
              organization={organization}
            />
          ))}
        </tbody>
      )}
      fallback={<tbody></tbody>}
    />
  );
}

function AdminOrganizationsListItem(props: { organization: Organization }) {
  const { organization } = props;

  const { url } = useRouteMatch();

  return (
    <tr>
      <td>
        {organization.name_ja} <br />
        (英語表記: {organization.name_en})
      </td>
      <td>{organization.tenantId}</td>
      <td>
        〒{organization.postalCode} {organization.prefecture}{" "}
        {organization.address1} {organization.address2}
      </td>
      <td>{organization.tel}</td>
      <td>
        <Link to={`${url}/${organization.organizationId}`}>編集</Link>
      </td>
      <td>
        <Link to={`${url}/${organization.organizationId}/users`}>
          ユーザー管理
        </Link>
      </td>
    </tr>
  );
}
