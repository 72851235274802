import React, { FocusEvent } from "react";
import { setSourceOfMedia } from "./dom-util";

export function setInputRefValue(
  inputRef: React.RefObject<HTMLInputElement>,
  value: string
) {
  if (inputRef.current) {
    inputRef.current.value = value;
  }
}

export function setMediaRefFile(
  mediaRef: React.RefObject<HTMLImageElement | HTMLMediaElement>,
  file: File
) {
  const reader = new FileReader();

  reader.addEventListener("load", (event) => {
    const media = mediaRef.current;
    if (!media) {
      return;
    }

    if (typeof event.target?.result !== "string") {
      return;
    }

    if (media instanceof HTMLImageElement) {
      media.src = event.target.result;
    } else {
      setSourceOfMedia(media, event.target.result);
    }
  });

  reader.readAsDataURL(file);
}

export function preventDefault(event: React.SyntheticEvent) {
  event.preventDefault();
}

/**
 * パスワード入力欄がブラウザによって勝手に自動補完されることを防ぐために使用する。
 *
 * 本関数を `input[type="text"]` 要素のonFocusリスナーに登録することで、
 * ユーザがパスワード入力を始めたタイミングで初めて `input[type="password"]` に変える。
 */
export function changeToPasswordInput(event: FocusEvent<HTMLInputElement>) {
  event.target.type = "password";
}
