import React, { useCallback, useRef } from "react";
import { GuideLanguage, GuideVersion } from "./common";
import { MAX_LENGTH_GUIDE_LANGUAGES_NAME } from "./constants";
import { compareByPriority, compareBySortKey } from "./Guides-util";
import { useRefresh } from "./hooks/useRefresh";
import { phantomId } from "./utils/phantom-id";
import { removeElement, removeMatchedElements } from "./utils/util";
import { notifyInfo } from "./components/NotificationOverlay";
import { Input } from "./components/Input";

/**
 * 音声ガイド編集画面 - 基本設定 - 言語。
 */
export function GuidesEditBasicLanguages(props: { guide: GuideVersion }) {
  const {
    guide: { guideLanguages, contents },
  } = props;

  const refresh = useRefresh();

  const inputNameJaRef = useRef<HTMLInputElement>(null);
  const inputNameEnRef = useRef<HTMLInputElement>(null);

  // 言語追加処理。
  const addButtonListener = useCallback(
    (_event) => {
      if (!inputNameJaRef.current || !inputNameEnRef.current) {
        return;
      }

      const { value: name_ja } = inputNameJaRef.current;
      const { value: name_en } = inputNameEnRef.current;

      if (!name_ja || !name_en) {
        notifyInfo({
          message: "言語名を入力してください。英語表記も必須です。",
        });
        return;
      }

      // すでにある言語は追加しない。
      if (guideLanguages.find((language) => language.name_ja === name_ja)) {
        notifyInfo({
          message: `言語「${name_ja}」は追加済みです。`,
        });
        return;
      }

      // 表示順と優先順位で一番下になるようにする。
      const sortKey =
        guideLanguages.reduce(
          (v, language) => Math.max(v, language.sortKey),
          0
        ) + 1;
      const priority =
        guideLanguages.reduce(
          (v, language) => Math.max(v, language.priority),
          0
        ) + 1;

      const guideLanguageId = phantomId();

      guideLanguages.push({
        guideLanguageId,
        guideVersionId: phantomId(),
        name_ja,
        name_en,
        priority,
        sortKey,
      });

      // contents にも対象言語を追加。
      contents.forEach((content) => {
        content.contentByLanguages.push({
          contentByLanguageId: phantomId(),
          contentId: content.contentId,
          guideLanguageId,
          title: "",
          text: "",
          voice: null,
          voiceSize: null,
          name: name_ja,
        });
      });

      refresh();
    },
    [guideLanguages, contents]
  );

  // 言語削除処理。
  const deleteListener = useCallback(
    (guideLanguage: GuideLanguage) => {
      if (guideLanguages.length === 1) {
        notifyInfo({
          message: "全ての言語を削除することはできません。",
        });
        return;
      }

      if (!confirm("言語に紐付くコンテンツも削除されますがよろしいですか？")) {
        return;
      }

      removeElement(guideLanguages, guideLanguage);

      // contents からも対象言語削除。
      contents.forEach((content) => {
        removeMatchedElements(
          content.contentByLanguages,
          (contentByLanguage) =>
            contentByLanguage.guideLanguageId === guideLanguage.guideLanguageId
        );
      });

      refresh();
    },
    [guideLanguages, contents]
  );

  // 言語の表示順、または優先順位変更。
  // item が示す言語を insertAt が示す言語の前に移動する。
  const moveListener = useCallback(
    (item: string, insertAt: string, type: "sortKey" | "priority") => {
      guideLanguages.sort(
        type === "sortKey" ? compareBySortKey : compareByPriority
      );

      const itemIndex = guideLanguages.findIndex(
        (language) => language.name_ja === item
      );
      let insertAtIndex = guideLanguages.findIndex(
        (language) => language.name_ja === insertAt
      );

      if (itemIndex < 0 || insertAtIndex < 0) {
        return;
      }

      const tmp = guideLanguages.splice(itemIndex, 1);

      if (itemIndex < insertAtIndex) {
        insertAtIndex--;
      }

      guideLanguages.splice(insertAtIndex, 0, ...tmp);

      // 移動完了したので値を振り直す。
      guideLanguages.forEach((language, index) => {
        if (type === "sortKey") {
          language.sortKey = index;
        } else {
          language.priority = index;
        }
      });

      refresh();
    },
    [guideLanguages]
  );

  return (
    <>
      <dt>言語:</dt>
      <dd>
        <label className="guide_language must">日本語</label>
        <input
          type="text"
          name="言語"
          className="half"
          ref={inputNameJaRef}
          maxLength={MAX_LENGTH_GUIDE_LANGUAGES_NAME}
        />
        <br />
        <label className="guide_language must">英語表示</label>
        <input
          type="text"
          name="言語（英語表記）"
          className="half"
          ref={inputNameEnRef}
          maxLength={MAX_LENGTH_GUIDE_LANGUAGES_NAME}
        />
        &nbsp;
        <input type="button" value="追加" onClick={addButtonListener} />
      </dd>

      <dt>表示順</dt>
      <dd>
        <ul className="lang">
          {guideLanguages.sort(compareBySortKey).map((language) => (
            <LanguageItem
              key={language.name_ja + language.name_en}
              language={language}
              type="sortKey"
              onDelete={deleteListener}
              onMove={moveListener}
              refresh={refresh}
            />
          ))}
        </ul>
      </dd>

      <dt>優先度</dt>
      <dd>
        <ul className="lang">
          {guideLanguages.sort(compareByPriority).map((language) => (
            <LanguageItem
              key={language.name_ja + language.name_en}
              language={language}
              type="priority"
              onDelete={deleteListener}
              onMove={moveListener}
              refresh={refresh}
            />
          ))}
        </ul>
      </dd>
    </>
  );
}

function LanguageItem(props: {
  language: GuideLanguage;
  type: "sortKey" | "priority";
  onDelete: (language: GuideLanguage) => void;
  onMove: (
    item: string,
    insertAt: string,
    type: "sortKey" | "priority"
  ) => void;
  refresh: () => void;
}) {
  const { language, type, onDelete, onMove, refresh } = props;

  const dragStartListener: React.DragEventHandler = useCallback(
    (event) => {
      event.dataTransfer.setData("text", `${type}:${language.name_ja}`);

      const parent = (event.target as HTMLElement).parentElement;
      if (!parent) {
        return;
      }

      const clientRect = parent.getBoundingClientRect();

      event.dataTransfer.setDragImage(
        parent,
        event.pageX - clientRect.left - window.pageXOffset,
        event.pageY - clientRect.top - window.pageYOffset
      );
    },
    [type, language]
  );

  const dropListener: React.DragEventHandler = useCallback(
    (event) => {
      const [droppedType, droppedName] = event.dataTransfer
        .getData("text")
        .split(":");

      if (droppedType !== type) {
        return;
      }

      onMove(droppedName, language.name_ja, type);
    },
    [type, language, onMove]
  );

  const dragOverListener: React.DragEventHandler = useCallback((event) => {
    event.preventDefault();
  }, []);

  return (
    <li
      onDrop={dropListener}
      onDragOver={dragOverListener}
      style={{ height: "auto" }}
    >
      <Input type="text" obj={language} propName="name_ja" onBlur={refresh} />
      &nbsp;
      <Input type="text" obj={language} propName="name_en" onBlur={refresh} />
      <span
        className="delete"
        onClick={() => onDelete(language)}
        style={{ margin: "7px 0px" }}
      ></span>
      <span
        className="move"
        draggable
        onDragStart={dragStartListener}
        style={{ margin: "7px 0px" }}
      ></span>
    </li>
  );
}
