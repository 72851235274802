import React, { useReducer } from "react";
import { Wait } from "react-suspensive";
import { fetchOrganization, fetchUser } from "./Account-store";
import { AccountEditOrganization } from "./AccountEditOrganization";
import { AccountEditOperations } from "./AccountEditOperations";
import { AccountEditUser } from "./AccountEditUser";
import { AccountEditPassword, passwordForm } from "./AccountEditPassword";

/**
 * アカウント編集画面。
 */
export function AccountEdit() {
  const [refreshCounter, refresh] = useReducer((state) => {
    passwordForm.oldPassword = "";
    passwordForm.newPassword = "";
    passwordForm.newPasswordConfirm = "";
    return state + 1;
  }, 0);

  const organization = fetchOrganization();
  const user = fetchUser();

  return (
    <Wait
      suspensive={organization}
      render={(organization) => (
        <Wait
          suspensive={user}
          render={(user) => (
            <>
              <AccountEditOperations onDiscard={refresh} />
              <div className="contentsInfoWrapper">
                <div className="contentsBox qr">
                  <dl className="contentsInfo" key={refreshCounter}>
                    <AccountEditOrganization organization={organization} />
                    <AccountEditUser user={user} />
                    <AccountEditPassword />
                  </dl>
                </div>
              </div>
            </>
          )}
        />
      )}
    />
  );
}
