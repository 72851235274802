import React from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import { AdminOrganizationsEdit } from "./AdminOrganizationsEdit";
import { AdminOrganizationsList } from "./AdminOrganizationsList";
import { AdminUsers } from "./AdminUsers";
import { SiteFooter } from "./SiteFooter";
import { SiteHeader } from "./SiteHeader";

/**
 * 組織管理画面。
 *
 * 組織管理管理系の各画面にルーティングする。
 */
export function AdminOrganizations() {
  const { path } = useRouteMatch();

  return (
    <>
      <SiteHeader />
      <div className="contentsWrapper">
        <div className="contents">
          <Switch>
            <Route path={path} exact component={AdminOrganizationsList} />
            <Route
              path={`${path}/:organizationId/users`}
              component={AdminUsers}
            />
            <Route
              path={`${path}/:organizationId`}
              component={AdminOrganizationsEdit}
            />
          </Switch>
        </div>
      </div>
      <SiteFooter />
    </>
  );
}
