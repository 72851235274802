/**
 * クライアント上でのみ有効な ID 。
 */
class PhantomId extends Number {
  private static _next = -1;

  constructor() {
    super(PhantomId._next--);
  }

  toJSON() {
    return undefined;
  }
}

/**
 * クライアント上でのみ有効な ID を生成する。
 *
 * JSON 化してサーバーに送信する際には自動的に消える。
 *
 * @returns
 */
export function phantomId() {
  return new PhantomId() as unknown as number;
}

/**
 * クライアント上でのみ有効な ID かどうかを返す。
 *
 * @param id
 * @returns
 */
export function isPhantomId(id: number) {
  return (id as unknown) instanceof PhantomId;
}
