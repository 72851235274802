import React from "react";
import { User } from "./common";
import { Input } from "./components/Input";
import {
  MAX_LENGTH_USERS_EMAIL,
  MAX_LENGTH_USERS_NAME,
  MAX_LENGTH_USERS_NAME_KANA,
} from "./constants";

/**
 * アカウント編集画面 - ユーザー情報。
 */
export function AccountEditUser(props: { user: User }) {
  const { user } = props;

  return (
    <>
      <dt className="must">担当者名</dt>
      <dd>
        <Input
          type="text"
          className="half"
          name="お名前"
          placeholder="お名前"
          obj={user}
          propName="name"
          maxLength={MAX_LENGTH_USERS_NAME}
        />
        <br />
        <Input
          type="text"
          className="half"
          name="フリガナ"
          placeholder="フリガナ"
          obj={user}
          propName="name_kana"
          maxLength={MAX_LENGTH_USERS_NAME_KANA}
        />
      </dd>

      <dt className="must">メールアドレス</dt>
      <dd>
        <Input
          type="text"
          name="メールアドレス"
          className="full"
          obj={user}
          propName="email"
          maxLength={MAX_LENGTH_USERS_EMAIL}
        />
      </dd>
    </>
  );
}
