import React from "react";
import { Link, useRouteMatch } from "react-router-dom";

export function UsersNav() {
  const { url } = useRouteMatch();

  const userListMatch = useRouteMatch({
    path: "/:tenantId/users",
    exact: true,
  });
  const userNewMatch = useRouteMatch({
    path: "/:tenantId/users/new",
    exact: true,
  });

  return (
    <div className="nav2Wrapper">
      <ul id="nav2">
        <li className={userNewMatch ? "active" : undefined}>
          <Link to={`${url}/new`}>ユーザー新規作成</Link>
        </li>
        <li className={userListMatch ? "active" : undefined}>
          <Link to={url}>ユーザー一覧</Link>
        </li>
      </ul>
    </div>
  );
}
