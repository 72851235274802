import React, { useRef } from "react";
import { isGeneralUser } from "./Account-store";
import { Content, GuideVersion } from "./common";
import { InputFile } from "./components/InputFile";
import { ACCEPT_MOVIE, useUploader } from "./hooks/useUploader";

/**
 * 音声ガイド編集画面 - コンテンツ設定 - 動画。
 */
export function GuidesEditContentsMovie(props: {
  content: Content;
  guide: GuideVersion;
}) {
  const { content, guide } = props;

  const mediaRef = useRef<HTMLVideoElement>(null);

  const uploaderProps = useUploader({
    guideId: guide.guideId,
    obj: content,
    propName: "movie",
    mediaRef,
    accept: ACCEPT_MOVIE,
  });

  const readOnly = isGeneralUser();

  return (
    <div className="picture_content">
      <h2 className="must">動画</h2>
      <div className="indent">
        {!readOnly && <InputFile name="動画" {...uploaderProps} />}
        <video ref={mediaRef} controls />
      </div>
    </div>
  );
}
