import React, { Fragment } from "react";
import { Content, GuideVersion } from "./common";
import { Input } from "./components/Input";
import { findContentByLanguage } from "./Guides-util";

/**
 * 音声ガイド編集画面 - コンテンツ設定 - タイトル。
 */
export function GuidesEditContentsTitle(props: {
  content: Content;
  guide: GuideVersion;
}) {
  const {
    content: { contentByLanguages },
    guide: { guideLanguages },
  } = props;

  return (
    <div className="title">
      <h2>タイトル</h2>
      <dl className="title">
        {guideLanguages.map((guideLanguage) => {
          const contentByLanguage = findContentByLanguage(
            contentByLanguages,
            guideLanguage
          );

          return (
            <Fragment key={contentByLanguage.contentByLanguageId}>
              <dt>
                {guideLanguage.name_ja} ({guideLanguage.name_en})
              </dt>
              <dd>
                <Input
                  className="full"
                  type="text"
                  obj={contentByLanguage}
                  propName="title"
                />
              </dd>
            </Fragment>
          );
        })}
      </dl>
    </div>
  );
}
