import React, { Component, ErrorInfo, MouseEvent } from "react";
import { signOut } from "../cognito";

export class ErrorBoundary extends Component<{}, { error?: Error }> {
  constructor(props: any) {
    super(props);
    this.state = { error: undefined };
  }

  static getDerivedStateFromError(error: Error) {
    return { error };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error(error);
    console.error(errorInfo);
  }

  logOutClickListener(event: MouseEvent) {
    event.preventDefault();

    if (confirm("ログアウトしますか？")) {
      signOut();
      document.location.pathname = "/";
    }
  }

  render() {
    const { error } = this.state;

    if (!error) {
      return this.props.children;
    }

    return (
      <main>
        <h1>Something went wrong.</h1>
        <div>{error.message}</div>
        <div>
          <a
            className="errorboundary__logout"
            onClick={this.logOutClickListener}
          >
            ログアウト
          </a>
        </div>
      </main>
    );
  }
}
