import React from "react";
import { useRouteMatch } from "react-router";
import { Link } from "react-router-dom";
import { Suspensive, useLocalSuspensive, Wait } from "react-suspensive";
import { isGeneralUser } from "./Account-store";
import { Guide } from "./common";
import { QueryGuidesResponse } from "./common/QueryGuidesResponse";
import { DateSpan } from "./components/DateSpan";
import { QUERY_LIMIT } from "./constants";
import { fetchJsonApi } from "./fetcher";
import { getGuideStatusText } from "./Guides-util";
import { useQueryParams } from "./hooks/useQueryParams";
import { safeParseInt } from "./utils/util";

/**
 * 音声ガイド一覧画面。
 */
export function GuidesList() {
  const query = useQueryParams<{ offset: string }>();

  const offset = safeParseInt(query.offset);

  const guides = useLocalSuspensive(
    () =>
      fetchJsonApi({
        type: "queryGuides",
        limit: QUERY_LIMIT,
        offset,
      }),
    [offset]
  );

  const readOnly = isGeneralUser();

  return (
    <>
      <table className="contentsList">
        <GuidesListHead readOnly={readOnly} />
        <GuidesListBody guides={guides} readOnly={readOnly} />
      </table>

      <Wait
        suspensive={guides}
        render={(guides) =>
          guides.totalCount > 0 && (
            <div className="listPaginate">
              <span className="current">
                {offset + 1}-{Math.min(offset + QUERY_LIMIT, guides.totalCount)}
              </span>
              /<span className="total">{guides.totalCount}</span>
              <Link
                to={{ search: `?offset=${Math.max(0, offset - QUERY_LIMIT)}` }}
                className="prev"
              />
              <Link
                to={{
                  search: `?offset=${
                    offset + QUERY_LIMIT >= guides.totalCount
                      ? offset
                      : offset + QUERY_LIMIT
                  }`,
                }}
                className="next"
              />
            </div>
          )
        }
      />
    </>
  );
}

function GuidesListHead(props: { readOnly: boolean }) {
  return (
    <thead>
      <tr>
        <th>コンテンツ名</th>
        <th>コンテンツID</th>
        <th>開始日</th>
        <th>終了日</th>
        <th></th>
        {!props.readOnly && <th></th>}
        <th>ステータス</th>
      </tr>
    </thead>
  );
}

function GuidesListBody(props: {
  guides: Suspensive<QueryGuidesResponse>;
  readOnly: boolean;
}) {
  const { guides, readOnly } = props;

  return (
    <Wait
      suspensive={guides}
      render={(guides) => (
        <tbody>
          {guides.items.map((guide) => (
            <GuidesListItem
              key={guide.guideId}
              guide={guide}
              readOnly={readOnly}
            />
          ))}
        </tbody>
      )}
      fallback={<tbody></tbody>}
    />
  );
}

function GuidesListItem(props: { guide: Guide; readOnly: boolean }) {
  const { guide, readOnly } = props;

  const { url } = useRouteMatch();

  return (
    <tr>
      <td>{guide.name_ja}</td>
      <td>{guide.auxGuideId}</td>
      {guide.periodBegin && guide.periodEnd ? (
        <>
          <td>
            <DateSpan date={guide.periodBegin} />
          </td>
          <td>
            <DateSpan date={guide.periodEnd} />
          </td>
        </>
      ) : (
        <>
          <td>-</td>
          <td>無期限</td>
        </>
      )}
      <td>
        <Link to={`${url}/${guide.guideId}`}>{readOnly ? "閲覧" : "編集"}</Link>
      </td>
      {!readOnly && (
        <td>
          <Link to={`${url}/new?copy=${guide.guideId}`}>
            コピーして新規作成
          </Link>
        </td>
      )}
      <td>
        <span className={guide.status === "opened" ? "publish" : undefined}>
          {getGuideStatusText(guide.status)}
        </span>
      </td>
    </tr>
  );
}
