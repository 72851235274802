import React, { useCallback, MouseEvent, useRef, ChangeEvent } from "react";
import { isGeneralUser, useTenantNavigator } from "./Account-store";
import { notifyError, notifyInfo } from "./components/NotificationOverlay";
import { setProgressTask } from "./components/ProgressOverlay";
import { fetchJsonApi } from "./fetcher";
import { EditScope } from "./GuidesEdit";
import {
  currentGuide,
  currentGuideHasChanged,
  exportGuide,
  importGuide,
  saveAndPublish,
  validateBasicSettings,
} from "./GuidesEdit-store";
import { isPhantomId } from "./utils/phantom-id";

/**
 * 音声ガイド編集画面 - 操作メニュー。
 */
export function GuidesEditOperations(props: {
  editScope: EditScope;
  setEditScope: (editScope: EditScope) => void;
}) {
  const { editScope, setEditScope } = props;

  const tenantNavigator = useTenantNavigator();

  const basicClickListener = useCallback(
    (event: MouseEvent) => {
      event.preventDefault();
      setEditScope("basic");
    },
    [setEditScope]
  );

  const contentsClickListener = useCallback(
    (event: MouseEvent) => {
      event.preventDefault();

      const errors = validateBasicSettings();

      if (errors.length > 0) {
        notifyError({
          message: "音声ガイドの基本設定に不備があります。",
          listItems: errors,
        });
        return;
      }

      setEditScope("contents");
    },
    [setEditScope]
  );

  const previewClickListener = useCallback(
    (event: MouseEvent) => {
      event.preventDefault();
      setEditScope("preview");
    },
    [setEditScope]
  );

  const publishClickListener = useCallback(
    (event: MouseEvent) => {
      event.preventDefault();

      const task = saveAndPublish({ publish: true })
        .then((result) => {
          if (Array.isArray(result)) {
            notifyError({
              message: "音声ガイドに公開用のコンテンツとして不備があります。",
              listItems: result,
            });
            return;
          }

          notifyInfo({
            message: "音声ガイドを公開しました。",
          });
          tenantNavigator("/guides");
        })
        .catch((err) => {
          notifyError({
            message: "音声ガイドの公開に失敗しました。",
            err,
          });
        });

      setProgressTask(task);
    },
    [tenantNavigator]
  );

  const saveClickListener = useCallback(
    (event: MouseEvent) => {
      event.preventDefault();

      const task = saveAndPublish()
        .then((result) => {
          if (Array.isArray(result)) {
            notifyError({
              message: "音声ガイドに公開用のコンテンツとして不備があります。",
              listItems: result,
            });
            return;
          }

          notifyInfo({
            message: "音声ガイドの保存が完了しました。",
          });
          tenantNavigator("/guides");
        })
        .catch((err) => {
          if (err.errorCode === "GuideVersionConflict") {
            err.message = "他の端末から編集されています。";
          }

          notifyError({
            message: "音声ガイドの保存が失敗しました。",
            err,
          });
        });

      setProgressTask(task);
    },
    [tenantNavigator]
  );

  const deleteClickListener = useCallback(
    (event: MouseEvent) => {
      event.preventDefault();

      // 新規作成の場合は何もしない。
      if (isPhantomId(currentGuide.value.guideId)) {
        return;
      }

      if (!confirm("音声ガイドを削除しますか？")) {
        return;
      }

      const task = fetchJsonApi({
        type: "deleteGuide",
        guideId: currentGuide.value.guideId,
      })
        .then(() => {
          notifyInfo({
            message: "音声ガイドを削除しました。",
          });
          tenantNavigator("/guides");
        })
        .catch((err) => {
          notifyError({
            message: "音声ガイドの削除が失敗しました。",
            err,
          });
        });

      setProgressTask(task);
    },
    [tenantNavigator]
  );

  const discardClickListener = useCallback(
    (event: MouseEvent) => {
      event.preventDefault();

      tenantNavigator("/guides");
    },
    [tenantNavigator]
  );

  const importInputRef = useRef<HTMLInputElement>(null);

  const importClickListener = useCallback(
    (event: MouseEvent) => {
      event.preventDefault();

      if (importInputRef.current) {
        importInputRef.current.click();
      }
    },
    [importInputRef]
  );

  const importChangeListener = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const task = importGuide(event.currentTarget.files).catch((err) => {
        notifyError({
          message: "音声ガイドのインポートが失敗しました。",
          err,
        });
      });

      setProgressTask(task);
    },
    []
  );

  const exportClickListener = useCallback((event: MouseEvent) => {
    event.preventDefault();

    if (currentGuideHasChanged()) {
      notifyError({
        message: "エクスポート前に編集中の内容を保存、または破棄してください。",
      });
    }

    const task = exportGuide()
      .then((res) => {
        window.open(res.url);
      })
      .catch((err) => {
        notifyError({
          message: "音声ガイドのエクスポートが失敗しました。",
          err,
        });
      });

    setProgressTask(task);
  }, []);

  const readOnly = isGeneralUser();

  return (
    <div className="operationWrapper">
      <ul className="operation">
        <li className={editScope === "basic" ? "save" : "discard"}>
          <a onClick={basicClickListener}>&#10102; 基本設定</a>
        </li>
        <li className={editScope === "contents" ? "save" : "discard"}>
          <a onClick={contentsClickListener}>&#10103; コンテンツ設定</a>
        </li>
        <li className={editScope === "preview" ? "save" : "discard"}>
          <a onClick={previewClickListener}>&#10104; プレビュー</a>
        </li>
        {!readOnly && (
          <li className="discard">
            <a onClick={publishClickListener}>&#10105; 公開</a>
          </li>
        )}
      </ul>
      {!readOnly && (
        <div>
          <ul className="operation2">
            <li className="discard">
              <a onClick={saveClickListener}>保存</a>
            </li>
            <li className="discard">
              <a onClick={deleteClickListener}>削除</a>
            </li>
            <li className="discard">
              <a onClick={discardClickListener}>変更を破棄</a>
            </li>
          </ul>
          <ul className="operation2">
            <li className="discard">
              <a onClick={importClickListener}>インポート</a>
              <input
                type="file"
                ref={importInputRef}
                onChange={importChangeListener}
                style={{ display: "none" }}
                /* @ts-expect-error */
                directory=""
                webkitdirectory=""
              />
            </li>
            {!isPhantomId(currentGuide.value.guideId) && (
              <li className="discard">
                <a onClick={exportClickListener}>エクスポート</a>
              </li>
            )}
          </ul>
        </div>
      )}
    </div>
  );
}
