import React from "react";
import { useParams } from "react-router-dom";
import { useSuspensive, Wait } from "react-suspensive";
import { clearUser, fetchUser } from "./UsersEdit-store";
import { UsersEditOperations } from "./UsersEditOperations";
import { UsersEditUser } from "./UsersEditUser";

export function UsersEdit() {
  const { userId } = useParams<{ userId: string }>();

  const user = useSuspensive(() => {
    if (userId === "new") {
      return clearUser();
    } else {
      return fetchUser(userId);
    }
  }, [userId]);

  return (
    <Wait
      suspensive={user}
      render={(user) => (
        <>
          <UsersEditOperations />

          <div className="contentsInfoWrapper">
            <div className="contentsBox qr">
              <dl className="contentsInfo">
                <UsersEditUser user={user} />
              </dl>
            </div>
          </div>
        </>
      )}
    />
  );
}
