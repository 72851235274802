import React, { ChangeEvent, useCallback } from "react";
import { GuideVersion } from "./common";

/**
 * 音声ガイド編集画面 - 基本設定 - 配信方法。
 */
export function GuidesEditBasicDelivery(props: { guide: GuideVersion }) {
  const { guide } = props;

  const changeListener = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target;
      guide.streaming = value === "1" ? 1 : 0;
    },
    [guide]
  );

  return (
    <>
      <dt className="must">配信方法</dt>
      <dd onChange={changeListener}>
        <input
          type="radio"
          name="配信方法"
          value="1"
          id="delevery1"
          defaultChecked={guide.streaming === 1}
        />
        <label htmlFor="delevery1">ストリーミング再生</label>
        <input
          type="radio"
          name="配信方法"
          value="0"
          id="delevery0"
          defaultChecked={guide.streaming === 0}
        />
        <label htmlFor="delevery0">ダウンロード</label>
        <br />
        ※配信方法はコンテンツ毎に設定変更可能です
      </dd>
    </>
  );
}
