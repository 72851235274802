export const MAX_LENGTH_CONTENTS_BY_LANGUAGE_TITLE = 512;
export const MAX_LENGTH_GUIDE_LANGUAGES_NAME = 45;
export const MAX_LENGTH_GUIDES_NAME = 512;
export const MAX_LENGTH_GUIDES_AUX_GUIDE_ID = 32;
export const MAX_LENGTH_GUIDES_LIMIT_BY_PASSWORD = 45;
export const MAX_LENGTH_ORGANIZATIONS_NAME = 128;
export const MAX_LENGTH_ORGANIZATIONS_DESCRIPTION = 1024;
export const MAX_LENGTH_ORGANIZATIONS_POSTAL_CODE = 7;
export const MAX_LENGTH_ORGANIZATIONS_PREFECTURE = 4;
export const MAX_LENGTH_ORGANIZATIONS_ADDRESS1 = 128;
export const MAX_LENGTH_ORGANIZATIONS_ADDRESS2 = 128;
export const MAX_LENGTH_ORGANIZATIONS_TEL = 15;
export const MAX_LENGTH_ORGANIZATIONS_SSID = 255;
export const MAX_LENGTH_QRCODES_NAME = 255;
export const MAX_LENGTH_QRCODES_AUX_GUIDE_ID = 32;
export const MAX_LENGTH_QRCODES_GROUP_NAME = 255;
export const MAX_LENGTH_QRCODES_FACILITY_NAME = 255;
export const MAX_LENGTH_QRCODES_DESCRIPTION1 = 1024;
export const MAX_LENGTH_QRCODES_DESCRIPTION2 = 1024;
export const MAX_LENGTH_USERS_NAME = 255;
export const MAX_LENGTH_USERS_NAME_KANA = 255;
export const MAX_LENGTH_USERS_EMAIL = 255;

export const QUERY_LIMIT = 20;

/**
 * 歴博用サブドメイン名。
 */
export const REKIHAKU_SUBDOMAIN_NAME = {
  prod: "rekihaku",
  it: "rekihaku-it",
  dev: "rekihaku-dev",
}[process.env.GLOBALGUIDE_ENV!];
