import React, { useCallback, useState } from "react";
import { Prompt, useParams } from "react-router-dom";
import { useSuspensive, Wait } from "react-suspensive";
import {
  clearGuide,
  currentGuideHasChanged,
  fetchGuide,
} from "./GuidesEdit-store";
import { GuidesEditBasic } from "./GuidesEditBasic";
import { GuidesEditContents } from "./GuidesEditContents";
import { GuidesEditOperations } from "./GuidesEditOperations";
import { GuidesEditPreview } from "./GuidesEditPreview";
import { useQueryParams } from "./hooks/useQueryParams";

/**
 * 編集画面で表示中の編集対象。
 */
export type EditScope = "basic" | "contents" | "preview";

/**
 * 音声ガイド編集画面。
 */
export function GuidesEdit() {
  const [editScope, setEditScope] = useState<EditScope>("basic");

  const { guideId } = useParams<{ guideId: string }>();
  const { copy } = useQueryParams<{ copy: string }>();

  const guide = useSuspensive(() => {
    if (guideId === "new") {
      if (copy) {
        return fetchGuide(copy, true);
      } else {
        return clearGuide();
      }
    } else {
      return fetchGuide(guideId);
    }
  }, [guideId, copy]);

  const [initialSlide, setInitialSlide] =
    useState<number | undefined>(undefined);

  const showPreview = useCallback(
    (initialSlide: number) => {
      setInitialSlide(initialSlide);
      setEditScope("preview");
    },
    [setInitialSlide]
  );

  return (
    <Wait
      suspensive={guide}
      render={(guide) => (
        <>
          <Prompt
            message={() => {
              if (currentGuideHasChanged()) {
                return "保存されていない変更があります。変更を破棄しますか？";
              }

              return true;
            }}
          />
          <GuidesEditOperations
            editScope={editScope}
            setEditScope={setEditScope}
          />
          {editScope === "basic" && <GuidesEditBasic guide={guide} />}
          {editScope === "contents" && (
            <GuidesEditContents guide={guide} showPreview={showPreview} />
          )}
          {editScope === "preview" && (
            <GuidesEditPreview guide={guide} initialSlide={initialSlide} />
          )}
        </>
      )}
    />
  );
}
