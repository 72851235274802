import React, {
  ChangeEvent,
  Ref,
  useEffect,
  useImperativeHandle,
  useReducer,
  useRef,
  useState,
} from "react";
import { Content, GuideVersion } from "./common";
import { GuidesEditContentsBeacon } from "./GuidesEditContentsBeacon";
import { GuidesEditContentsGps } from "./GuidesEditContentsGps";
import { GuidesEditContentsImage } from "./GuidesEditContentsImage";
import { GuidesEditContentsMovie } from "./GuidesEditContentsMovie";
import { GuidesEditContentsText } from "./GuidesEditContentsText";
import { GuidesEditContentsVoice } from "./GuidesEditContentsVoice";
import $ from "jquery";
import { GuidesEditContentsDelivery } from "./GuidesEditContentsDelivery";
import { fetchContentDefaultImageUrl } from "./Account-store";
import { Wait } from "react-suspensive";

/**
 * コンテンツ詳細コンポーネントに対するコマンドの定義。
 */
export interface GuidesEditContentsItemDetailCommands {
  show(visible: boolean): void;
}

/**
 * 音声ガイド編集画面 - コンテンツ設定 - コンテンツ - 詳細。
 *
 * コンテンツ設定画面の１コンテンツ内の詳細部分。
 * GuidesEditContentsItem コンポーネント上の「閉じる」ボタンで
 * 非表示にすることが可能。
 *
 * 表示／非表示制御するためのコマンドを props.commandsRef 経由で提供する。
 *
 * 非表示にした際に DOM 要素自体を削除することで audio, movie 要素を節約する。
 *
 * スクロールアウトした場合は、つっかえ棒となるダミーの div 要素に中身を
 * 置き換えることで audio, movie 要素を節約する。
 */
export function GuidesEditContentsItemDetail(props: {
  content: Content;
  guide: GuideVersion;
  commandsRef: Ref<GuidesEditContentsItemDetailCommands>;
}) {
  const { content, guide, commandsRef } = props;

  const [movieEnabled, setMovieEnabled] = useReducer(
    (_: boolean, event: ChangeEvent<HTMLInputElement>) => {
      return event.target.value === "movie";
    },
    content,
    (content) => content.movie !== null
  );

  const contentsOptionRef = useRef<HTMLDivElement>(null);

  const [contentsOptionVisible, setContentsOptionVisible] = useState(true);

  useImperativeHandle(commandsRef, () => ({
    show(visible: boolean) {
      const contentsOption = contentsOptionRef.current;
      if (!contentsOption) {
        return;
      }

      if (visible) {
        // 表示アニメーション開始とともに、DOM 要素を追加するため
        // state を変更する。
        $(contentsOption).show(100);
        setContentsOptionVisible(visible);
      } else {
        // 非表示アニメーション開始する。
        // アニメーション完了時に、DOM 要素自体を削除するため state を変更する。
        $(contentsOption).hide(100, () => {
          setContentsOptionVisible(visible);
        });
      }
    },
  }));

  // つっかえ棒の表示状態。
  const [tensionRodVisible, setTensionRodVisible] = useState(false);
  // つっかえ棒の高さ。
  const [tensionRodHeight, setTensionRodHeight] = useState(0);

  // スクロールに応じて中身をつっかえ棒に置き換えるための処理。
  useEffect(() => {
    const contentsOption = contentsOptionRef.current;
    if (!contentsOption) {
      return;
    }

    // つっかえ棒の高さを現在表示しているコンテンツの高さと同じにセット。
    setTensionRodHeight(contentsOption.clientHeight);

    // スクロールイベントハンドラー。
    // 一定時間のスロットリングをする。
    let lastTime = Date.now();
    const listener = (event: Event) => {
      const now = Date.now();
      if (now - lastTime < 100) {
        return;
      }
      lastTime = now;

      // 現在の Bounding Box から Viewport 内に表示されているかどうかを判定する。
      // Viewport の外にスクロールアウトしていた場合、コンテンツの代わりにつっかえ棒を表示するよう状態変更。
      //
      // 再表示に少し時間がかかるため Viewport 内かどうかの判定に GAP を持たせて
      // 早めにコンテンツを準備する。
      const rect = contentsOption.getBoundingClientRect();
      const gap = 200;
      setTensionRodVisible(
        rect.bottom <= -gap ||
          rect.top >=
            gap + (window.innerHeight || document.documentElement.clientHeight)
      );
    };

    window.addEventListener("scroll", listener);
    return () => {
      window.removeEventListener("scroll", listener);
    };
  }, [contentsOptionRef, setTensionRodHeight, setTensionRodVisible]);

  const typeId = `content-${content.contentId}-type`;
  const voiceId = `content-${content.contentId}-voice`;
  const movieId = `content-${content.contentId}-movie`;

  const contentDefaultImageUrl = fetchContentDefaultImageUrl();

  return (
    <div className="contentsOption" ref={contentsOptionRef}>
      {tensionRodVisible && <div style={{ height: tensionRodHeight }} />}
      {!tensionRodVisible && contentsOptionVisible && (
        <>
          <h2 className="must">コンテンツ</h2>
          <dl className="indent">
            <dt></dt>
            <dd>
              <input
                type="radio"
                id={voiceId}
                name={typeId}
                value="voice"
                checked={!movieEnabled}
                onChange={setMovieEnabled}
              />
              <label htmlFor={voiceId}>音声</label>
              <input
                type="radio"
                id={movieId}
                name={typeId}
                value="movie"
                checked={movieEnabled}
                onChange={setMovieEnabled}
              />
              <label htmlFor={movieId}>動画</label>
            </dd>
          </dl>

          {movieEnabled ? (
            <>
              <GuidesEditContentsMovie content={content} guide={guide} />
              <GuidesEditContentsDelivery content={content} />
            </>
          ) : (
            <>
              <GuidesEditContentsVoice content={content} guide={guide} />
              <GuidesEditContentsDelivery content={content} />
              <Wait
                suspensive={contentDefaultImageUrl}
                render={(contentDefaultImageUrl) => (
                  <GuidesEditContentsImage
                    content={content}
                    guide={guide}
                    defaultImageUrl={contentDefaultImageUrl}
                  />
                )}
              />
            </>
          )}

          <GuidesEditContentsText content={content} guide={guide} />
          {!!guide.playOnGps && (
            <GuidesEditContentsGps content={content} guide={guide} />
          )}
          {!!guide.playOnBeacon && (
            <GuidesEditContentsBeacon content={content} guide={guide} />
          )}
        </>
      )}
    </div>
  );
}
