import React from "react";
import { Prompt, useParams } from "react-router-dom";
import { useSuspensive, Wait } from "react-suspensive";
import { Input } from "./components/Input";
import { TextArea } from "./components/TextArea";
import { useQueryParams } from "./hooks/useQueryParams";
import {
  clearQRCode,
  currentQRCodeHasChanged,
  fetchQRCode,
} from "./QREdit-store";
import { QREditGuide } from "./QREditGuide";
import { QREditImage } from "./QREditImage";
import { QREditKind } from "./QREditKind";
import { QREditOperations } from "./QREditOperations";

/**
 * QRコード編集画面。
 */
export function QREdit() {
  const { qrCodeId } = useParams<{ qrCodeId: string }>();
  const { copy } = useQueryParams<{ copy: string }>();

  const qrCode = useSuspensive(() => {
    if (qrCodeId === "new") {
      if (copy) {
        return fetchQRCode(copy, true);
      } else {
        return clearQRCode();
      }
    } else {
      return fetchQRCode(qrCodeId);
    }
  }, [qrCodeId, copy]);

  return (
    <Wait
      suspensive={qrCode}
      render={(qrCode) => (
        <>
          <Prompt
            message={() => {
              if (currentQRCodeHasChanged()) {
                return "保存されていない変更があります。変更を破棄しますか？";
              }

              return true;
            }}
          />
          <QREditOperations qrCodeId={qrCodeId} />
          <div className="contentsInfoWrapper">
            <div className="contentsBox qr">
              <dl className="contentsInfo">
                {qrCode.printedAt ? (
                  <p className="qrcode_attention">
                    ※
                    QRコードは印刷済みであるため内容を変更できません。印刷は可能です。
                  </p>
                ) : (
                  ""
                )}

                <dt className="must">QRコード名</dt>
                <dd>
                  <Input
                    type="text"
                    name="QRコード名"
                    className="full"
                    obj={qrCode}
                    propName="name"
                  />
                </dd>

                <dt>QRコードID</dt>
                <dd>
                  <Input
                    type="text"
                    name="QRコードID"
                    className="full"
                    obj={qrCode}
                    propName="auxQRCodeId"
                  />
                </dd>

                <QREditGuide qrCode={qrCode} />

                <QREditKind qrCode={qrCode} />

                <dt className="must">有効期限</dt>
                <dd>
                  <Input
                    type="date"
                    name="利用開始日"
                    id="datepicker1"
                    placeholder="カレンダーを表示"
                    autoComplete="off"
                    className="calendar w45"
                    obj={qrCode}
                    propName="periodBegin"
                  />
                  &nbsp;
                  <span className="nyoro">～</span>
                  &nbsp;
                  <Input
                    type="date"
                    name="利用終了日"
                    id="datepicker2"
                    placeholder="カレンダーを表示"
                    autoComplete="off"
                    className="calendar w45"
                    obj={qrCode}
                    propName="periodEnd"
                  />
                </dd>

                <dt className="must">人数</dt>
                <dd>
                  <Input
                    type="number"
                    name="枚数"
                    min={1}
                    className="half"
                    obj={qrCode}
                    propName="numbers"
                  />
                  <span className="ml10">人</span>
                </dd>

                <dt className="must">施設名</dt>
                <dd>
                  <Input
                    type="text"
                    name="施設名"
                    className="full"
                    obj={qrCode}
                    propName="facilityName"
                  />
                </dd>

                <QREditImage qrCode={qrCode} />

                <dt>説明文１</dt>
                <dd>
                  <TextArea
                    name="日本語"
                    obj={qrCode}
                    propName="description_ja"
                  />
                </dd>
                <dt>説明文２</dt>
                <dd>
                  <TextArea
                    name="英語"
                    obj={qrCode}
                    propName="description_en"
                  />
                </dd>
              </dl>
            </div>
          </div>
        </>
      )}
    />
  );
}
