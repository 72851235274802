import React from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import { SiteFooter } from "./SiteFooter";
import { SiteHeader } from "./SiteHeader";
import { UsersEdit } from "./UsersEdit";
import { UsersList } from "./UsersList";

/**
 * ユーザー管理画面。
 *
 * ユーザー管理管理系の各画面にルーティングする。
 */
export function Users() {
  const { path } = useRouteMatch();

  return (
    <>
      <SiteHeader />

      <div className="contentsWrapper">
        <div className="contents">
          <Switch>
            <Route path={path} exact component={UsersList} />
            <Route path={`${path}/:userId`} component={UsersEdit} />
          </Switch>
        </div>
      </div>

      <SiteFooter />
    </>
  );
}
