import React, {
  useCallback,
  useMemo,
  useReducer,
  useRef,
  useState,
} from "react";
import { Content, ContentByLanguage, GuideVersion } from "./common";
import { compareBySortKey, getGuideStatusText } from "./Guides-util";
import { useRefresh } from "./hooks/useRefresh";
import { phantomId } from "./utils/phantom-id";
import { GuidesEditContentsItem } from "./GuidesEditContentsItem";

const CONTENTS_PER_PAGE = 10;

/**
 * 音声ガイド編集画面 - コンテンツ設定。
 */
export function GuidesEditContents(props: {
  guide: GuideVersion;
  showPreview: (initialSlide: number) => void;
}) {
  const { guide, showPreview } = props;

  const refresh = useRefresh();

  // ページネーション関連。
  const [currentPage, setCurrentPage] = useState(0);
  const pages =
    ((guide.contents.length + CONTENTS_PER_PAGE - 1) / CONTENTS_PER_PAGE) | 0;
  const contentsBeginIndex = currentPage * CONTENTS_PER_PAGE;
  const contentsEndIndex = Math.min(
    contentsBeginIndex + CONTENTS_PER_PAGE,
    guide.contents.length
  );
  const lastPage = currentPage === pages - 1;

  // 言語を表示順にソート。
  // （基本設定で優先順位ソートされてるため）
  useMemo(() => guide.guideLanguages.sort(compareBySortKey), []);

  // 「ガイド番号順にソート」ボタン押下時
  const sortButtonListener = useCallback(() => {
    guide.contents.sort((lhs, rhs) => lhs.guideNumber - rhs.guideNumber);
    refresh();
  }, [guide.contents, refresh]);

  // 「コンテンツを追加する」ボタン押下時
  const addContentListener = useCallback(() => {
    const contentId = phantomId();

    const guideNumber = guide.contents.length
      ? guide.contents[guide.contents.length - 1].guideNumber + 1
      : 1;

    const contentByLanguages = guide.guideLanguages.map(
      (guideLanguage): ContentByLanguage => ({
        contentByLanguageId: phantomId(),
        contentId,
        guideLanguageId: guideLanguage.guideLanguageId,
        title: "",
        text: "",
        voice: null,
        voiceSize: null,
        name: guideLanguage.name_ja,
      })
    );

    guide.contents.push({
      contentId,
      guideVersionId: guide.guideVersionId,
      guideNumber,
      image: null,
      imageSize: null,
      movie: null,
      movieSize: null,
      textVisible: 1,
      gpsPlayLimit: null,
      gpsLatitude: null,
      gpsLongitude: null,
      gpsRadius: null,
      gpsLatitude1: null,
      gpsLongitude1: null,
      gpsRadius1: null,
      gpsLatitude2: null,
      gpsLongitude2: null,
      gpsRadius2: null,
      gpsLatitude3: null,
      gpsLongitude3: null,
      gpsRadius3: null,
      beaconPlayLimit: null,
      beaconId: null,
      beaconStrength: null,
      beaconId1: null,
      beaconStrength1: null,
      beaconId2: null,
      beaconStrength2: null,
      beaconId3: null,
      beaconStrength3: null,
      contentByLanguages,
      streaming: guide.streaming,
    });

    // 最終ページでコンテンツが最大まであった時に追加した場合は、次のページに移動した上でスクロール。
    // それ以外は画面再描画。
    if (
      lastPage &&
      contentsEndIndex - contentsBeginIndex === CONTENTS_PER_PAGE
    ) {
      setCurrentPage((pageIndex) => pageIndex + 1);
      document.querySelector(".contentsBox")?.scrollIntoView();
    } else {
      refresh();
    }
  }, [
    guide,
    refresh,
    lastPage,
    contentsBeginIndex,
    contentsEndIndex,
    setCurrentPage,
  ]);

  // コンテンツの削除
  const deleteListener = useCallback(
    (content: Content) => {
      const index = guide.contents.indexOf(content);

      if (index < 0) {
        return;
      }

      guide.contents.splice(index, 1);

      // ２ページ目以降で最終ページの最後のコンテンツ削除した場合は、前のページに移動。
      // それ以外は画面再描画。
      if (
        contentsBeginIndex > 0 &&
        lastPage &&
        contentsEndIndex - contentsBeginIndex === 1
      ) {
        setCurrentPage((pageIndex) => pageIndex - 1);
      } else {
        refresh();
      }
    },
    [
      guide,
      refresh,
      lastPage,
      contentsBeginIndex,
      contentsEndIndex,
      setCurrentPage,
    ]
  );

  //
  // キーワード検索。
  //
  const keywordInputRef = useRef<HTMLInputElement>(null);

  const [filterByKeyword, setKeyword] = useReducer(
    (_: unknown, keyword: string) => {
      if (!keyword) {
        return undefined;
      }

      const re = new RegExp(keyword.replace(/[.*+?^${}()|[\]\\]/g, "\\$&"));

      return (content: Content) => {
        const text = `${content.guideNumber}${content.contentByLanguages
          .flatMap((cbl) => `${cbl.title}$${cbl.text}`)
          .join()}`;

        return text.match(re) !== null;
      };
    },
    undefined
  );

  const searchButtonListener = useCallback(() => {
    if (keywordInputRef.current) {
      setKeyword(keywordInputRef.current.value);
    }
  }, [keywordInputRef]);

  const clearButtonListener = useCallback(() => {
    if (keywordInputRef.current) {
      keywordInputRef.current.value = "";
      setKeyword("");
    }
  }, [keywordInputRef]);

  return (
    <>
      <dl className="voiceguideTitle">
        <dt>音声ガイド名</dt>
        <dd className="title">{guide.name_ja}</dd>
        <dd className="status">
          <span className="nopublic">{getGuideStatusText(guide.status)}</span>
        </dd>
        <dt>音声ガイドID</dt>
        <dd className="title">{guide.auxGuideId}</dd>
        <dd className="status"></dd>
      </dl>

      <div>
        <input
          type="button"
          value="ガイド番号順にソート"
          className="ml130"
          onClick={sortButtonListener}
        />
        <div className="fr" style={{ marginRight: 130 }}>
          <input type="text" ref={keywordInputRef} />
          <input
            type="button"
            value="検索"
            className="ml10"
            onClick={searchButtonListener}
          />
          <input
            type="button"
            value="クリア"
            className="ml10"
            onClick={clearButtonListener}
          />
        </div>
      </div>

      {guide.contents.map(
        (content, index) =>
          (filterByKeyword
            ? filterByKeyword(content)
            : filterByIndex(index)) && (
            <GuidesEditContentsItem
              key={content.contentId}
              content={content}
              guide={guide}
              onDelete={deleteListener}
              showPreview={showPreview}
              index={index}
            />
          )
      )}

      {(!pages || lastPage) && (
        <p className="addContents" onClick={addContentListener}>
          コンテンツを追加する
        </p>
      )}

      {pages > 1 && !filterByKeyword && (
        <div className="page">
          {contentsBeginIndex > 0 && (
            <span>
              <a
                className="link_before"
                onClick={() => setCurrentPage(currentPage - 1)}
              >
                前の{CONTENTS_PER_PAGE}件
              </a>
            </span>
          )}
          {Array.from({ length: pages }, (_, i) => (
            <>
              {i === currentPage ? (
                <span className="current_page">{i + 1}</span>
              ) : (
                <a className="link_page" onClick={() => setCurrentPage(i)}>
                  {i + 1}
                </a>
              )}
              &nbsp;
            </>
          ))}
          {!lastPage && (
            <span>
              <a
                className="link_next"
                onClick={() => setCurrentPage(currentPage + 1)}
              >
                次の{CONTENTS_PER_PAGE}件
              </a>
            </span>
          )}
        </div>
      )}
    </>
  );

  function filterByIndex(index: number) {
    return contentsBeginIndex <= index && index < contentsEndIndex;
  }
}
