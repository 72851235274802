import React, { ChangeEvent, useLayoutEffect, useReducer, useRef } from "react";
import { GuideVersion } from "./common";
import { Input } from "./components/Input";
import { setInputRefValue } from "./utils/react-util";
import $ from "jquery";

/**
 * 音声ガイド編集画面 - 基本設定 - 盗難防止。
 */
export function GuidesEditBasicAntitheft(props: { guide: GuideVersion }) {
  const { guide } = props;

  const latitudeRef = useRef<HTMLInputElement>(null);
  const longtitudeRef = useRef<HTMLInputElement>(null);
  const radiusRef = useRef<HTMLInputElement>(null);

  const [enabled, setEnabled] = useReducer(
    (_: boolean, event: ChangeEvent<HTMLInputElement>) => {
      const { checked } = event.target;

      if (!checked) {
        setInputRefValue(latitudeRef, "");
        setInputRefValue(longtitudeRef, "");
        setInputRefValue(radiusRef, "");
        guide.antiTheftLatitude = null;
        guide.antiTheftLongitude = null;
        guide.antiTheftRadius = null;
        $("div.constraints_gps2").hide(100);
      } else {
        $("div.constraints_gps2").show(100);
      }

      return checked;
    },
    guide,
    (guide) =>
      guide.antiTheftLatitude !== null &&
      guide.antiTheftLongitude !== null &&
      guide.antiTheftRadius !== null
  );

  useLayoutEffect(() => {
    if (
      !guide.antiTheftLatitude ||
      !guide.antiTheftLongitude ||
      !guide.antiTheftRadius
    ) {
      $("div.constraints_gps2").hide();
    }
  }, [guide]);

  return (
    <div className="rental">
      <h3>貸出専用設定</h3>
      <p className="attention">
        貸出しない場合は絶対に設定しないでください
        <br />
        設定範囲から外れると端末から警告音が鳴ります
      </p>
      <input
        type="checkbox"
        name="盗難防止"
        id="security"
        checked={enabled}
        onChange={setEnabled}
      />
      <label htmlFor="security">盗難防止機能を使用する</label>
      <div className="constraints_gps2">
        <label htmlFor="lat2">緯度</label>
        <Input
          id="lat2"
          type="number"
          name="緯度"
          className="half"
          obj={guide}
          propName="antiTheftLatitude"
          disabled={!enabled}
          inputRef={latitudeRef}
        />
        <br />
        <label htmlFor="lng2">経度</label>
        <Input
          id="lng2"
          type="number"
          name="経度"
          className="half"
          obj={guide}
          propName="antiTheftLongitude"
          disabled={!enabled}
          inputRef={longtitudeRef}
        />
        <br />
        <label htmlFor="area2">半径</label>
        <Input
          id="area2"
          type="number"
          name="半径"
          className="half"
          obj={guide}
          propName="antiTheftRadius"
          disabled={!enabled}
          inputRef={radiusRef}
        />
        <span className="ml10">m</span>
      </div>
    </div>
  );
}
