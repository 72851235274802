import { REKIHAKU_SUBDOMAIN_NAME } from "../constants";

export function throwException(message: string): any {
  throw new Error(message);
}

/**
 * 文字列を１０進数としてパースする。
 * パース出来ない時は指定したデフォルトを返す。
 *
 * @param s
 * @param defaultValue
 * @returns
 */
export function safeParseInt(s: string, defaultValue: number = 0) {
  const value = parseInt(s, 10);
  return isNaN(value) ? defaultValue : value;
}

/**
 * 配列から対象の要素を削除。
 *
 * @param array
 * @param element
 * @returns
 */
export function removeElement<T>(array: T[], element: T) {
  const index = array.indexOf(element);
  if (index < 0) {
    return;
  }
  array.splice(index, 1);
}

/**
 * 配列から条件に一致した要素を削除。
 *
 * @param array
 * @param predicate
 */
export function removeMatchedElements<T>(
  array: T[],
  predicate: (element: T) => boolean
) {
  for (let index = 0; index < array.length; ++index) {
    if (predicate(array[index])) {
      array.splice(index, 1);
      index--;
    }
  }
}

/**
 * 郵便番号から住所情報を取得する。
 *
 * 郵便番号 API として https://madefor.github.io/postal-code-api/ を使用している。
 *
 * @param postalCode
 * @returns
 */
export async function fetchAddress(postalCode: string) {
  const match = postalCode.match(/^(\d{3})(\d{4})/);
  if (!match) {
    return;
  }

  return fetch(
    `https://madefor.github.io/postal-code-api/api/v1/${match[1]}/${match[2]}.json`
  ).then((res) => {
    if (!res.ok) {
      return;
    }

    return res.json().then((json) => {
      const { data = [] } = json;
      const [first = {}] = data;
      const addr: {
        prefecture?: string;
        address1?: string;
        address2?: string;
        address3?: string;
        address4?: string;
      } = first.ja ?? {};

      return {
        prefecture: addr.prefecture ?? "",
        address:
          addr.address1 ??
          "" + addr.address2 ??
          "" + addr.address3 ??
          "" + addr.address4 ??
          "",
      };
    });
  });
}

/**
 * 歴博用ドメイン判定
 */
export function isRekihaku() {
  return (
    REKIHAKU_SUBDOMAIN_NAME &&
    location.hostname.split(".")[0] === REKIHAKU_SUBDOMAIN_NAME
  );
}
