import React, { ChangeEvent, useCallback, useState } from "react";
import { User } from "./common";
import { Input } from "./components/Input";
import {
  MAX_LENGTH_USERS_EMAIL,
  MAX_LENGTH_USERS_NAME,
  MAX_LENGTH_USERS_NAME_KANA,
} from "./constants";

export function AdminUsersEditUser(props: { user: User }) {
  const { user } = props;

  const [privilegedKind, setPrivilegedKind] = useState(user.privilegedKind);

  const privilegedKindChangeListener = useCallback(
    (event: ChangeEvent<HTMLSelectElement>) => {
      user.privilegedKind = event.target.value as any;
      setPrivilegedKind(event.target.value as any);
    },
    [user, setPrivilegedKind]
  );

  return (
    <>
      <dt className="must">メールアドレス</dt>
      <dd>
        <Input
          type="text"
          name="メールアドレス"
          className="full"
          obj={user}
          propName="email"
          maxLength={MAX_LENGTH_USERS_EMAIL}
        />
      </dd>

      <dt className="must">名前</dt>
      <dd>
        <Input
          type="text"
          name="名前"
          className="full"
          obj={user}
          propName="name"
          maxLength={MAX_LENGTH_USERS_NAME}
        />
      </dd>

      <dt className="must">名前(カナ)</dt>
      <dd>
        <Input
          type="text"
          name="名前(カナ)"
          className="full"
          obj={user}
          propName="name_kana"
          maxLength={MAX_LENGTH_USERS_NAME_KANA}
        />
      </dd>

      <dt className="must">権限種別</dt>
      <dd>
        <select value={privilegedKind} onChange={privilegedKindChangeListener}>
          <option value="admin">管理者</option>
          <option value="general">一般</option>
        </select>
      </dd>
    </>
  );
}
