import { useReducer } from "react";

/**
 * Component を強制的に再描画する refresh() 関数を返す。
 *
 * @returns
 */
export function useRefresh(): () => void {
  return useReducer((state) => state + 1, 0)[1];
}

/**
 * Component を強制的に再描画するためのカウンターと refresh() 関数を返す。
 *
 * カウンターを key に指定することで強制的に Component を再構築する。
 *
 * @returns
 */
export function useRefreshCounter() {
  return useReducer((state) => state + 1, 0);
}
