import React, { ChangeEvent, useReducer } from "react";
import { Content, GuideVersion } from "./common";
import { Input } from "./components/Input";

/**
 * 音声ガイド編集画面 - コンテンツ設定 - ビーコン。
 */
export function GuidesEditContentsBeacon(props: {
  content: Content;
  guide: GuideVersion;
}) {
  const { content } = props;

  const [playLimitEnabled, setPlayLimitEnabled] = useReducer(
    (_: boolean, event: ChangeEvent<HTMLInputElement>) => {
      const { checked } = event.target;

      if (!checked) {
        content.beaconPlayLimit = null;
      } else {
        content.beaconPlayLimit = 0;
      }

      return checked;
    },
    content,
    (content) => content.beaconPlayLimit !== null
  );

  return (
    <>
      <h2 className="must">ビーコン</h2>
      <div className="indent">
        <label htmlFor="beacon">ビーコンID</label>
        <Input
          id="beacon"
          type="text"
          name="ビーコンID"
          obj={content}
          propName="beaconId"
        />
        &nbsp;
        <label htmlFor="strength">強度</label>
        <Input
          id="strength"
          type="text"
          name="強度"
          obj={content}
          propName="beaconStrength"
        />
        <h3>予備ポイント1</h3>
        <label htmlFor="beacon1">ビーコンID</label>
        <Input
          id="beacon1"
          type="text"
          name="ビーコンID"
          obj={content}
          propName="beaconId1"
        />
        &nbsp;
        <label htmlFor="strength1">強度</label>
        <Input
          id="strength1"
          type="text"
          name="強度"
          obj={content}
          propName="beaconStrength1"
        />
        <h3>予備ポイント2</h3>
        <label htmlFor="beacon2">ビーコンID</label>
        <Input
          id="beacon2"
          type="text"
          name="ビーコンID"
          obj={content}
          propName="beaconId2"
        />
        &nbsp;
        <label htmlFor="strength2">強度</label>
        <Input
          id="strength2"
          type="text"
          name="強度"
          obj={content}
          propName="beaconStrength2"
        />
        <h3>予備ポイント3</h3>
        <label htmlFor="beacon3">ビーコンID</label>
        <Input
          id="beacon3"
          type="text"
          name="ビーコンID"
          obj={content}
          propName="beaconId3"
        />
        &nbsp;
        <label htmlFor="strength3">強度</label>
        <Input
          id="strength3"
          type="text"
          name="強度"
          obj={content}
          propName="beaconStrength3"
        />
      </div>

      <div className="indent">
        <input
          type="checkbox"
          checked={playLimitEnabled}
          onChange={setPlayLimitEnabled}
        />
        <label htmlFor="repeat1">
          範囲から外れた後、再度範囲に入ったときに再生する
        </label>
      </div>
      {playLimitEnabled && (
        <div className="indent">
          <label htmlFor="view1">再生回数</label>
          <Input
            type="text"
            name="再生回数"
            obj={content}
            propName="beaconPlayLimit"
          />
        </div>
      )}
    </>
  );
}
