import React, { useRef } from "react";
import { isGeneralUser } from "./Account-store";
import { GuideVersion } from "./common";
import { InputFile } from "./components/InputFile";
import { ACCEPT_IMAGE, useUploader } from "./hooks/useUploader";

/**
 * 音声ガイド編集画面 - 基本設定 - トップ画像。
 *
 * @param props
 * @returns
 */
export function GuidesEditBasicTopImage(props: { guide: GuideVersion }) {
  const { guide } = props;

  const imageRef = useRef<HTMLImageElement>(null);

  const uploaderProps = useUploader({
    guideId: guide.guideId,
    obj: guide,
    propName: "topImage",
    imageRef,
    accept: ACCEPT_IMAGE,
  });

  const readOnly = isGeneralUser();

  return (
    <div className="topImage">
      <h2 className="must">トップ画像</h2>
      {!readOnly && <InputFile name="画像" id="topPhoto" {...uploaderProps} />}
      <img alt="" id="topImage" ref={imageRef} />
    </div>
  );
}
