import React, { ChangeEvent, useLayoutEffect, useReducer, useRef } from "react";
import { GuideVersion } from "./common";
import { Input } from "./components/Input";
import { MAX_LENGTH_GUIDES_LIMIT_BY_PASSWORD } from "./constants";
import { changeToPasswordInput, setInputRefValue } from "./utils/react-util";
import $ from "jquery";

/**
 * 音声ガイド編集画面 - 基本設定 - 制限。
 */
export function GuidesEditBasicLimit(props: { guide: GuideVersion }) {
  const { guide } = props;

  const latitudeRef = useRef<HTMLInputElement>(null);
  const longtitudeRef = useRef<HTMLInputElement>(null);
  const radiusRef = useRef<HTMLInputElement>(null);
  const passwordRef = useRef<HTMLInputElement>(null);

  const [limitByGps, setLimitByGps] = useReducer(
    (_: boolean, event: ChangeEvent<HTMLInputElement>) => {
      const { checked } = event.target;

      if (!checked) {
        setInputRefValue(latitudeRef, "");
        setInputRefValue(longtitudeRef, "");
        setInputRefValue(radiusRef, "");
        guide.limitByGpsLatitude = null;
        guide.limitByGpsLongitude = null;
        guide.limitByGpsRadius = null;
        $("div.constraints_gps").hide(100);
      } else {
        $("div.constraints_gps").show(100);
      }

      return checked;
    },
    guide,
    (guide) =>
      guide.limitByGpsLatitude !== null &&
      guide.limitByGpsLongitude !== null &&
      guide.limitByGpsRadius !== null
  );

  const [limitByPassword, setLimitByPassword] = useReducer(
    (_: boolean, event: ChangeEvent<HTMLInputElement>) => {
      const { checked } = event.target;

      if (!checked) {
        setInputRefValue(passwordRef, "");
        guide.limitByPassword = null;
        $("div.constraints_passwd").hide(100);
      } else {
        $("div.constraints_passwd").show(100);
      }

      return checked;
    },
    guide,
    (guide) => guide.limitByPassword !== null
  );

  useLayoutEffect(() => {
    if (
      !guide.limitByGpsLatitude ||
      !guide.limitByGpsLongitude ||
      !guide.limitByGpsRadius
    ) {
      $("div.constraints_gps").hide();
    }
    if (!guide.limitByPassword) {
      $("div.constraints_passwd").hide();
    }
  }, [guide]);

  return (
    <>
      <dt>制限</dt>
      <dd>
        <input
          type="checkbox"
          name="制限"
          id="constraints1"
          checked={limitByGps}
          onChange={setLimitByGps}
        />
        <label htmlFor="constraints1">GPSによる利用制限</label>
        <div className="constraints_gps">
          <label htmlFor="lat">緯度</label>
          <Input
            id="lat"
            type="text"
            name="緯度"
            className="half"
            obj={guide}
            propName="limitByGpsLatitude"
            disabled={!limitByGps}
            inputRef={latitudeRef}
          />
          <br />
          <label htmlFor="lng">経度</label>
          <Input
            id="lng"
            type="text"
            name="経度"
            className="half"
            obj={guide}
            propName="limitByGpsLongitude"
            disabled={!limitByGps}
            inputRef={longtitudeRef}
          />
          <br />
          <label htmlFor="area">半径</label>
          <Input
            id="area"
            type="text"
            name="半径"
            className="half"
            obj={guide}
            propName="limitByGpsRadius"
            disabled={!limitByGps}
            inputRef={radiusRef}
          />
          <span className="ml10">m</span>
        </div>

        <br />
        <input
          type="checkbox"
          name="制限"
          id="constraints2"
          checked={limitByPassword}
          onChange={setLimitByPassword}
        />
        <label htmlFor="constraints2">キーワードによる利用制限</label>
        <div className="constraints_passwd">
          <Input
            type="text"
            name="constraints_passwd"
            className="half"
            obj={guide}
            propName="limitByPassword"
            maxLength={MAX_LENGTH_GUIDES_LIMIT_BY_PASSWORD}
            placeholder="キーワード"
            onFocus={changeToPasswordInput}
          />
        </div>
      </dd>
    </>
  );
}
