"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useSuspensive = exports.useLocalSuspensive = exports.useObserver = exports.Observer = exports.Wait = exports.setDefaultFallback = exports.Suspensive = void 0;
var Suspensive_1 = require("./Suspensive");
Object.defineProperty(exports, "Suspensive", { enumerable: true, get: function () { return Suspensive_1.Suspensive; } });
var Wait_1 = require("./Wait");
Object.defineProperty(exports, "setDefaultFallback", { enumerable: true, get: function () { return Wait_1.setDefaultFallback; } });
Object.defineProperty(exports, "Wait", { enumerable: true, get: function () { return Wait_1.Wait; } });
var Observer_1 = require("./Observer");
Object.defineProperty(exports, "Observer", { enumerable: true, get: function () { return Observer_1.Observer; } });
Object.defineProperty(exports, "useObserver", { enumerable: true, get: function () { return Observer_1.useObserver; } });
var useSuspensive_1 = require("./useSuspensive");
Object.defineProperty(exports, "useLocalSuspensive", { enumerable: true, get: function () { return useSuspensive_1.useLocalSuspensive; } });
Object.defineProperty(exports, "useSuspensive", { enumerable: true, get: function () { return useSuspensive_1.useSuspensive; } });
