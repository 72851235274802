"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useSuspensive = exports.useLocalSuspensive = void 0;
const react_1 = require("react");
const Suspensive_1 = require("./Suspensive");
/**
 * The hook to store a `Suspensive` as state.
 *
 * @param factory The function that generates a value to set to the `Suspensive`.
 * @param deps The dependency array that triggers to call the `factory` function.
 */
function useLocalSuspensive(factory, deps) {
    const [state, dispatch] = (0, react_1.useReducer)((state, deps) => {
        state.suspensive.set(factory());
        return {
            deps,
            suspensive: state.suspensive,
        };
    }, deps, deps => ({
        deps,
        suspensive: new Suspensive_1.Suspensive(factory()),
    }));
    if (!areEqual(state.deps, deps)) {
        dispatch(deps);
    }
    return state.suspensive;
}
exports.useLocalSuspensive = useLocalSuspensive;
/**
 * The hook to use a `Suspensive` external to a component.
 *
 * When the `Suspensive` referred by this hook is changed and new `Suspensive` has no fallback,
 * this hook sets the value of the old `Suspensive` as the fallback of new `Suspensive`.
 * By doing so, you can use the old `Suspensive` until the new `Suspensive` resolves.
 *
 * @param factory The function that returns the reference to the external `Suspensive`.
 * @param deps The dependency array that triggers to call the `factory` function.
 */
function useSuspensive(factory, deps) {
    const [state, dispatch] = (0, react_1.useReducer)((state, deps) => {
        const suspensive = factory();
        if (!suspensive.hasFallback() && state.prev) {
            try {
                suspensive.fallback = state.prev.value;
            }
            catch (_a) {
                if (state.prev.hasFallback()) {
                    suspensive.fallback = state.prev.fallback;
                }
            }
        }
        return {
            deps,
            suspensive,
            prev: state.suspensive,
        };
    }, deps, deps => ({
        deps,
        suspensive: factory(),
    }));
    if (!areEqual(state.deps, deps)) {
        dispatch(deps);
    }
    return state.suspensive;
}
exports.useSuspensive = useSuspensive;
function areEqual(prevDeps, nextDeps) {
    if (prevDeps.length !== nextDeps.length) {
        throw new Error('Size of deps must not be changed');
    }
    for (let i = 0; i < nextDeps.length; ++i) {
        if (!Object.is(prevDeps[i], nextDeps[i])) {
            return false;
        }
    }
    return true;
}
