import { Suspensive } from "react-suspensive";
import { Organization } from "./common";
import { fetchJsonApi } from "./fetcher";
import { phantomId } from "./utils/phantom-id";

/**
 * 現在編集中の組織。
 */
export const currentOrganization = new Suspensive<Organization>(
  createOrganization()
);

// for debug
(window as any).$adminOrganization = currentOrganization;

/**
 * 指定された組織のデータを fetch して、編集用にセットする。
 *
 * @param organizationId 取得対象の組織の ID
 */
export function fetchOrganization(organizationId: string) {
  currentOrganization.value = fetchJsonApi({
    type: "adminGetOrganization",
    organizationId: parseInt(organizationId, 10),
  }).then((res) => {
    if (!res.item) {
      throw new Error(`組織 ${organizationId} が見つかりませんでした。`);
    }

    return res.item;
  });

  return currentOrganization;
}

/**
 * 編集中の組織を新規作成用にクリアする。
 */
export function clearOrganization() {
  currentOrganization.set(createOrganization());

  return currentOrganization;
}

function createOrganization(): Organization {
  const organizationId = phantomId();

  return {
    organizationId,
    name_ja: "",
    name_en: "",
    introduction_ja: "",
    introduction_en: "",
    postalCode: "",
    prefecture: "",
    address1: "",
    address2: "",
    tel: "",
    tenantId: "",
    privileged: 0,
    visibleOnViewer: 1,
    maxAdminUsers: 5,
    specialized: 0,
    subdomain: "",
    androidStoreUrl: "",
    iOSStoreUrl: "",
    iOSAppID: "",
  };
}

/**
 * 編集中の内容を検証する。
 */
export function validateOrganization(item: Organization) {
  const errors: string[] = [];

  if (!item.name_ja) {
    errors.push("「施設名」を入力してください。");
  }

  if (!item.postalCode) {
    errors.push("「郵便番号」を入力してください。");
  }

  if (!item.prefecture) {
    errors.push("「都道府県」を入力してください。");
  }

  if (!item.address1) {
    errors.push("「住所1」を入力してください。");
  }

  if (!item.tel) {
    errors.push("「電話番号」を入力してください。");
  }

  return errors;
}
