import React from "react";
import { useLocalSuspensive, Wait } from "react-suspensive";
import { GuideVersion } from "./common";
import { fetchSignedUrlOfGuideFile } from "./Guides-util";

/**
 * 音声ガイド編集画面 - プレビュー - トップ画像。
 */
export function GuidesEditPreviewTopImage(props: { guide: GuideVersion }) {
  const { guide } = props;

  const topImageUrl = useLocalSuspensive(
    () => fetchSignedUrlOfGuideFile(guide.guideId, guide.topImage),
    [guide.topImage, guide.guideId]
  );

  return (
    <Wait
      suspensive={topImageUrl}
      render={(topImageUrl) =>
        topImageUrl && <img src={topImageUrl} alt="" className="top" />
      }
    />
  );
}
