import React from "react";
import { useRouteMatch } from "react-router";
import { Link } from "react-router-dom";
import { Suspensive, useLocalSuspensive, Wait } from "react-suspensive";
import { User } from "./common";
import { QueryUsersResponse } from "./common/QueryUsersResponse";
import { QUERY_LIMIT } from "./constants";
import { fetchJsonApi } from "./fetcher";
import { useQueryParams } from "./hooks/useQueryParams";
import { safeParseInt } from "./utils/util";

export function UsersList() {
  const query = useQueryParams<{ offset: string }>();

  const offset = safeParseInt(query.offset);

  const users = useLocalSuspensive(
    () =>
      fetchJsonApi({
        type: "queryUsers",
        limit: QUERY_LIMIT,
        offset,
      }),
    [offset]
  );

  return (
    <>
      <table className="contentsList">
        <UsersListHead />
        <UsersListBody users={users} />
      </table>

      <Wait
        suspensive={users}
        render={(users) =>
          users.totalCount > 0 && (
            <div className="listPaginate">
              <span className="current">
                {offset + 1}-{Math.min(offset + QUERY_LIMIT, users.totalCount)}
              </span>
              /<span className="total">{users.totalCount}</span>
              <Link
                to={{ search: `?offset=${Math.max(0, offset - QUERY_LIMIT)}` }}
                className="prev"
              />
              <Link
                to={{
                  search: `?offset=${
                    offset + QUERY_LIMIT >= users.totalCount
                      ? offset
                      : offset + QUERY_LIMIT
                  }`,
                }}
                className="next"
              />
            </div>
          )
        }
      />
    </>
  );
}

function UsersListHead() {
  return (
    <thead>
      <tr>
        <th>メールアドレス</th>
        <th>名前</th>
      </tr>
    </thead>
  );
}

function UsersListBody(props: { users: Suspensive<QueryUsersResponse> }) {
  const { users } = props;

  return (
    <Wait
      suspensive={users}
      render={(users) => (
        <tbody>
          {users.items.map((user) => (
            <UsersListItem key={user.userId} user={user} />
          ))}
        </tbody>
      )}
      fallback={<tbody></tbody>}
    />
  );
}

function UsersListItem(props: { user: User }) {
  const { user } = props;

  const { url } = useRouteMatch();

  return (
    <tr>
      <td>{user.email}</td>
      <td>
        {user.name} ({user.name_kana})
      </td>
      <td>
        <Link to={`${url}/${user.userId}`}>編集</Link>
      </td>
    </tr>
  );
}
