import { Suspensive } from "react-suspensive";
import { QRCode } from "./common";
import { fetchJsonApi } from "./fetcher";
import { jstToday } from "./utils/date-util";
import { isPhantomId, phantomId } from "./utils/phantom-id";

/**
 * 現在編集中のQRコード。
 */
export const currentQRCode = new Suspensive<QRCode>(createQRCode());

/**
 * 編集開始時のQRコード
 */
let prevQRCode: QRCode | undefined;

// for debug
(window as any).$qrCode = currentQRCode;

/**
 * 指定されたQRコードのデータを fetch して、編集用にセットする。
 *
 * @param qrCodeId 取得対象のQRコードの ID
 * @param copy 取得後のQRコードをコピーとして扱うかどうか
 */
export function fetchQRCode(qrCodeId: string, copy?: boolean) {
  currentQRCode.value = fetchJsonApi({
    type: "getQRCode",
    qrCodeId: parseInt(qrCodeId, 10),
  })
    .then((res) => {
      if (!res.item) {
        throw new Error(`QRコード ${qrCodeId} が見つかりませんでした。`);
      }

      const { item } = res;

      if (copy) {
        makeQRCodeForCopy(item);
      }

      if (!copy) {
        return item;
      }

      // コピー作成時には API copyMediaFiles も呼ぶ。
      return fetchJsonApi({
        type: "copyMediaFiles",
        qrCodeId: parseInt(qrCodeId, 10),
      }).then(() => {
        return item;
      });
    })
    .then((item) => {
      setPrevQRCode(item);

      return item;
    });

  return currentQRCode;
}

/**
 * QRコードのデータをコピーとして扱えるようにする。
 *
 * @param qrCode
 */
function makeQRCodeForCopy(qrCode: QRCode) {
  qrCode.qrCodeId = phantomId();
  qrCode.name = "（コピー）" + qrCode.name;
  qrCode.printedAt = undefined;
  qrCode.periodBegin = jstToday();
  qrCode.periodEnd = jstToday();
}

/**
 * 編集中のQRコードデータを新規作成用にクリアする。
 */
export function clearQRCode() {
  currentQRCode.set(createQRCode());
  setPrevQRCode(currentQRCode.value);

  return currentQRCode;
}

function createQRCode(): QRCode {
  return {
    qrCodeId: phantomId(),
    organizationId: phantomId(),
    guideId: phantomId(),
    name: "",
    auxQRCodeId: "",
    kind: "general",
    groupName: "",
    periodBegin: jstToday(),
    periodEnd: jstToday(),
    numbers: 1,
    facilityName: "",
    qrImage: undefined,
    qrImageSize: null,
    description_ja: "",
    description_en: "",
    printedAt: undefined,
  };
}

function setPrevQRCode(qrCode: QRCode) {
  prevQRCode = JSON.parse(JSON.stringify(qrCode));
}

/**
 * QRコードが編集状態にあるかチェック
 */
export function currentQRCodeHasChanged(): boolean {
  const current = currentQRCode.value;
  const prev = prevQRCode;

  if (!prev) {
    throw new Error("編集状態チェックに失敗");
  }

  if (
    current.name !== prev.name ||
    // 新規作成画面の仮IDは変更チェック対象から除外する。
    (!isPhantomId(current.guideId) && current.guideId !== prev.guideId) ||
    current.kind !== prev.kind ||
    current.groupName !== prev.groupName ||
    current.periodBegin !== prev.periodBegin ||
    current.periodEnd !== prev.periodEnd ||
    current.numbers !== prev.numbers ||
    current.facilityName !== prev.facilityName ||
    current.qrImage !== prev.qrImage ||
    current.qrImageSize !== prev.qrImageSize ||
    current.description_ja !== prev.description_ja ||
    current.description_en !== prev.description_en
  ) {
    return true;
  }

  return false;
}

/**
 * QRコードを保存
 */
export async function saveQRCode() {
  await fetchJsonApi({
    type: "postQRCode",
    item: currentQRCode.value,
  });
  setPrevQRCode(currentQRCode.value);
}

/**
 * 編集中のQRコードの検証。
 */
export function validateQRCode() {
  const qrCode = currentQRCode.value;

  const errors: string[] = [];

  if (!qrCode.name) {
    errors.push("「QRコード名」を入力してください。");
  }

  if (qrCode.guideId <= 0) {
    errors.push("「音声ガイド」を選択してください。");
  }

  if (qrCode.kind === "group" && !qrCode.groupName) {
    errors.push("「団体客名」を入力してください。");
  }

  if (qrCode.periodBegin > qrCode.periodEnd) {
    errors.push("「有効期限」の終了日は開始日以降を入力してください。");
  }

  if (qrCode.numbers <= 0) {
    errors.push("「枚数」は１以上を入力してください。");
  }

  if (!qrCode.facilityName) {
    errors.push("「施設名」を入力してください。");
  }

  return errors;
}
