import React from "react";
import { useParams } from "react-router-dom";
import { useSuspensive, Wait } from "react-suspensive";
import {
  fetchOrganization,
  clearOrganization,
} from "./AdminOrganizationsEdit-store";
import { AdminOrganizationsEditOperations } from "./AdminOrganizationsEditOperations";
import { AdminOrganizationsEditOrganization } from "./AdminOrganizationsEditOrganization";

export function AdminOrganizationsEdit() {
  const { organizationId } = useParams<{ organizationId: string }>();

  const organization = useSuspensive(() => {
    if (organizationId === "new") {
      return clearOrganization();
    } else {
      return fetchOrganization(organizationId);
    }
  }, [organizationId]);

  return (
    <Wait
      suspensive={organization}
      render={(organization) => (
        <>
          <AdminOrganizationsEditOperations />

          <div className="contentsInfoWrapper">
            <div className="contentsBox qr">
              <dl className="contentsInfo">
                <AdminOrganizationsEditOrganization
                  organization={organization}
                />
              </dl>
            </div>
          </div>
        </>
      )}
    />
  );
}
