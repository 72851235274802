import React, { ChangeEvent, useCallback, useRef, useState } from "react";
import { Organization } from "./common";
import { Input } from "./components/Input";
import { notifyError } from "./components/NotificationOverlay";
import { setProgressTask } from "./components/ProgressOverlay";
import { TextArea } from "./components/TextArea";
import {
  MAX_LENGTH_ORGANIZATIONS_ADDRESS1,
  MAX_LENGTH_ORGANIZATIONS_ADDRESS2,
  MAX_LENGTH_ORGANIZATIONS_NAME,
  MAX_LENGTH_ORGANIZATIONS_POSTAL_CODE,
  MAX_LENGTH_ORGANIZATIONS_TEL,
} from "./constants";
import { fetchAddress } from "./utils/util";

export function AdminOrganizationsEditOrganization(props: {
  organization: Organization;
}) {
  const { organization } = props;

  const [prefecture, setPrefecture] = useState(organization.prefecture ?? "");

  const address1Ref = useRef<HTMLInputElement>(null);

  const postalCodeChangeListener = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const { postalCode = "" } = organization;

      if (postalCode?.length !== 7) {
        return;
      }

      const task = fetchAddress(postalCode)
        .then((addr) => {
          if (!addr) {
            notifyError({
              message: `郵便番号 ${postalCode} の住所が見つかりません。`,
            });
            return;
          }

          organization.prefecture = addr.prefecture;
          setPrefecture(addr.prefecture);

          if (address1Ref.current) {
            address1Ref.current.value = addr.address;
            organization.address1 = addr.address;
          }
        })
        .catch((err) => {
          notifyError({
            message: `郵便番号 ${postalCode} の住所が見つかりません。`,
            err,
          });
        });

      setProgressTask(task);
    },
    [organization, setPrefecture, address1Ref]
  );

  const prefectureChangeListener = useCallback(
    (event: ChangeEvent<HTMLSelectElement>) => {
      organization.prefecture = event.target.value;
      setPrefecture(event.target.value);
    },
    [setPrefecture]
  );

  return (
    <>
      <dt className="must">施設名</dt>
      <dd>
        <Input
          type="text"
          name="施設名"
          className="full"
          obj={organization}
          propName="name_ja"
          maxLength={MAX_LENGTH_ORGANIZATIONS_NAME}
        />
      </dd>

      <dt className="must">施設名(英語表記)</dt>
      <dd>
        <Input
          type="text"
          name="施設名(英語表記)"
          className="full"
          obj={organization}
          propName="name_en"
          maxLength={MAX_LENGTH_ORGANIZATIONS_NAME}
        />
      </dd>

      <dt className="must">施設紹介文</dt>
      <dd>
        <TextArea
          name="施設紹介文"
          obj={organization}
          propName="introduction_ja"
        />
      </dd>

      <dt className="must">施設紹介文(英語表記)</dt>
      <dd>
        <TextArea
          name="施設紹介文(英語表記)"
          obj={organization}
          propName="introduction_en"
        />
      </dd>

      <dt className="must">郵便番号</dt>
      <dd>
        <Input
          type="text"
          name="郵便番号"
          className="half"
          obj={organization}
          propName="postalCode"
          onChange={postalCodeChangeListener}
          maxLength={MAX_LENGTH_ORGANIZATIONS_POSTAL_CODE}
        />
      </dd>

      <dt className="must">都道府県</dt>
      <dd>
        <select
          name="都道府県"
          value={prefecture}
          onChange={prefectureChangeListener}
        >
          <option value="">選択してください</option>
          <option value="北海道">北海道</option>
          <option value="青森県">青森県</option>
          <option value="岩手県">岩手県</option>
          <option value="宮城県">宮城県</option>
          <option value="秋田県">秋田県</option>
          <option value="山形県">山形県</option>
          <option value="福島県">福島県</option>
          <option value="茨城県">茨城県</option>
          <option value="栃木県">栃木県</option>
          <option value="群馬県">群馬県</option>
          <option value="埼玉県">埼玉県</option>
          <option value="千葉県">千葉県</option>
          <option value="東京都">東京都</option>
          <option value="神奈川県">神奈川県</option>
          <option value="新潟県">新潟県</option>
          <option value="富山県">富山県</option>
          <option value="石川県">石川県</option>
          <option value="福井県">福井県</option>
          <option value="山梨県">山梨県</option>
          <option value="長野県">長野県</option>
          <option value="岐阜県">岐阜県</option>
          <option value="静岡県">静岡県</option>
          <option value="愛知県">愛知県</option>
          <option value="三重県">三重県</option>
          <option value="滋賀県">滋賀県</option>
          <option value="京都府">京都府</option>
          <option value="大阪府">大阪府</option>
          <option value="兵庫県">兵庫県</option>
          <option value="奈良県">奈良県</option>
          <option value="和歌山県">和歌山県</option>
          <option value="鳥取県">鳥取県</option>
          <option value="島根県">島根県</option>
          <option value="岡山県">岡山県</option>
          <option value="広島県">広島県</option>
          <option value="山口県">山口県</option>
          <option value="徳島県">徳島県</option>
          <option value="香川県">香川県</option>
          <option value="愛媛県">愛媛県</option>
          <option value="高知県">高知県</option>
          <option value="福岡県">福岡県</option>
          <option value="佐賀県">佐賀県</option>
          <option value="長崎県">長崎県</option>
          <option value="熊本県">熊本県</option>
          <option value="大分県">大分県</option>
          <option value="宮崎県">宮崎県</option>
          <option value="鹿児島県">鹿児島県</option>
          <option value="沖縄県">沖縄県</option>
        </select>
      </dd>

      <dt className="must">住所1</dt>
      <dd>
        <Input
          name="住所1"
          type="text"
          className="full"
          obj={organization}
          propName="address1"
          inputRef={address1Ref}
          maxLength={MAX_LENGTH_ORGANIZATIONS_ADDRESS1}
        />
      </dd>

      <dt>住所2</dt>
      <dd>
        <Input
          name="住所2"
          type="text"
          className="full"
          obj={organization}
          propName="address2"
          maxLength={MAX_LENGTH_ORGANIZATIONS_ADDRESS2}
        />
      </dd>

      <dt className="must">電話番号</dt>
      <dd>
        <Input
          name="電話番号"
          type="text"
          className="half"
          placeholder="半角ハイフンなし"
          obj={organization}
          propName="tel"
          maxLength={MAX_LENGTH_ORGANIZATIONS_TEL}
        />
      </dd>

      <dt className="must">管理者上限</dt>
      <dd>
        <Input
          type="number"
          min={1}
          className="half"
          obj={organization}
          propName="maxAdminUsers"
        />
        <span className="ml10">人</span>
      </dd>

      <dt className="must">アプリ表示</dt>
      <dd>
        <Input
          type="checkbox"
          className="half"
          obj={organization}
          propName="visibleOnViewer"
        />
      </dd>

      <dt className="must">特権</dt>
      <dd>
        <Input
          name="特権"
          type="checkbox"
          className="half"
          obj={organization}
          propName="privileged"
        />
      </dd>

      <dt className="must">要専用アプリ</dt>
      <dd>
        <Input
          type="checkbox"
          className="half"
          obj={organization}
          propName="specialized"
        />
        {organization.tenantId && (
          <span className="ml10">テナントID： {organization.tenantId}</span>
        )}
        <div>
          <label htmlFor="subdomain">サブドメイン</label>
          <br />
          <Input
            id="subdomain"
            type="text"
            className="half"
            obj={organization}
            propName="subdomain"
          />
          <br />
          <label htmlFor="androidStoreUrl">Android ストアページ URL</label>
          <br />
          <Input
            id="androidStoreUrl"
            type="text"
            className="full"
            obj={organization}
            propName="androidStoreUrl"
          />
          <br />
          <label htmlFor="iOSStoreUrl">iOS ストアページ URL</label>
          <br />
          <Input
            id="iOSStoreUrl"
            type="text"
            className="full"
            obj={organization}
            propName="iOSStoreUrl"
          />
          <br />
          <label htmlFor="iOSAppID">iOS AppId</label>
          <br />
          <Input
            id="iOSAppID"
            type="text"
            className="full"
            obj={organization}
            propName="iOSAppID"
          />
        </div>
      </dd>

      <dt>SSID</dt>
      <dd>
        <Input
          name="SSID"
          type="text"
          className="full"
          obj={organization}
          propName="ssid"
        />
      </dd>
    </>
  );
}
