import React, { useLayoutEffect, useState } from "react";

export interface RenderPromiseProps<T> {
  /**
   * RenderPromise が判定対象とする Promise。
   */
  promise: Promise<T>;

  /**
   * promise が解決した時に呼ばれる render prop。
   */
  renderOnResolved: (value: T) => JSX.Element;

  /**
   * promise がエラーとなった時に呼ばれる render prop。
   */
  renderOnRejected: (err: Error) => JSX.Element;
}

type RenderPromiseState<T> =
  | {
      type: "initial";
    }
  | {
      type: "resolved";
      value: T;
    }
  | {
      type: "rejected";
      err: Error;
    };

/**
 * Promise の結果で表示内容を切り替えるコンポーネント。
 *
 * Promise の結果に応じた render prop を指定する。
 *
 * Promise が未解決の間は何も表示しない。
 */
export function RenderPromise<T>(props: RenderPromiseProps<T>) {
  const [state, setState] = useState<RenderPromiseState<T>>({
    type: "initial",
  });

  useLayoutEffect(() => {
    let mounted = true;

    props.promise.then(
      (value) => mounted && setState({ type: "resolved", value }),
      (err) => mounted && setState({ type: "rejected", err })
    );

    return () => {
      mounted = false;
    };
  }, []);

  if (state.type === "initial") {
    return <></>;
  } else if (state.type === "resolved") {
    return props.renderOnResolved(state.value);
  } else {
    return props.renderOnRejected(state.err);
  }
}
