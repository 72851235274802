import React from "react";
import { useRefresh } from "../hooks/useRefresh";

interface NotifyInfoOptions {
  /**
   * ユーザーに表示するメッセージ。
   */
  message: string;

  /**
   * ユーザーにリスト形式で表示する追加的なメッセージ。
   */
  listItems?: string[];
}

interface NotifyErrorOptions extends NotifyInfoOptions {
  /**
   * エラーメッセージに関連する Error オブジェクト。
   */
  err?: Error;
}

interface NotificationItem {
  key: string;
  level: "ERROR" | "INFO";
  message: string;
  listItems?: string[];
  err?: Error;
}

/**
 * 画面に表示する通知内容の配列。
 */
const notificationItems: NotificationItem[] = [];

/**
 * NotificationOverlay コンポーネントを再描画する関数。
 */
let refreshOverlay: () => void;

/**
 * ユーザーに指定したメッセージを通知する。
 */
export function notifyInfo(options: NotifyInfoOptions) {
  notify({
    key: Math.random().toString(36).substr(2),
    level: "INFO",
    ...options,
  });
}

/**
 * ユーザーにエラーが発生したことを通知する。
 */
export function notifyError(options: NotifyErrorOptions) {
  notify({
    key: Math.random().toString(36).substr(2),
    level: "ERROR",
    ...options,
  });
}

function notify(notificationItem: NotificationItem) {
  notificationItems.push(notificationItem);

  if (notificationItem.level === "ERROR") {
    console.error(notificationItem.err);
  } else if (notificationItem.level === "INFO") {
    setTimeout(() => {
      removeNotificationItem(notificationItem);
    }, 10 * 1000);
  }

  if (refreshOverlay) {
    refreshOverlay();
  }
}

function removeNotificationItem(notificationItem: NotificationItem) {
  const index = notificationItems.indexOf(notificationItem);
  if (index < 0) {
    return;
  }

  notificationItems.splice(index, 1);

  if (refreshOverlay) {
    refreshOverlay();
  }
}

/**
 * ユーザーに通知するメッセージを表示するコンポーネント。
 */
export function NotificationOverlay() {
  refreshOverlay = useRefresh();

  if (notificationItems.length === 0) {
    return null;
  }

  return (
    <div className="NotificationOverlay">
      {notificationItems.map((notificationItem) => (
        <NotificationOverlayItem
          key={notificationItem.key}
          notificationItem={notificationItem}
        />
      ))}
    </div>
  );
}

function NotificationOverlayItem(props: {
  notificationItem: NotificationItem;
}) {
  const { notificationItem } = props;
  const { message, listItems, err } = notificationItem;

  return (
    <div className="NotificationOverlayItem">
      <div className="NotificationOverlayItem__messages">
        <div>{message}</div>
        {err && <div>{err.message}</div>}
        {listItems && (
          <ul>
            {listItems.map((item, index) => (
              <li key={index}>{item}</li>
            ))}
          </ul>
        )}
      </div>
      <span
        className="NotificationOverlayItem__close"
        onClick={() => removeNotificationItem(notificationItem)}
      />
    </div>
  );
}
