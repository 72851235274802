import React from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import { GuidesList } from "./GuidesList";
import { GuidesEdit } from "./GuidesEdit";
import { SiteHeader } from "./SiteHeader";
import { SiteFooter } from "./SiteFooter";

/**
 * 音声ガイド画面。
 *
 * 音声ガイド管理系の各画面にルーティングする。
 */
export function Guides() {
  const { path } = useRouteMatch();

  return (
    <>
      <SiteHeader />
      <div className="contentsWrapper">
        <div className="contents">
          <Switch>
            <Route path={path} exact component={GuidesList} />
            <Route
              path={`${path}/:guideId`}
              render={(props) => (
                <GuidesEdit key={props.match.params.guideId} />
              )}
            />
          </Switch>
        </div>
      </div>
      <SiteFooter />
    </>
  );
}
