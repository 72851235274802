import { Suspensive } from "react-suspensive";
import { ContentByLanguage, GuideLanguage, GuideVersion } from "./common";
import { fetchJsonApi } from "./fetcher";
import { findGuideLanguage } from "./Guides-util";
import { jstToday } from "./utils/date-util";
import { isPhantomId, phantomId } from "./utils/phantom-id";
import sleep from "sleep-promise";
import { extensions } from "mime-types";
import { ACCEPT_AUDIO, ACCEPT_IMAGE, ACCEPT_MOVIE } from "./hooks/useUploader";
import XLSX from "xlsx";
import { fileRelativePath } from "./utils/file-util";
import {
  parseNullableTextCell,
  parseTextCell,
  parseRequiredNumberCell,
  parseFlagCell,
  parseNullableNumberCell,
  parseRequiredTextCell,
  stringCellAddress,
  loadSheet,
} from "./utils/xlsx-util";

/**
 * 現在編集中の音声ガイド。
 */
export const currentGuide = new Suspensive<GuideVersion>(createGuideVersion());

/**
 * 編集開始時の音声ガイド
 */
let prevGuide: GuideVersion | undefined;

// for debug
(window as any).$guide = currentGuide;

/**
 * 指定された音声ガイドのデータを fetch して、編集用にセットする。
 *
 * @param guideId 取得対象の音声ガイドの ID
 * @param copy 取得後の音声ガイドをコピーとして扱うかどうか
 */
export function fetchGuide(guideId: string, copy?: boolean) {
  currentGuide.value = fetchJsonApi({
    type: "getGuideVersion",
    guideId: parseInt(guideId, 10),
  })
    .then((res) => {
      if (!res.item) {
        throw new Error(`音声ガイド ${guideId} が見つかりませんでした。`);
      }

      const { item } = res;

      if (copy) {
        makeGuideForCopy(item);
      }

      // ガイド番号順ソート。
      item.contents.sort((lhs, rhs) => lhs.guideNumber - rhs.guideNumber);

      if (!copy) {
        return item;
      }

      // コピー作成時には API copyMediaFiles も呼ぶ。
      return fetchJsonApi({
        type: "copyMediaFiles",
        guideId: parseInt(guideId, 10),
      }).then((res) => {
        return item;
      });
    })
    .then((item) => {
      setPrevGuide(item);

      return item;
    });

  return currentGuide;
}

/**
 * 音声ガイドのデータをコピーとして扱えるようにする。
 *
 * @param guide
 */
function makeGuideForCopy(guide: GuideVersion) {
  guide.guideVersionId = phantomId();
  guide.guideId = phantomId();
  guide.versionNumber = phantomId();
  guide.status = "notOpened";
  guide.name_ja = "（コピー）" + guide.name_ja;

  const guideLanguageIdMap: { [old: number]: number } = {};

  guide.guideLanguages.forEach((guideLanguage) => {
    const phantomGuideLanguageId = phantomId();
    guideLanguageIdMap[guideLanguage.guideLanguageId] = phantomGuideLanguageId;

    guideLanguage.guideLanguageId = phantomGuideLanguageId;
    guideLanguage.guideVersionId = guide.guideVersionId;
  });

  guide.contents.forEach((content) => {
    content.contentId = phantomId();
    content.guideVersionId = guide.guideVersionId;

    content.contentByLanguages.forEach((contentByLanguage) => {
      contentByLanguage.contentByLanguageId = phantomId();
      contentByLanguage.contentId = content.contentId;
      contentByLanguage.guideLanguageId =
        guideLanguageIdMap[contentByLanguage.guideLanguageId];
    });
  });
}

/**
 * 編集中の音声ガイドデータを新規作成用にクリアする。
 */
export function clearGuide() {
  currentGuide.set(createGuideVersion());
  setPrevGuide(currentGuide.value);

  return currentGuide;
}

function setPrevGuide(guide: GuideVersion) {
  prevGuide = JSON.parse(JSON.stringify(guide));
}

/**
 * 音声ガイドが編集状態にあるかチェック
 */
export function currentGuideHasChanged(): boolean {
  const current = currentGuide.value;
  const prev = prevGuide;

  if (!prev) {
    throw new Error("編集状態チェックに失敗");
  }

  if (
    current.topImage !== prev.topImage ||
    current.name_ja !== prev.name_ja ||
    current.name_en !== prev.name_en ||
    current.description_ja !== prev.description_ja ||
    current.description_en !== prev.description_en ||
    current.auxGuideId !== prev.auxGuideId ||
    current.periodBegin !== prev.periodBegin ||
    current.periodEnd !== prev.periodEnd ||
    current.streaming !== prev.streaming ||
    current.startupSound !== prev.startupSound ||
    current.playOnInputNumber !== prev.playOnInputNumber ||
    current.playOnGps !== prev.playOnGps ||
    current.playOnBeacon !== prev.playOnBeacon ||
    current.qrCodeEnabled !== prev.qrCodeEnabled ||
    current.limitByGpsLatitude !== prev.limitByGpsLatitude ||
    current.limitByGpsLongitude !== prev.limitByGpsLongitude ||
    current.limitByGpsRadius !== prev.limitByGpsRadius ||
    current.limitByPassword !== prev.limitByPassword ||
    current.antiTheftLatitude !== prev.antiTheftLatitude ||
    current.antiTheftLongitude !== prev.antiTheftLongitude ||
    current.antiTheftRadius !== prev.antiTheftRadius
  ) {
    return true;
  }

  if (current.contents.length !== prev.contents.length) {
    return true;
  }

  const contentsHasChanged = current.contents.some((cc, i) => {
    const pc = prev.contents[i];

    if (
      cc.guideNumber !== pc.guideNumber ||
      cc.image !== pc.image ||
      cc.movie !== pc.movie ||
      cc.textVisible !== pc.textVisible ||
      cc.gpsPlayLimit !== pc.gpsPlayLimit ||
      cc.gpsLatitude !== pc.gpsLatitude ||
      cc.gpsLongitude !== pc.gpsLongitude ||
      cc.gpsRadius !== pc.gpsRadius ||
      cc.gpsLatitude1 !== pc.gpsLatitude1 ||
      cc.gpsLongitude1 !== pc.gpsLongitude1 ||
      cc.gpsRadius1 !== pc.gpsRadius1 ||
      cc.gpsLatitude2 !== pc.gpsLatitude2 ||
      cc.gpsLongitude2 !== pc.gpsLongitude2 ||
      cc.gpsRadius2 !== pc.gpsRadius2 ||
      cc.gpsLatitude3 !== pc.gpsLatitude3 ||
      cc.gpsLongitude3 !== pc.gpsLongitude3 ||
      cc.gpsRadius3 !== pc.gpsRadius3 ||
      cc.beaconPlayLimit !== pc.beaconPlayLimit ||
      cc.beaconId !== pc.beaconId ||
      cc.beaconStrength !== pc.beaconStrength ||
      cc.beaconId1 !== pc.beaconId1 ||
      cc.beaconStrength1 !== pc.beaconStrength1 ||
      cc.beaconId2 !== pc.beaconId2 ||
      cc.beaconStrength2 !== pc.beaconStrength2 ||
      cc.beaconId3 !== pc.beaconId3 ||
      cc.beaconStrength3 !== pc.beaconStrength3
    ) {
      return true;
    }

    const contentByLanguagesHasChanged = cc.contentByLanguages.some(
      (ccbl, i) => {
        const pcbl = pc.contentByLanguages[i];

        if (
          ccbl.name !== pcbl.name ||
          ccbl.text !== pcbl.text ||
          ccbl.title !== pcbl.title ||
          ccbl.voice !== pcbl.voice
        ) {
          return true;
        }

        return false;
      }
    );

    if (contentByLanguagesHasChanged) {
      return true;
    }

    current.guideLanguages;

    return false;
  });

  if (contentsHasChanged) {
    return true;
  }

  if (current.guideLanguages.length !== prev.guideLanguages.length) {
    return true;
  }

  const guideLanguagesHasChanged = current.guideLanguages.some((cgl, i) => {
    const pgl = current.guideLanguages[i];

    if (
      cgl.name_ja !== pgl.name_ja ||
      cgl.name_en !== pgl.name_en ||
      cgl.priority !== pgl.priority ||
      cgl.sortKey !== pgl.sortKey
    ) {
      return true;
    }

    return false;
  });

  if (guideLanguagesHasChanged) {
    return true;
  }

  return false;
}

function createGuideVersion(): GuideVersion {
  const guideVersionId = phantomId();

  return {
    guideVersionId,
    guideId: phantomId(),
    versionNumber: phantomId(),
    topImage: null,
    topImageSize: null,
    name_ja: "",
    name_en: "",
    description_ja: "",
    description_en: "",
    auxGuideId: "",
    periodBegin: jstToday(),
    periodEnd: jstToday(),
    streaming: 1,
    startupSound: "",
    startupSoundSize: null,
    playOnInputNumber: 0,
    playOnGps: 0,
    playOnBeacon: 0,
    qrCodeEnabled: 0,
    limitByGpsLatitude: null,
    limitByGpsLongitude: null,
    limitByGpsRadius: null,
    limitByPassword: null,
    antiTheftLatitude: null,
    antiTheftLongitude: null,
    antiTheftRadius: null,

    contents: [],
    guideLanguages: [
      {
        guideLanguageId: phantomId(),
        guideVersionId,
        name_ja: "日本語",
        name_en: "Japanese",
        sortKey: 0,
        priority: 0,
      },
    ],
    status: "notOpened",
  };
}

/**
 * 編集中の音声ガイドを保存、および公開する。
 *
 * 公開済み音声ガイドの場合、保存と同時に再公開も行う。
 *
 * @param options
 * @returns 保存、および公開成功時に true を返す。
 *          公開時のデータ検証エラー時にはエラーメッセージの配列を返す。
 */
export async function saveAndPublish(
  options: {
    /**
     * 保存の後に公開を行うかどうか。
     */
    publish?: boolean;
  } = {}
) {
  // ContentByLanguage.name を最新化。（言語名変更に対応するため）
  currentGuide.value.contents.forEach((content) =>
    content.contentByLanguages.forEach((contentByLanguage) => {
      contentByLanguage.name = findGuideLanguage(
        currentGuide.value,
        contentByLanguage.guideLanguageId
      ).name_ja;
    })
  );

  const guide = currentGuide.value;
  const item = isPhantomId(guide.guideId)
    ? guide
    : {
        ...guide,
        versionNumber: guide.versionNumber + 1,
      };

  // 公開済み、または公開ボタン押された時、公開処理が必要。
  const needsOpenGuide = item.status === "opened" || options.publish;

  // 公開用データとして不備がある場合、保存処理自体を行わない。
  const errors = validate(item);
  if (errors.length > 0) {
    return errors;
  }

  const { guideId } = await fetchJsonApi({
    type: "postGuideVersion",
    item,
  });

  if (needsOpenGuide) {
    await fetchJsonApi({
      type: "openGuide",
      guideId,
    });
  }

  setPrevGuide(item);

  return true;
}

/**
 * 現在閲覧している音声ガイドをエクスポートする。
 */
export async function exportGuide() {
  while (true) {
    const result = await fetchJsonApi({
      type: "exportGuideVersion",
      guideId: currentGuide.value.guideId,
    });

    if (result.status === "COMPLETED") {
      return result;
    }

    await sleep(10000);
  }
}

/**
 * 音声ガイドの「基本設定」の入力値を検証。
 */
export function validateBasicSettings() {
  const guide = currentGuide.value;
  const item = isPhantomId(guide.guideId)
    ? guide
    : {
        ...guide,
        versionNumber: guide.versionNumber + 1,
      };

  return validate(item, { onlyBasic: true });
}

/**
 * 音声ガイドを公開データとして検証。
 */
function validate(item: GuideVersion, options?: { onlyBasic: boolean }) {
  const errors: string[] = [];

  if (!item.name_ja) {
    errors.push("「音声ガイド名」を入力してください。");
  }

  if (!item.topImage) {
    errors.push("「トップ画像」をアップロードしてください。");
  }

  if (item.periodBegin && item.periodEnd) {
    if (item.periodBegin > item.periodEnd) {
      errors.push("「利用期間」の終了日は開始日以降に設定してください。");
    }

    if (item.periodEnd < jstToday()) {
      errors.push("「利用期間」の終了日は本日以降に設定してください。");
    }
  }

  if (
    item.playOnBeacon === 0 &&
    item.playOnGps === 0 &&
    item.playOnInputNumber === 0
  ) {
    errors.push("「再生方法」を設定してください。");
  }

  if (options?.onlyBasic) {
    return errors;
  }

  if (item.contents.length === 0) {
    errors.push("コンテンツを少なくとも１つは作成してください。");
  } else {
    for (const content of item.contents) {
      const { guideNumber, contentByLanguages } = content;

      if (contentByLanguages.every((c) => !c.title)) {
        errors.push(
          `ガイド番号 ${guideNumber} のコンテンツにタイトルを設定してください。`
        );
      }

      if (!content.movie && contentByLanguages.every((c) => !c.voice)) {
        errors.push(
          `ガイド番号 ${guideNumber} のコンテンツに音声、または動画のコンテンツをアップロードしてください。`
        );
      }

      // if (
      //   item.playOnGps &&
      //   (content.gpsLatitude === null ||
      //     content.gpsLongitude === null ||
      //     content.gpsRadius === null)
      // ) {
      //   errors.push(
      //     `ガイド番号 ${guideNumber} のコンテンツにGPS情報を設定してください。`
      //   );
      // }

      if (
        item.playOnBeacon &&
        (content.beaconId === null || content.beaconStrength === null)
      ) {
        errors.push(
          `ガイド番号 ${guideNumber} のコンテンツにビーコン情報を設定してください。`
        );
      }
    }
  }

  return errors;
}

/**
 * 音声ガイドのインポートを行う。
 *
 * @param fileList ディレクトリアップロードで得られたファイル一覧。
 */
export async function importGuide(fileList: FileList | null) {
  if (!fileList) {
    throw new Error("ファイルの一覧が取得できません。");
  }

  //
  // ファイル一覧から検索しやすいようにマップを作成する。
  //
  // 各ファイルの先頭のパスセグメントを削除してディレクトリ内相対パスを取得する。
  // さらに検索しやすいよう拡張子も削除したものをキーとする。
  //
  const files = Array.from(fileList).reduce((files, file) => {
    const relativePath = fileRelativePath(file);

    const key = relativePath
      .substr(relativePath.indexOf("/") + 1)
      .replace(/\.[^\.]+$/, "");
    files[key] = file;

    return files;
  }, {} as Record<string, File>);

  console.log(files);

  //
  // ファイルマップから指定されたキーに対するファイルがあれば、アップロードする関数を定義。
  //
  // アップロード後のサーバーで生成されたファイル名を返す。
  // ファイルマップにファイルがなければ null を返す。
  //
  const uploadFile = async (
    key: string,
    accept: typeof ACCEPT_IMAGE | typeof ACCEPT_MOVIE | typeof ACCEPT_AUDIO
  ) => {
    const file = files[key];
    if (!file) {
      return null;
    } else {
      return uploadFileForImport(file, accept);
    }
  };

  //
  // guide.xlsx 読み込み。
  //
  const guideXlsxFile = files["guide"];

  if (!guideXlsxFile) {
    throw new Error("guide.xlsx ファイルがありません。");
  }

  const workbook = XLSX.read(await guideXlsxFile.arrayBuffer());

  const basicSheet = loadSheet(workbook, "基本設定");
  if (!basicSheet) {
    throw new Error("guide.xlsx 内にシート「基本設定」がありません。");
  }

  const contentsSheet = loadSheet(workbook, "コンテンツ設定");
  if (!contentsSheet) {
    throw new Error("guide.xlsx 内にシート「コンテンツ設定」がありません。");
  }

  //
  // 基本設定シートから言語設定を読み込み。
  //
  const numberOfLanguages = parseRequiredNumberCell(basicSheet, {
    row: 7,
    col: 2,
  });

  const rowLangSort = 8;
  const rowLangPriority = rowLangSort + numberOfLanguages;
  const rowAfterLang = rowLangPriority + numberOfLanguages;

  const guideLanguages: GuideLanguage[] = [];

  for (let row = rowLangSort; row < rowLangPriority; ++row) {
    guideLanguages.push({
      guideLanguageId: phantomId(),
      guideVersionId: currentGuide.value.guideVersionId,
      name_ja: parseRequiredTextCell(basicSheet, { row, col: 2 }),
      name_en: parseRequiredTextCell(basicSheet, { row, col: 3 }),
      sortKey: row - rowLangSort,
      priority: 0,
    });
  }

  for (let row = rowLangPriority; row < rowAfterLang; ++row) {
    const address = { row, col: 2 };
    const langName = parseRequiredTextCell(basicSheet, address);

    const lang = guideLanguages.find((lang) => lang.name_ja === langName);
    if (!lang) {
      const stringAddress = stringCellAddress(address);
      throw new Error(
        `シート ${basicSheet.name} のセル ${stringAddress} で指定された言語 ${langName} は、言語：表示順で指定されていない言語です。`
      );
    }

    lang.priority = row - rowLangPriority;
  }

  //
  // 基本設定シートから言語以外の設定を読み込み。
  //
  const guideVersion: GuideVersion = {
    guideVersionId: currentGuide.value.guideVersionId,
    guideId: currentGuide.value.guideId,
    versionNumber: currentGuide.value.versionNumber,
    topImage: await uploadFile("topImage", ACCEPT_IMAGE),
    topImageSize: null,
    name_ja: parseTextCell(basicSheet, { row: 2, col: 2 }),
    name_en: parseTextCell(basicSheet, { row: 2, col: 3 }),
    description_ja: parseNullableTextCell(basicSheet, { row: 3, col: 2 }) || "",
    description_en: parseNullableTextCell(basicSheet, { row: 3, col: 3 }) || "",
    auxGuideId: parseNullableTextCell(basicSheet, { row: 4, col: 2 }) || "",
    periodBegin: parseNullableTextCell(basicSheet, { row: 5, col: 2 }),
    periodEnd: parseNullableTextCell(basicSheet, { row: 6, col: 2 }),
    startupSound: await uploadFile("startupSound", ACCEPT_MOVIE),
    startupSoundSize: null,
    playOnInputNumber: parseFlagCell(basicSheet, {
      row: rowAfterLang + 0,
      col: 2,
    }),
    playOnGps: parseFlagCell(basicSheet, { row: rowAfterLang + 1, col: 2 }),
    playOnBeacon: parseFlagCell(basicSheet, { row: rowAfterLang + 2, col: 2 }),
    streaming: parseFlagCell(basicSheet, { row: rowAfterLang + 3, col: 2 }),
    qrCodeEnabled: parseFlagCell(basicSheet, { row: rowAfterLang + 4, col: 2 }),
    limitByGpsLatitude: parseNullableNumberCell(basicSheet, {
      row: rowAfterLang + 5,
      col: 2,
    }),
    limitByGpsLongitude: parseNullableNumberCell(basicSheet, {
      row: rowAfterLang + 6,
      col: 2,
    }),
    limitByGpsRadius: parseNullableNumberCell(basicSheet, {
      row: rowAfterLang + 7,
      col: 2,
    }),
    limitByPassword: parseNullableTextCell(basicSheet, {
      row: rowAfterLang + 8,
      col: 2,
    }),
    antiTheftLatitude: parseNullableNumberCell(basicSheet, {
      row: rowAfterLang + 9,
      col: 2,
    }),
    antiTheftLongitude: parseNullableNumberCell(basicSheet, {
      row: rowAfterLang + 10,
      col: 2,
    }),
    antiTheftRadius: parseNullableNumberCell(basicSheet, {
      row: rowAfterLang + 11,
      col: 2,
    }),

    contents: [],
    guideLanguages,
    status: "notOpened",
  };

  //
  // コンテンツ設定シートからコンテンツの数を算出。
  //
  let numberOfContents = 0;

  while (
    parseNullableTextCell(contentsSheet, {
      row: 2 + numberOfContents * numberOfLanguages,
      col: 1,
    })
  ) {
    ++numberOfContents;
  }

  if (!numberOfContents) {
    throw new Error("シート「コンテンツ設定」にコンテンツがありません。");
  }

  for (let i = 0; i < numberOfContents; ++i) {
    const contentId = phantomId();
    const contentByLanguages: ContentByLanguage[] = [];

    const row = 2 + i * numberOfLanguages;

    const guideNumber = parseRequiredNumberCell(contentsSheet, { row, col: 1 });

    for (let j = 0; j < numberOfLanguages; ++j) {
      const guideLanguage = guideLanguages[j];

      contentByLanguages.push({
        contentByLanguageId: phantomId(),
        contentId,
        guideLanguageId: guideLanguage.guideLanguageId,
        title: parseRequiredTextCell(contentsSheet, { row: row + j, col: 2 }),
        text:
          parseNullableTextCell(contentsSheet, { row: row + j, col: 5 }) || "",
        voice: await uploadFile(
          `${guideLanguage.name_en}${guideNumber}`,
          ACCEPT_AUDIO
        ),
        voiceSize: null,
        name: guideLanguage.name_ja,
      });
    }

    guideVersion.contents.push({
      contentId,
      guideVersionId: guideVersion.guideVersionId,
      guideNumber,
      image: await uploadFile(`image${guideNumber}`, ACCEPT_IMAGE),
      imageSize: null,
      movie: await uploadFile(`movie${guideNumber}`, ACCEPT_MOVIE),
      movieSize: null,
      streaming: parseFlagCell(contentsSheet, { row, col: 3 }),
      textVisible: parseFlagCell(contentsSheet, { row, col: 4 }),
      gpsLatitude: parseNullableNumberCell(contentsSheet, { row, col: 6 }),
      gpsLongitude: parseNullableNumberCell(contentsSheet, { row, col: 7 }),
      gpsRadius: parseNullableNumberCell(contentsSheet, { row, col: 8 }),
      gpsLatitude1: parseNullableNumberCell(contentsSheet, { row, col: 9 }),
      gpsLongitude1: parseNullableNumberCell(contentsSheet, { row, col: 10 }),
      gpsRadius1: parseNullableNumberCell(contentsSheet, { row, col: 11 }),
      gpsLatitude2: parseNullableNumberCell(contentsSheet, { row, col: 12 }),
      gpsLongitude2: parseNullableNumberCell(contentsSheet, { row, col: 13 }),
      gpsRadius2: parseNullableNumberCell(contentsSheet, { row, col: 14 }),
      gpsLatitude3: parseNullableNumberCell(contentsSheet, { row, col: 15 }),
      gpsLongitude3: parseNullableNumberCell(contentsSheet, { row, col: 16 }),
      gpsRadius3: parseNullableNumberCell(contentsSheet, { row, col: 17 }),
      gpsPlayLimit: parseNullableNumberCell(contentsSheet, { row, col: 18 }),
      beaconId: parseNullableTextCell(contentsSheet, { row, col: 19 }),
      beaconStrength: parseNullableNumberCell(contentsSheet, { row, col: 20 }),
      beaconId1: parseNullableTextCell(contentsSheet, { row, col: 21 }),
      beaconStrength1: parseNullableNumberCell(contentsSheet, { row, col: 22 }),
      beaconId2: parseNullableTextCell(contentsSheet, { row, col: 23 }),
      beaconStrength2: parseNullableNumberCell(contentsSheet, { row, col: 24 }),
      beaconId3: parseNullableTextCell(contentsSheet, { row, col: 25 }),
      beaconStrength3: parseNullableNumberCell(contentsSheet, { row, col: 26 }),
      beaconPlayLimit: parseNullableNumberCell(contentsSheet, { row, col: 27 }),
      contentByLanguages,
    });
  }

  //
  // 読み込んだ結果を編集中の音声ガイドとしてセット。
  //
  currentGuide.set(guideVersion);
  setPrevGuide(currentGuide.value);
}

/**
 * 音声ガイドのインポート用にファイルをアップロードする。
 *
 * @param file
 * @param accept
 * @returns アップロード後のファイル名。
 */
async function uploadFileForImport(
  file: File,
  accept: typeof ACCEPT_IMAGE | typeof ACCEPT_MOVIE | typeof ACCEPT_AUDIO
) {
  const relativePath = fileRelativePath(file);

  //
  // アップロードするファイルの拡張子を求める。
  //
  const extensionCandidates = extensions[file.type];
  if (!extensionCandidates) {
    throw new Error(
      `サポートされていない種類のファイルです。 ファイル： ${relativePath}`
    );
  }

  const ext =
    extensionCandidates.find((ec) =>
      (accept as unknown as string[]).includes(ec)
    ) ?? extensionCandidates[0];

  //
  // アップロード。
  //
  const res = await fetchJsonApi({
    type: "getSignedUrl",
    operation: "putObject",
    ext,
  });

  await fetch(res.url, {
    method: "PUT",
    mode: "cors",
    headers: {
      "content-type": file.type,
    },
    body: file,
  });

  return `${res.uuid}.${ext}`;
}
