import {
  CognitoUser,
  CognitoUserPool,
  CognitoUserSession,
} from "amazon-cognito-identity-js";
import { throwException } from "./utils/util";

export const cognitoUserPool = new CognitoUserPool({
  UserPoolId:
    process.env.USER_POOL_ID || throwException("USER_POOL_ID must be set"),
  ClientId:
    process.env.WEB_CLIENT_ID || throwException("WEB_CLIENT_ID must be set"),
});

let cognitoUser = cognitoUserPool.getCurrentUser();

export function getSignedInUser() {
  if (!cognitoUser) {
    throw new Error("cognitoUser not found");
  }

  return cognitoUser;
}

export function setSignedInUser(signedInUser: CognitoUser) {
  cognitoUser = signedInUser;
}

export function isSignedIn() {
  return !!cognitoUser;
}

export function restoreSession(): Promise<CognitoUserSession> {
  return new Promise<CognitoUserSession>((resolve, reject) => {
    getSignedInUser().getSession(
      (err: Error | null, session: CognitoUserSession) => {
        err ? reject(err) : resolve(session);
      }
    );
  });
}

export function signOut() {
  if (cognitoUser) {
    cognitoUser.signOut();
  }
}

export function changePassword(
  oldPassword: string,
  newPassword: string
): Promise<void> {
  return new Promise<void>((resolve, reject) => {
    getSignedInUser().changePassword(
      oldPassword,
      newPassword,
      (err?: Error) => {
        err ? reject(err) : resolve();
      }
    );
  });
}

export function forgotPassword(email: string): Promise<void> {
  const cognitoUser = new CognitoUser({
    Username: email,
    Pool: cognitoUserPool,
  });

  return new Promise<void>((resolve, reject) => {
    cognitoUser.forgotPassword({
      onSuccess: resolve,
      onFailure: reject,
    });
  });
}

export function confirmPassword(
  email: string,
  verificationCode: string,
  newPassword: string
): Promise<void> {
  const cognitoUser = new CognitoUser({
    Username: email,
    Pool: cognitoUserPool,
  });

  return new Promise<void>((resolve, reject) => {
    cognitoUser.confirmPassword(verificationCode, newPassword, {
      onSuccess: resolve,
      onFailure: reject,
    });
  });
}
