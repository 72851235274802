import { useLocation } from "react-router";

/**
 * クエリーパラメーターを取得する。
 *
 * @returns
 */
export function useQueryParams<T>(): T {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const params = {} as any;

  searchParams.forEach((value, key) => (params[key] = value));

  return params;
}
