import React from "react";
import { useParams } from "react-router-dom";
import { useLocalSuspensive, Wait } from "react-suspensive";
import { fetchJsonApi } from "./fetcher";
import { safeParseInt } from "./utils/util";
import QRCode from "qrcode.react";

/**
 * QRコード印刷。
 */
export function PrintQR() {
  const { qrCodeId } = useParams<{ qrCodeId: string }>();

  const data = useLocalSuspensive(async () => {
    try {
      const qrCode = await fetchJsonApi({
        type: "getQRCode",
        qrCodeId: safeParseInt(qrCodeId),
        includeKeys: true,
      });

      const guide = await fetchJsonApi({
        type: "getGuideVersion",
        guideId: qrCode.item.guideId,
      });

      let imageUrl: string | undefined = undefined;

      if (qrCode.item.qrImage) {
        const [uuid, ext] = qrCode.item.qrImage?.split(".")!;

        const image = await fetchJsonApi({
          type: "getSignedUrl",
          operation: "getObject",
          qrCodeId: qrCode.item.qrCodeId,
          uuid,
          ext,
        });

        imageUrl = image.url;
      }

      return {
        err: undefined,
        qrCode: qrCode.item,
        guide: guide.item,
        imageUrl,
      };
    } catch (err) {
      return {
        err,
        qrCode: undefined,
        guide: undefined,
        imageUrl: undefined,
      };
    }
  }, [qrCodeId]);

  return (
    <Wait
      suspensive={data}
      render={(data) => {
        const { err, qrCode, guide, imageUrl } = data;

        if (err) {
          return (
            <div>
              <div>QRコードの取得でエラーが発生しました。</div>
              <div>{err.message}</div>
            </div>
          );
        }

        if (!qrCode || !qrCode.keys || qrCode.keys.length === 0) {
          return (
            <div>
              <div>QRコード${qrCodeId}が見つかりません。</div>
            </div>
          );
        }

        return (
          <>
            {qrCode.keys.map((key) => (
              <div className="PrintQR" key={key}>
                <div className="PrintQR__image">
                  <img className="PrintQR__image__img" src={imageUrl} />
                </div>
                <div className="PrintQR__facilityName">
                  {qrCode?.facilityName}
                </div>
                <div className="PrintQR__name">{qrCode?.name}</div>
                <div className="PrintQR__guideName">
                  {guide?.name_ja}：
                  {qrCode?.kind === "general" ? "一般" : "団体"}
                </div>
                <div className="PrintQR__period">
                  有効期限：{qrCode?.periodBegin.replace(/-/g, "/")} ～{" "}
                  {qrCode?.periodEnd.replace(/-/g, "/")}
                </div>
                <div className="PrintQR__qr">
                  <QRCode value={urlForKey(key)} />
                </div>
                <div className="PrintQR__description">
                  {qrCode?.description_ja}
                </div>
                <div className="PrintQR__description">
                  {qrCode?.description_en}
                </div>
              </div>
            ))}
          </>
        );
      }}
    />
  );
}

/**
 * Key からストア誘導ページへの URL を生成する。
 *
 * @param key
 * @returns
 */
function urlForKey(key: string) {
  const u = new URL(window.location.href);
  u.pathname = `/s/${key}`;
  return u.href;
}
