import React from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import { AdminUsersEdit } from "./AdminUsersEdit";
import { AdminUsersList } from "./AdminUsersList";

/**
 * ユーザー管理画面。
 *
 * ユーザー管理管理系の各画面にルーティングする。
 */
export function AdminUsers() {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route path={path} exact component={AdminUsersList} />
      <Route path={`${path}/:userId`} component={AdminUsersEdit} />
    </Switch>
  );
}
