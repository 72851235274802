import React, { ChangeEvent, useCallback } from "react";
import { GuideVersion } from "./common";

/**
 * 音声ガイド編集画面 - 基本設定 - QRコード。
 */
export function GuidesEditBasicQRCode(props: { guide: GuideVersion }) {
  const { guide } = props;

  const changeListener = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target;
      guide.qrCodeEnabled = value === "1" ? 1 : 0;
    },
    [guide]
  );

  return (
    <>
      <dt className="must">QRコード</dt>
      <dd onChange={changeListener}>
        <input
          type="radio"
          name="QRコード"
          value="1"
          defaultChecked={guide.qrCodeEnabled === 1}
        />
        <label htmlFor="qr1">利用する</label>
        <input
          type="radio"
          name="QRコード"
          value="0"
          defaultChecked={guide.qrCodeEnabled === 0}
        />
        <label htmlFor="qr2">利用しない</label>
      </dd>
    </>
  );
}
