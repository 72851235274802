import React, { MouseEvent } from "react";

export interface InputFileProps<T>
  extends Omit<React.InputHTMLAttributes<HTMLInputElement>, "type"> {
  /**
   * 削除ボタンの表示フラグ (true: 表示、 false: 非表示)
   */
  deleteVisible: boolean;

  /**
   * 削除ボタン押下のイベントリスナー
   */
  onDelete: (event: MouseEvent) => void;
}

/**
 * input[type="file"] のカスタマイズ版。
 *
 * UI をカスタマイズするため label で囲った input[type="file"] を生成する。
 *
 * input[type="file"] は直接 CSS でデザイン変更できないので
 * extra.css で非表示にし、代わりに label 要素を設定する。
 */
export function InputFile<T>(props: InputFileProps<T>) {
  const { deleteVisible, onDelete, ...restProps } = props;

  return (
    <>
      <label className="file">
        ファイルを選択
        <input type="file" {...restProps} />
      </label>
      {deleteVisible && (
        <label className="file" onClick={onDelete}>
          <span>削除</span>
        </label>
      )}
    </>
  );
}
