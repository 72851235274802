import React, { ChangeEvent, useCallback } from "react";
import { Content } from "./common";

/**
 * 音声ガイド編集画面 - コンテンツ設定 - 配信方法。
 */
export function GuidesEditContentsDelivery(props: { content: Content }) {
  const { content } = props;

  const changeListener = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target;
      content.streaming = value === "1" ? 1 : 0;
    },
    [content]
  );

  const delivertId = `content-${content.contentId}-delivery`;

  return (
    <div>
      <h2>配信方法</h2>
      <dl className="indent">
        <dt></dt>
        <dd onChange={changeListener}>
          <input
            type="radio"
            name={delivertId}
            value="1"
            id={`${delivertId}1`}
            defaultChecked={content.streaming === 1}
          />
          <label htmlFor={`${delivertId}1`}>ストリーミング再生</label>
          <input
            type="radio"
            name={delivertId}
            value="0"
            id={`${delivertId}0`}
            defaultChecked={content.streaming === 0}
          />
          <label htmlFor={`${delivertId}0`}>ダウンロード</label>
        </dd>
      </dl>
    </div>
  );
}
