import React from "react";
import { Link, useRouteMatch } from "react-router-dom";
import { isGeneralUser } from "./Account-store";
import { preventDefault } from "./utils/react-util";
import { isRekihaku } from "./utils/util";

export function GuidesNav() {
  const { url } = useRouteMatch();
  const listMatch = useRouteMatch({ path: "/:tenantId/guides", exact: true });
  const newMatch = useRouteMatch({
    path: "/:tenantId/guides/new",
    exact: true,
  });
  const importMatch = useRouteMatch({
    path: "/:tenantId/guides/import",
    exact: true,
  });

  const readOnly = isGeneralUser();

  return (
    <div className="nav2Wrapper">
      <ul id="nav2">
        {!readOnly && (
          <>
            <li className={newMatch ? "active" : undefined}>
              <Link to={`${url}/new`}>新規作成</Link>
            </li>
            {!isRekihaku() && (
              <li className={importMatch ? "active" : undefined}>
                <Link to={`${url}/import`} onClick={preventDefault}>
                  収蔵品からインポート
                </Link>
              </li>
            )}
          </>
        )}
        <li className={listMatch ? "active" : undefined}>
          <Link to={url}>一覧</Link>
        </li>
      </ul>
    </div>
  );
}
