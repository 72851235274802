import React, { useRef } from "react";
import { QRCode } from "./common";
import { InputFile } from "./components/InputFile";
import { ACCEPT_IMAGE, useUploader } from "./hooks/useUploader";

/**
 * QRコード編集画面 - 画像。
 */
export function QREditImage(props: { qrCode: QRCode }) {
  const { qrCode } = props;

  const imageRef = useRef<HTMLImageElement>(null);

  const uploaderProps = useUploader({
    qrCodeId: qrCode.qrCodeId,
    obj: qrCode,
    propName: "qrImage",
    imageRef,
    accept: ACCEPT_IMAGE,
  });

  return (
    <>
      <dt>画像</dt>
      <dd>
        <InputFile name="画像・動画" id="myImage" {...uploaderProps} />
        <img id="preview" ref={imageRef} />
      </dd>
    </>
  );
}
