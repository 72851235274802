import React, { ChangeEvent, useCallback, useEffect, useState } from "react";
import { useLocalSuspensive, Wait } from "react-suspensive";
import { fetchOrganization, isPrivilegedOrganization } from "./Account-store";
import { AdminQueryOrganizationsResponse } from "./common/AdminQueryOrganizationsResponse";
import { GetReportResponse } from "./common/GetReportResponse";
import { notifyError } from "./components/NotificationOverlay";
import { fetchJsonApi, fetchJsonApiAsBlob } from "./fetcher";
import { SiteFooter } from "./SiteFooter";
import { SiteHeader } from "./SiteHeader";

interface QueryForm {
  duration: "currentMonth" | "prevMonth" | "custom";
  customStartYear: string;
  customStartMonth: string;
  customEndYear: string;
  customEndMonth: string;
  outputType: "downloadDetail" | "perGuides" | "perGroups";
  organizationId: string;
}

/**
 * レポート画面。
 *
 * @returns
 */
export function Reports() {
  const organizations = useLocalSuspensive(() => {
    if (isPrivilegedOrganization()) {
      return fetchJsonApi({
        type: "adminQueryOrganizations",
        limit: 1000,
        offset: 0,
      });
    } else {
      return undefined;
    }
  }, []);

  return (
    <Wait
      suspensive={organizations}
      render={(organizations) => <ReportsMain organizations={organizations} />}
    />
  );
}

function ReportsMain(props: {
  organizations: AdminQueryOrganizationsResponse | undefined;
}) {
  const [queryState, changeQueryState] = useState<QueryForm>(() => ({
    duration: "currentMonth",
    customStartYear: "" + new Date().getFullYear(),
    customStartMonth: "" + new Date().getMonth(),
    customEndYear: "" + new Date().getFullYear(),
    customEndMonth: "" + new Date().getMonth(),
    outputType: "downloadDetail",
    organizationId: fetchOrganization().value.organizationId.toString(),
  }));

  const [results, changeResults] = useState<GetReportResponse | null>(null);

  const onChange = useCallback(
    (event: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
      const nextState = {
        ...queryState,
        [event.target.name]: event.target.value,
      };

      const { startDate, endDate } = getStartAndEndDate(nextState);

      const promise = props.organizations
        ? fetchJsonApi({
            type: "adminGetReport",
            startDate,
            endDate,
            outputFormat: "json",
            outputType: nextState.outputType,
            organizationId: parseInt(nextState.organizationId, 10),
          })
        : fetchJsonApi({
            type: "getReport",
            startDate,
            endDate,
            outputFormat: "json",
            outputType: nextState.outputType,
          });

      promise
        .then((res) => {
          changeResults(res);
        })
        .catch((err) => {
          notifyError({ message: "レポートの取得に失敗しました。", err });
        });

      changeQueryState(nextState);
    },
    [queryState, changeQueryState]
  );

  const downloadAsCSV = useCallback(() => {
    const { startDate, endDate } = getStartAndEndDate(queryState);

    const promise = props.organizations
      ? fetchJsonApiAsBlob({
          type: "adminGetReport",
          startDate,
          endDate,
          outputFormat: "csv",
          outputType: queryState.outputType,
          organizationId: parseInt(queryState.organizationId, 10),
        })
      : fetchJsonApiAsBlob({
          type: "getReport",
          startDate,
          endDate,
          outputFormat: "csv",
          outputType: queryState.outputType,
        });

    promise
      .then((blob) => {
        const anchor = document.createElement("a");
        anchor.download = "GlobalGuideレポート.csv";
        anchor.href = window.URL.createObjectURL(blob);
        anchor.click();
      })
      .catch((err) => {
        notifyError({ message: "レポートの取得に失敗しました。", err });
      });
  }, [queryState]);

  useEffect(() => {
    const { startDate, endDate } = getStartAndEndDate(queryState);

    const promise = props.organizations
      ? fetchJsonApi({
          type: "adminGetReport",
          startDate,
          endDate,
          outputFormat: "json",
          outputType: queryState.outputType,
          organizationId: parseInt(queryState.organizationId, 10),
        })
      : fetchJsonApi({
          type: "getReport",
          startDate,
          endDate,
          outputFormat: "json",
          outputType: queryState.outputType,
        });

    promise
      .then((res) => {
        changeResults(res);
      })
      .catch((err) => {
        notifyError({ message: "レポートの取得に失敗しました。", err });
      });
  }, []);

  return (
    <>
      <SiteHeader />

      <div className="contentsWrapper">
        <div className="contents">
          <div className="contentsInfoWrapper">
            <div className="contentsBox qr">
              <dl className="contentsInfo">
                <dt>期間</dt>
                <dd>
                  <input
                    type="radio"
                    name="duration"
                    id="type1"
                    value="prevMonth"
                    checked={queryState.duration === "prevMonth"}
                    onChange={onChange}
                  />
                  <label htmlFor="type1">先月</label>
                  <input
                    type="radio"
                    name="duration"
                    id="type2"
                    value="currentMonth"
                    checked={queryState.duration === "currentMonth"}
                    onChange={onChange}
                  />
                  <label htmlFor="type2">当月</label>
                  <input
                    type="radio"
                    name="duration"
                    id="type3"
                    value="custom"
                    checked={queryState.duration === "custom"}
                    onChange={onChange}
                  />
                  <label htmlFor="type3">期間指定</label>
                  {queryState.duration === "custom" && (
                    <div className="reportDuration">
                      <select
                        name="customStartYear"
                        id=""
                        value={queryState.customStartYear}
                        onChange={onChange}
                      >
                        {getYears(3).map((y) => (
                          <option value={y} key={y}>
                            {y}年
                          </option>
                        ))}
                      </select>
                      <span>年</span>
                      <select
                        name="customStartMonth"
                        id=""
                        value={queryState.customStartMonth}
                        onChange={onChange}
                      >
                        <option value="0">1月</option>
                        <option value="1">2月</option>
                        <option value="2">3月</option>
                        <option value="3">4月</option>
                        <option value="4">5月</option>
                        <option value="5">6月</option>
                        <option value="6">7月</option>
                        <option value="7">8月</option>
                        <option value="8">9月</option>
                        <option value="9">10月</option>
                        <option value="10">11月</option>
                        <option value="11">12月</option>
                      </select>
                      <span>月</span>
                      <span>～</span>
                      <select
                        name="customEndYear"
                        id=""
                        value={queryState.customEndYear}
                        onChange={onChange}
                      >
                        {getYears(3).map((y) => (
                          <option value={y} key={y}>
                            {y}年
                          </option>
                        ))}
                      </select>
                      <span>年</span>
                      <select
                        name="customEndMonth"
                        id=""
                        value={queryState.customEndMonth}
                        onChange={onChange}
                      >
                        <option value="0">1月</option>
                        <option value="1">2月</option>
                        <option value="2">3月</option>
                        <option value="3">4月</option>
                        <option value="4">5月</option>
                        <option value="5">6月</option>
                        <option value="6">7月</option>
                        <option value="7">8月</option>
                        <option value="8">9月</option>
                        <option value="9">10月</option>
                        <option value="10">11月</option>
                        <option value="11">12月</option>
                      </select>
                      <span>月</span>
                    </div>
                  )}
                </dd>
                <dt>出力タイプ</dt>
                <dd>
                  <input
                    type="radio"
                    name="outputType"
                    id="type4"
                    value="downloadDetail"
                    checked={queryState.outputType === "downloadDetail"}
                    onChange={onChange}
                  />
                  <label htmlFor="type4">利用明細</label>
                  <input
                    type="radio"
                    name="outputType"
                    id="type5"
                    value="perGuides"
                    checked={queryState.outputType === "perGuides"}
                    onChange={onChange}
                  />
                  <label htmlFor="type5">音声ガイド別</label>
                  <input
                    type="radio"
                    name="outputType"
                    id="type6"
                    value="perGroups"
                    checked={queryState.outputType === "perGroups"}
                    onChange={onChange}
                    disabled
                  />
                  <label htmlFor="type6">団体客別</label>
                </dd>
                {props.organizations && (
                  <>
                    <dt>施設</dt>
                    <dd>
                      <select
                        name="organizationId"
                        id=""
                        value={queryState.organizationId}
                        onChange={onChange}
                      >
                        {props.organizations.items.map((organization) => (
                          <option
                            key={organization.organizationId}
                            value={organization.organizationId}
                          >
                            {organization.name_ja}
                          </option>
                        ))}
                      </select>
                    </dd>
                  </>
                )}
              </dl>
            </div>
          </div>

          {results && (
            <>
              <table className="report">
                <thead>
                  <tr>
                    {results.headers.map((h, i) => (
                      <th key={i}>{h}</th>
                    ))}
                  </tr>
                </thead>

                <tfoot>
                  <tr>
                    {results.footers.map((f, i) => (
                      <th key={i}>{f}</th>
                    ))}
                  </tr>
                </tfoot>

                <tbody>
                  {results.data.map((columns, i) => (
                    <tr key={i}>
                      {columns.map((col, j) => (
                        <td key={j}>{col}</td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>

              <p className="addContents">
                <a href="javascript:void(0)" onClick={downloadAsCSV}>
                  CSVダウンロード
                </a>
              </p>
            </>
          )}
        </div>
      </div>

      <SiteFooter />
    </>
  );
}

function getStartAndEndDate(form: QueryForm) {
  const s = new Date();
  s.setHours(0);
  s.setMinutes(0);
  s.setSeconds(0);
  s.setMilliseconds(0);

  let startDate = "";
  let endDate = "";

  if (form.duration === "currentMonth") {
    // 今月
    s.setDate(1);
    startDate = dateFormat(s);

    s.setMonth(s.getMonth() + 1);
    endDate = dateFormat(s);
  } else if (form.duration === "prevMonth") {
    // 先月
    s.setMonth(s.getMonth() - 1);
    s.setDate(1);
    startDate = dateFormat(s);

    s.setMonth(s.getMonth() + 1);
    endDate = dateFormat(s);
  } else {
    // 期間指定
    s.setFullYear(parseInt(form.customStartYear, 10));
    s.setMonth(parseInt(form.customStartMonth, 10));
    s.setDate(1);
    startDate = dateFormat(s);

    s.setFullYear(parseInt(form.customEndYear, 10));
    s.setMonth(parseInt(form.customEndMonth, 10) + 1);
    s.setDate(1);
    endDate = dateFormat(s);
  }

  return { startDate, endDate };
}

function dateFormat(s: Date) {
  const y = s.getFullYear();
  const m = ("0" + (s.getMonth() + 1)).slice(-2);
  const d = ("0" + s.getDate()).slice(-2);
  return `${y}.${m}.${d}`;
}

function getYears(count: number): number[] {
  const d = new Date();
  const years = [] as number[];
  for (let i = 0; i < count; i++) {
    years.push(d.getFullYear());
    d.setFullYear(d.getFullYear() - 1);
  }
  return years;
}
