import React from "react";

/**
 * サイトのフッター。
 */
export function SiteFooter() {
  return (
    <p id="totop">
      <a href="#header" onClick={scrollToTop}>
        topへ
      </a>
    </p>
  );
}

function scrollToTop(event: React.SyntheticEvent) {
  event.preventDefault();
  window.scrollTo(0, 0);
}
