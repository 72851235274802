import React from "react";
import { Link, useRouteMatch } from "react-router-dom";

export function AdminNav() {
  const { url } = useRouteMatch();

  const orgListMatch = useRouteMatch({
    path: "/:tenantId/admin/organizations",
    exact: true,
  });
  const orgNewMatch = useRouteMatch({
    path: "/:tenantId/admin/organizations/new",
    exact: true,
  });
  const orgUsersMatch = useRouteMatch<{ organizationId: string }>({
    path: "/:tenantId/admin/organizations/:organizationId/users",
  });

  const userListMatch = useRouteMatch({
    path: "/:tenantId/admin/organizations/:organizationId/users",
    exact: true,
  });
  const userNewMatch = useRouteMatch({
    path: "/:tenantId/admin/organizations/:organizationId/users/new",
    exact: true,
  });

  return (
    <div className="nav2Wrapper">
      <ul id="nav2">
        <li className={orgNewMatch ? "active" : undefined}>
          <Link to={`${url}/organizations/new`}>施設新規作成</Link>
        </li>
        <li className={orgListMatch ? "active" : undefined}>
          <Link to={`${url}/organizations`}>施設一覧</Link>
        </li>
        {orgUsersMatch && (
          <>
            <li className={userNewMatch ? "active" : undefined}>
              <Link
                to={`${url}/organizations/${orgUsersMatch.params.organizationId}/users/new`}
              >
                ユーザー新規作成
              </Link>
            </li>
            <li className={userListMatch ? "active" : undefined}>
              <Link
                to={`${url}/organizations/${orgUsersMatch.params.organizationId}/users`}
              >
                ユーザー一覧
              </Link>
            </li>
          </>
        )}
      </ul>
    </div>
  );
}
