import React, { ChangeEvent, useLayoutEffect, useReducer, useRef } from "react";
import { GuideVersion } from "./common";
import { Input } from "./components/Input";
import { setInputRefValue } from "./utils/react-util";
import $ from "jquery";

/**
 * 音声ガイド編集画面 - 基本設定 - 利用期間。
 */
export function GuidesEditBasicPeriod(props: { guide: GuideVersion }) {
  const { guide } = props;

  const periodBeginRef = useRef<HTMLInputElement>(null);
  const periodEndRef = useRef<HTMLInputElement>(null);

  const [noPeriod, setNoPeriod] = useReducer(
    (_: boolean, event: ChangeEvent<HTMLInputElement>) => {
      const { checked } = event.target;

      if (checked) {
        setInputRefValue(periodBeginRef, "");
        setInputRefValue(periodEndRef, "");
        guide.periodBegin = null;
        guide.periodEnd = null;
        $("div.calendar").hide(100);
      } else {
        $("div.calendar").show(100);
      }

      return checked;
    },
    guide,
    (guide) => guide.periodBegin === null && guide.periodEnd === null
  );

  useLayoutEffect(() => {
    if (guide.periodBegin === null && guide.periodEnd === null) {
      $("div.calendar").hide();
    }
  }, [guide]);

  return (
    <>
      <dt className="must">利用期間</dt>
      <dd>
        <div className="calendar mb10">
          <Input
            type="date"
            name="利用開始日"
            id="datepicker1"
            placeholder="カレンダーを表示"
            autoComplete="off"
            className="calendar w45"
            obj={guide}
            propName="periodBegin"
            inputRef={periodBeginRef}
            disabled={noPeriod}
          />
          &nbsp;
          <span className="nyoro">～</span>
          &nbsp;
          <Input
            type="date"
            name="利用終了日"
            id="datepicker2"
            placeholder="カレンダーを表示"
            autoComplete="off"
            className="calendar w45"
            obj={guide}
            propName="periodEnd"
            inputRef={periodEndRef}
            disabled={noPeriod}
          />
        </div>
        <input
          type="checkbox"
          checked={noPeriod}
          id="infiniti"
          onChange={setNoPeriod}
        />
        <label htmlFor="infiniti">無制限</label>
      </dd>
    </>
  );
}
