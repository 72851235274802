import { throwException } from "./util";

/**
 * File オブジェクトから相対パスを取得する。
 *
 * @param file
 */
export function fileRelativePath(file: File) {
  return (
    (file as any).relativePath ||
    (file as any).webkitRelativePath ||
    throwException(
      "ファイルパスの取得に失敗しました。（未対応のブラウザーです）"
    )
  );
}
