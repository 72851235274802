import React, { useRef } from "react";
import { isGeneralUser } from "./Account-store";
import {
  Content,
  ContentByLanguage,
  GuideLanguage,
  GuideVersion,
} from "./common";
import { InputFile } from "./components/InputFile";
import { findContentByLanguage } from "./Guides-util";
import { ACCEPT_AUDIO, useUploader } from "./hooks/useUploader";

/**
 * 音声ガイド編集画面 - コンテンツ設定 - 音声。
 */
export function GuidesEditContentsVoice(props: {
  content: Content;
  guide: GuideVersion;
}) {
  const {
    content: { contentByLanguages },
    guide: { guideId, guideLanguages },
  } = props;

  return (
    <div className="voice_content">
      <h2 className="must">音声</h2>
      {guideLanguages.map((guideLanguage) => {
        const contentByLanguage = findContentByLanguage(
          contentByLanguages,
          guideLanguage
        );
        const key = `${contentByLanguage.contentByLanguageId}-voice`;

        return (
          <VoiceElement
            key={key}
            id={key}
            guideId={guideId}
            contentByLanguage={contentByLanguage}
            guideLanguage={guideLanguage}
          />
        );
      })}
    </div>
  );
}

function VoiceElement(props: {
  id: string;
  guideId: number;
  contentByLanguage: ContentByLanguage;
  guideLanguage: GuideLanguage;
}) {
  const { id, guideId, contentByLanguage, guideLanguage } = props;

  const mediaRef = useRef<HTMLAudioElement>(null);

  const uploaderProps = useUploader({
    guideId,
    obj: contentByLanguage,
    propName: "voice",
    mediaRef,
    accept: ACCEPT_AUDIO,
  });

  const readOnly = isGeneralUser();

  return (
    <dl className="indent">
      <dt>
        <label htmlFor={id}>
          {guideLanguage.name_ja} ({guideLanguage.name_en})
        </label>
      </dt>
      <dd>
        {!readOnly && <InputFile id={id} {...uploaderProps} />}
        <audio ref={mediaRef} controls />
      </dd>
    </dl>
  );
}
