import React from "react";
import { SiteFooter } from "./SiteFooter";
import { SiteHeader } from "./SiteHeader";

export function Collections() {
  return (
    <>
      <SiteHeader />
      <SiteFooter />
    </>
  );
}
