"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isSuspensive = exports.Suspensive = exports.NOT_IN_TRANSITION = void 0;
exports.NOT_IN_TRANSITION = Symbol('Not in transition');
/**
 * Promise wrapper for render-as-you-fetch.
 *
 * ```typescript
 * function ResourceWithLoading() {
 *   const resource = new Suspensive(fetch(...));
 *
 *   return (
 *     <Suspense fallback={<Loading />}>
 *       <Resource resource={resource} />
 *     </Suspense>
 *   );
 * }
 *
 * function Resource(props: { resource: Suspensive<Resource>}) {
 *   // If the value not prepared, throw an exception at the following line.
 *   const resource = props.resource.value;
 *   ...
 * }
 * ```
 */
class Suspensive {
    constructor(promise) {
        this._observers = new Set();
        this._get = () => exports.NOT_IN_TRANSITION;
        this._set(promise);
    }
    _set(promise) {
        try {
            this._fallback = this._get();
        }
        catch (promise) {
            if (promise instanceof Error) {
                // Ignore if the previous promise is rejected.
            }
            else {
                promise === null || promise === void 0 ? void 0 : promise.cancel();
            }
        }
        if (promise instanceof Promise) {
            const wrapped = this._wrapPromise(promise);
            this._get = () => { throw wrapped; };
        }
        else if (promise instanceof Function) {
            this._get = () => {
                throw this._wrapPromise(promise());
            };
        }
        else {
            this._get = () => promise;
        }
    }
    _wrapPromise(promise) {
        let canceled;
        const wrappedPromise = promise.then(value => {
            if (!canceled) {
                this._fallback = exports.NOT_IN_TRANSITION;
                this._get = () => value;
            }
        }, reason => {
            if (!canceled) {
                this._fallback = exports.NOT_IN_TRANSITION;
                this._get = () => { throw reason; };
            }
        });
        wrappedPromise.cancel = () => { canceled = true; };
        return wrappedPromise;
    }
    get value() {
        return this._get();
    }
    set value(value) {
        this.set(value);
    }
    set(value) {
        this._set(value);
        setTimeout(() => {
            this._observers.forEach(observer => observer());
        }, 0);
    }
    get fallback() {
        if (this._fallback === exports.NOT_IN_TRANSITION) {
            throw new Error('The `fallback` property can be get only in a transition');
        }
        return this._fallback;
    }
    set fallback(fallback) {
        this._fallback = fallback;
    }
    hasFallback() {
        return this._fallback !== exports.NOT_IN_TRANSITION;
    }
    clearFallback() {
        this._fallback = exports.NOT_IN_TRANSITION;
        this._get = () => exports.NOT_IN_TRANSITION;
    }
    addObserver(callback) {
        this._observers.add(callback);
    }
    removeObserver(callback) {
        this._observers.delete(callback);
    }
}
exports.Suspensive = Suspensive;
function isSuspensive(obj) {
    return obj instanceof Suspensive;
}
exports.isSuspensive = isSuspensive;
