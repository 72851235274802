import React from "react";
import { useParams } from "react-router-dom";
import { useSuspensive, Wait } from "react-suspensive";
import { clearUser, fetchUser } from "./AdminUsersEdit-store";
import { AdminUsersEditOperations } from "./AdminUsersEditOperations";
import { AdminUsersEditUser } from "./AdminUsersEditUser";
import { safeParseInt } from "./utils/util";

export function AdminUsersEdit() {
  const { userId, organizationId } =
    useParams<{ organizationId: string; userId: string }>();

  const user = useSuspensive(() => {
    if (userId === "new") {
      return clearUser(safeParseInt(organizationId));
    } else {
      return fetchUser(userId);
    }
  }, [organizationId, userId]);

  return (
    <Wait
      suspensive={user}
      render={(user) => (
        <>
          <AdminUsersEditOperations organizationId={organizationId} />

          <div className="contentsInfoWrapper">
            <div className="contentsBox qr">
              <dl className="contentsInfo">
                <AdminUsersEditUser user={user} />
              </dl>
            </div>
          </div>
        </>
      )}
    />
  );
}
