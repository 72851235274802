import { WorkBook, WorkSheet } from "xlsx";

/**
 * xlsx の WorkSheet にシート名を付加したオブジェクトを定義する。
 */
interface SheetWithName {
  object: WorkSheet;
  name: string;
}

/**
 * WorkBook から指定のシート名に一致する WorkSheet を返す。見つからなければ undefined を返す。
 *
 * @param workbook
 * @param sheetName
 * @returns
 */
export function loadSheet(
  workbook: WorkBook,
  sheetName: string
): SheetWithName | undefined {
  const sheet = workbook.Sheets[sheetName];

  return (
    sheet && {
      object: sheet,
      name: sheetName,
    }
  );
}

/**
 * xlsx の WorkSheet に対して数値によるセル位置を定義する。
 *
 * 列については文字指定ではなく、行と同様に１始まりの整数とする。
 */
export interface CellAddress {
  row: number;
  col: number;
}

/**
 * セル位置を文字列表示に変換する。
 *
 * @param address
 * @returns
 */
export function stringCellAddress(address: CellAddress) {
  return stringCol(address.col) + address.row;
}

/**
 * 列を文字列表示に変換する。
 *
 * @param col
 */
function stringCol(col: number) {
  col--;

  const result = [];

  for (;;) {
    const ch = String.fromCharCode(65 + (col % 26));
    result.push(ch);

    col = (col / 26) | 0;
    if (!col) {
      return result.reverse().join("");
    }
  }
}

/**
 *
 * @param sheet
 * @param address
 * @returns
 */
export function parseTextCell(sheet: SheetWithName, address: CellAddress) {
  const cell = sheet.object[stringCellAddress(address)];

  if (!cell) {
    return "";
  }

  return String(cell.v);
}

export function parseRequiredTextCell(
  sheet: SheetWithName,
  address: CellAddress
) {
  const v = parseTextCell(sheet, address);

  if (!v) {
    const stringAddress = stringCellAddress(address);
    throw new Error(
      `シート ${sheet.name} のセル ${stringAddress} には１文字以上を指定してください。`
    );
  }

  return v;
}

/**
 *
 * @param sheet
 * @param address
 * @returns
 */
export function parseNullableTextCell(
  sheet: SheetWithName,
  address: CellAddress
) {
  const cell = sheet.object[stringCellAddress(address)];

  if (!cell) {
    return null;
  }

  const v = String(cell.v);
  return v ? v : null;
}

/**
 *
 * @param sheet
 * @param address
 * @returns
 */
export function parseRequiredNumberCell(
  sheet: SheetWithName,
  address: CellAddress
): number {
  const stringAddress = stringCellAddress(address);
  const cell = sheet.object[stringAddress];

  if (!cell) {
    throw new Error(
      `シート ${sheet.name} のセル ${stringAddress} には数値を指定してください。`
    );
  }

  if (typeof cell.v === "number") {
    return cell.v;
  }

  try {
    return parseFloatOrThrewError(String(cell.v));
  } catch (err) {
    throw new Error(
      `シート ${sheet.name} のセル ${stringAddress} には数値を指定してください。`
    );
  }
}

export function parseNullableNumberCell(
  sheet: SheetWithName,
  address: CellAddress
) {
  const stringAddress = stringCellAddress(address);
  const cell = sheet.object[stringAddress];

  if (!cell) {
    return null;
  }

  if (typeof cell.v === "number") {
    return cell.v;
  }

  try {
    const v = String(cell.v);
    if (!v) {
      return null;
    }
    return parseFloatOrThrewError(v);
  } catch (err) {
    throw new Error(
      `シート ${sheet.name} のセル ${stringAddress} には数値を指定してください。`
    );
  }
}

export function parseFlagCell(sheet: SheetWithName, address: CellAddress) {
  const v = parseRequiredNumberCell(sheet, address);

  if (v !== 0 && v !== 1) {
    const stringAddress = stringCellAddress(address);
    throw new Error(
      `シート ${sheet.name} のセル ${stringAddress} には 0 または 1 を指定してください。`
    );
  }

  return v;
}

/**
 * parseFloatを呼び出し、結果がNaNもしくはInfinityの場合は例外発生する。
 *
 * @param value
 * @returns
 */
function parseFloatOrThrewError(value: string) {
  const a = parseFloat(String(value));

  if (!Number.isFinite(a)) {
    throw new Error("parseFloat error");
  }

  return a;
}
