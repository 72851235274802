import React, { MouseEvent, useCallback } from "react";
import { fetchUser, useTenantNavigator } from "./Account-store";
import { currentUser, validateUser } from "./UsersEdit-store";
import { signOut } from "./cognito";
import { notifyError, notifyInfo } from "./components/NotificationOverlay";
import { setProgressTask } from "./components/ProgressOverlay";
import { fetchJsonApi } from "./fetcher";
import { isPhantomId } from "./utils/phantom-id";

export function UsersEditOperations() {
  const tenantNavigator = useTenantNavigator();

  const saveClickListener = useCallback(
    (event: MouseEvent) => {
      event.preventDefault();

      const errors = validateUser(currentUser.value);

      if (errors.length > 0) {
        notifyError({
          message: "ユーザーの保存ができません。",
          listItems: errors,
        });

        return;
      }

      const task = fetchJsonApi({
        type: "postUser",
        item: currentUser.value,
      })
        .then(() => {
          //アカウント管理のfetchUserを呼んで自身のユーザIDとメールアドレスを取得
          const { userId, email } = fetchUser().value;

          // ユーザ管理で自身のメールアドレスを変更した場合は、認可エラーを防ぐためにログアウトする。
          if (
            userId === currentUser.value.userId &&
            email !== currentUser.value.email
          ) {
            confirm(
              "ユーザーの保存が完了しました。自身のメールアドレスが変更されたためログアウトします。"
            );

            signOut();
            document.location.pathname = "/";
          } else {
            notifyInfo({
              message: "ユーザーの保存が完了しました。",
            });
            tenantNavigator(`/users`);
          }
        })
        .catch((err) => {
          notifyError({
            message: "ユーザーの保存が失敗しました。",
            err,
          });
        });

      setProgressTask(task);
    },
    [tenantNavigator]
  );

  const discardClickListener = useCallback(
    (event: MouseEvent) => {
      event.preventDefault();

      if (confirm("変更を保存せずに破棄しますか？")) {
        tenantNavigator(`/users`);
      }
    },
    [tenantNavigator]
  );

  const deleteClickListener = useCallback(
    (event: MouseEvent) => {
      event.preventDefault();

      if (!confirm("ユーザーを削除しますか？")) {
        return;
      }

      fetchJsonApi({
        type: "deleteUser",
        userId: currentUser.value.userId,
      })
        .then(() => {
          notifyInfo({
            message: "ユーザーの削除が完了しました。",
          });

          tenantNavigator(`/users`);
        })
        .catch((err) => {
          notifyError({
            message: "ユーザーの削除に失敗しました。",
            err,
          });
        });
    },
    [tenantNavigator]
  );

  return (
    <ul className="operation">
      <li className="discard">
        <a onClick={saveClickListener}>保存</a>
      </li>
      {!isPhantomId(currentUser.value.userId) && (
        <li className="discard">
          <a onClick={deleteClickListener}>削除</a>
        </li>
      )}
      <li className="discard">
        <a onClick={discardClickListener}>変更を破棄</a>
      </li>
    </ul>
  );
}
