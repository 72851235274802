import React, { ChangeEvent, useCallback, useLayoutEffect } from "react";
import { QRCode } from "./common";
import { Input } from "./components/Input";
import $ from "jquery";

/**
 * QRコード編集画面 - 種別。
 */
export function QREditKind(props: { qrCode: QRCode }) {
  const { qrCode } = props;

  const changeListener = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.value === "general") {
      $("div.groups").hide();
    } else {
      $("div.groups").show();
    }
  }, []);

  useLayoutEffect(() => {
    if (qrCode.kind === "general") {
      $("div.groups").hide();
    }
  }, [qrCode]);

  return (
    <>
      <dt className="must">種別</dt>
      <dd>
        <Input
          type="radio"
          name="種別"
          id="type"
          value="general"
          obj={qrCode}
          propName="kind"
          onChange={changeListener}
        />
        <label htmlFor="type">一般客</label>
        &nbsp;
        <Input
          type="radio"
          name="種別"
          id="type2"
          value="group"
          obj={qrCode}
          propName="kind"
          onChange={changeListener}
        />
        <label htmlFor="type2">団体客</label>
      </dd>

      <div className="groups">
        <dt className="must">団体客名</dt>
        <dd>
          <Input
            type="text"
            className="full"
            obj={qrCode}
            propName="groupName"
          />
        </dd>
      </div>
    </>
  );
}
