import React, { MouseEvent, useCallback } from "react";
import { useTenantNavigator } from "./Account-store";
import {
  currentOrganization,
  validateOrganization,
} from "./AdminOrganizationsEdit-store";
import { notifyError, notifyInfo } from "./components/NotificationOverlay";
import { setProgressTask } from "./components/ProgressOverlay";
import { fetchJsonApi } from "./fetcher";
import { isPhantomId } from "./utils/phantom-id";

export function AdminOrganizationsEditOperations() {
  const tenantNavigator = useTenantNavigator();

  const saveClickListener = useCallback(
    (event: MouseEvent) => {
      event.preventDefault();

      const errors = validateOrganization(currentOrganization.value);

      if (errors.length > 0) {
        notifyError({
          message: "組織の保存ができません。",
          listItems: errors,
        });

        return;
      }

      const task = fetchJsonApi({
        type: "adminPostOrganization",
        item: currentOrganization.value,
      })
        .then(() => {
          notifyInfo({
            message: "組織の保存が完了しました。",
          });
          tenantNavigator("/admin/organizations");
        })
        .catch((err) => {
          notifyError({
            message: "組織の保存が失敗しました。",
            err,
          });
        });

      setProgressTask(task);
    },
    [tenantNavigator]
  );

  const discardClickListener = useCallback(
    (event: MouseEvent) => {
      event.preventDefault();

      if (confirm("変更を保存せずに破棄しますか？")) {
        tenantNavigator("/admin/organizations");
      }
    },
    [tenantNavigator]
  );

  const deleteClickListener = useCallback(
    (event: MouseEvent) => {
      event.preventDefault();

      if (!confirm("組織を削除しますか？")) {
        return;
      }

      fetchJsonApi({
        type: "adminDeleteOrganization",
        organizationId: currentOrganization.value.organizationId,
      })
        .then(() => {
          notifyInfo({
            message: "組織の削除が完了しました。",
          });

          tenantNavigator("/admin/organizations");
        })
        .catch((err) => {
          notifyError({
            message: "施設の削除に失敗しました。",
            err,
          });
        });
    },
    [tenantNavigator]
  );

  const deleteQRHistoryClickListener = useCallback(
    (event: MouseEvent) => {
      event.preventDefault();

      if (!confirm("QRコードのダウンロード履歴を全て削除しますか？")) {
        return;
      }

      fetchJsonApi({
        type: "adminDeleteQRCodeDevices",
        organizationId: currentOrganization.value.organizationId,
      })
        .then(() => {
          notifyInfo({
            message: "QRコードのダウンロード履歴の削除が完了しました。",
          });
        })
        .catch((err) => {
          notifyError({
            message: "QRコードのダウンロード履歴の削除に失敗しました。",
            err,
          });
        });
    },
    [tenantNavigator]
  );

  return (
    <ul className="operation">
      <li className="discard">
        <a onClick={saveClickListener}>保存</a>
      </li>
      {!isPhantomId(currentOrganization.value.organizationId) && (
        <li className="discard">
          <a onClick={deleteClickListener}>削除</a>
        </li>
      )}
      <li className="discard">
        <a onClick={discardClickListener}>変更を破棄</a>
      </li>
      <li className="discard">
        <a onClick={deleteQRHistoryClickListener}>QR履歴削除</a>
      </li>
    </ul>
  );
}
