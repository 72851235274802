import React from "react";
import { GuideVersion } from "./common";
import { Input } from "./components/Input";

/**
 * 音声ガイド編集画面 - 基本設定 - 再生方法。
 */
export function GuidesEditBasicPlayMethods(props: { guide: GuideVersion }) {
  const { guide } = props;

  return (
    <>
      <dt className="must">再生方法</dt>
      <dd>
        <Input
          type="checkbox"
          name="再生方法"
          id="play_method1"
          value="番号入力"
          obj={guide}
          propName="playOnInputNumber"
        />
        <label htmlFor="play_method1">番号入力</label>

        <Input
          type="checkbox"
          name="再生方法"
          id="play_method2"
          value="GPS"
          obj={guide}
          propName="playOnGps"
        />
        <label htmlFor="play_method2">GPS</label>

        <Input
          type="checkbox"
          name="再生方法"
          id="play_method3"
          value="ビーコン"
          obj={guide}
          propName="playOnBeacon"
        />
        <label htmlFor="play_method3">ビーコン</label>
      </dd>
    </>
  );
}
