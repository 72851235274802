import React from "react";
import { Link, Route, Switch, useParams } from "react-router-dom";
import { AccountNav } from "./AccountNav";
import { AdminNav } from "./AdminNav";
import { GuidesNav } from "./GuidesNav";
import { QRNav } from "./QRNav";
import { ReportsNav } from "./ReportsNav";
import { SiteNav } from "./SiteNav";
import { UsersNav } from "./UsersNav";
import { isRekihaku } from "./utils/util";

/**
 * サイトのヘッダー。
 *
 * ロゴやナビゲーターを表示する。
 */
export function SiteHeader() {
  const { tenantId } = useParams<{ tenantId: string }>();

  return (
    <header>
      <div id="header">
        <h1 className={isRekihaku() ? "rekihaku-logo" : "logo"}>
          <Link to="/">Global Guide</Link>
        </h1>
      </div>
      <SiteNav />
      <Switch>
        <Route path={`/${tenantId}/account`} component={AccountNav} />
        <Route path={`/${tenantId}/admin`} component={AdminNav} />
        <Route path={`/${tenantId}/guides`} component={GuidesNav} />
        <Route path={`/${tenantId}/qr`} component={QRNav} />
        <Route path={`/${tenantId}/reports`} component={ReportsNav} />
        <Route path={`/${tenantId}/users`} component={UsersNav} />
      </Switch>
    </header>
  );
}
