import {
  ChangeEvent,
  MouseEvent,
  RefObject,
  useCallback,
  useEffect,
} from "react";
import {
  Content,
  ContentByLanguage,
  GuideVersion,
  Organization,
  QRCode,
} from "../common";
import { fetchJsonApi } from "../fetcher";
import { extensions } from "mime-types";
import { setMediaRefFile } from "../utils/react-util";
import { setProgressTask } from "../components/ProgressOverlay";
import { notifyError } from "../components/NotificationOverlay";
import { setSourceOfMedia } from "../utils/dom-util";
import { useRefresh } from "./useRefresh";

export const ACCEPT_IMAGE = ["png", "jpg", "gif"] as const;
export const ACCEPT_MOVIE = ["mp4"] as const;
export const ACCEPT_AUDIO = ["mp3"] as const;

export interface UseUploaderProps<
  T extends GuideVersion | Content | ContentByLanguage | QRCode | Organization
> {
  /**
   * 音声ガイドフォルダーからダウンロードする場合の音声ガイドID。
   */
  guideId?: number;

  /**
   * QRコードフォルダーからダウンロードする場合のQRコードID。
   */
  qrCodeId?: number;

  /**
   * 「コンテンツデフォルト画像」のダウンロード指定。
   */
  contentDefaultImage?: true;

  /**
   * 「コンテンツデフォルト画像」のURL。
   */
  defaultImageUrl?: string;

  /**
   * 「トップデフォルト画像」のダウンロード指定。
   */
  topDefaultImage?: true;

  /**
   * 「トップデフォルト画像」のURL。
   */
  topDefaultImageUrl?: string;

  /**
   * アップロードしたファイルの情報を持つオブジェクト。
   */
  obj: T;

  /**
   * obj に対してどのプロパティがファイル名を持つか。
   */
  propName: keyof T;

  /**
   * input 要素の accept 属性で指定するアップロードを許可するファイル拡張子の配列。
   * 拡張子の先頭のドットは不要。
   */
  accept: typeof ACCEPT_IMAGE | typeof ACCEPT_MOVIE | typeof ACCEPT_AUDIO;

  /**
   * サムネール表示したい img 要素への参照。
   */
  imageRef?: RefObject<HTMLImageElement>;

  /**
   * サムネール表示したい audio 要素、または video 要素への参照。
   */
  mediaRef?: RefObject<HTMLMediaElement>;
}

/**
 * ファイルアップロード処理をまとめた Hook.
 *
 * ファイルアップロードに成功すると、指定した GuideVerion または QRCode の
 * 指定したプロパティにファイル名を記録する。
 *
 * プレビュー表示の img 要素への RefObject が指定してある場合、
 * アップロード後にサムネールをセットする。
 *
 * @param props
 * @returns input[type="file"] 要素に設定する onChange と accept を持つオブジェクトを返す。
 */
export function useUploader<
  T extends GuideVersion | Content | ContentByLanguage | QRCode | Organization
>(props: UseUploaderProps<T>) {
  const {
    guideId,
    qrCodeId,
    contentDefaultImage,
    defaultImageUrl,
    topDefaultImage,
    topDefaultImageUrl,
    obj,
    propName,
    accept,
    imageRef,
    mediaRef,
  } = props;

  const refresh = useRefresh();

  const changeListener = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const file = event.target.files?.item(0);

      if (!file) {
        return;
      }

      if (imageRef) {
        setMediaRefFile(imageRef, file);
      }

      if (mediaRef) {
        setMediaRefFile(mediaRef, file);
      }

      //
      // アップロードするファイルの拡張子を求める。
      //
      const extensionCandidates = extensions[file.type];
      if (!extensionCandidates) {
        alert("サポートされていない種類のファイルです。");
        return;
      }

      const ext =
        extensionCandidates.find((ec) =>
          (accept as unknown as string[]).includes(ec)
        ) ?? extensionCandidates[0];

      //
      // アップロード用署名済み URL 取得。
      //
      const task = fetchJsonApi({
        type: "getSignedUrl",
        operation: "putObject",
        ext,
      })
        .then(async (res) => {
          await fetch(res.url, {
            method: "PUT",
            mode: "cors",
            headers: {
              "content-type": file.type,
            },
            body: file,
          });
          return res;
        })
        .then((res) => {
          (obj[propName] as any) = res.uuid + "." + ext;

          if (imageRef?.current) {
            delete imageRef.current.dataset.noimage;
          }

          refresh();
        })
        .catch((err) => {
          notifyError({
            message: "アップロードに失敗しました。",
            err,
          });
        });

      setProgressTask(task);
    },
    [obj, propName, accept, imageRef, mediaRef]
  );

  const deleteListener = useCallback(
    (event: MouseEvent) => {
      event.preventDefault();

      if (!obj[propName]) {
        return;
      }

      if (!confirm("ファイルを削除してよろしいですか？")) {
        return;
      }

      (obj[propName] as any) = null;

      if (imageRef?.current) {
        // src = ""とするとリンク切れ画像にように表示されるため、代わりにデータURLスキーマによる空白画像を表示する。
        imageRef.current.src =
          "data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==";
        imageRef.current.dataset.noimage = "true";
      }

      if (mediaRef?.current) {
        mediaRef.current.querySelector<HTMLSourceElement>("source")?.remove();
        mediaRef.current.load();
      }

      refresh();
    },
    [obj, propName, imageRef, mediaRef]
  );

  useEffect(() => {
    //
    // デフォルト画像設定。
    //
    if (!obj[propName]) {
      if (imageRef?.current) {
        if (topDefaultImageUrl) {
          imageRef.current.src = topDefaultImageUrl;
        } else if (defaultImageUrl) {
          imageRef.current.src = defaultImageUrl;
        } else {
          // デフォルト画像の指定がない場合は extra.css で "No Photo" の画像を設定。
          imageRef.current.dataset.noimage = "true";
        }
      }

      return;
    }

    //
    // ダウンロード用署名済み URL 取得。
    //
    const [uuid, ext] = (obj[propName] as unknown as string).split(".");

    const controller = new AbortController();

    fetchJsonApi(
      {
        type: "getSignedUrl",
        operation: "getObject",
        guideId,
        qrCodeId,
        contentDefaultImage,
        topDefaultImage,
        uuid,
        ext,
      },
      { signal: controller.signal }
    ).then((res) => {
      if (imageRef?.current) {
        imageRef.current.src = res.url;
      }

      if (mediaRef?.current) {
        setSourceOfMedia(mediaRef.current, res.url);
      }
    });

    return () => controller.abort();
  }, [
    guideId,
    qrCodeId,
    contentDefaultImage,
    defaultImageUrl,
    topDefaultImage,
    topDefaultImageUrl,
    obj,
    propName,
    imageRef,
    mediaRef,
  ]);

  return {
    deleteVisible: !!obj[propName],
    accept: accept.map((ext) => `.${ext}`).join(","),
    onChange: changeListener,
    onDelete: deleteListener,
  };
}
