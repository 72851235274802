import React from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import { fetchOrganization, fetchUser } from "./Account-store";
import { AccountEdit } from "./AccountEdit";
import { SiteFooter } from "./SiteFooter";
import { SiteHeader } from "./SiteHeader";

/**
 * アカウント画面。
 */
export function Account() {
  const { path } = useRouteMatch();

  return (
    <>
      <SiteHeader />
      <div className="contentsWrapper">
        <div className="contents">
          <Switch>
            <Route
              path={`${path}`}
              render={() => {
                fetchOrganization(true);
                fetchUser(true);

                return <AccountEdit />;
              }}
            />
          </Switch>
        </div>
      </div>
      <SiteFooter />
    </>
  );
}
