import { useHistory } from "react-router-dom";
import { Suspensive } from "react-suspensive";
import { Organization, User } from "./common";
import { fetchJsonApi } from "./fetcher";

let organization: Suspensive<Organization>;
let user: Suspensive<User>;
let contentDefaultImageUrl: Suspensive<string | undefined> | undefined;

export function fetchOrganization(reload: boolean = false) {
  if (organization && !reload) {
    return organization;
  }

  const promise = fetchJsonApi({
    type: "getOrganization",
  }).then((res) => {
    if (!res.item) {
      throw new Error(`所属組織の情報が見つかりませんでした。`);
    }

    if (res.item.privileged !== 0) {
      // 特権組織の場合、背景色を変更する。
      document
        .querySelectorAll<HTMLElement>(":root, body")
        .forEach((el) => (el.style.backgroundColor = "#fff8dc"));
    }

    return res.item;
  });

  if (organization) {
    organization.value = promise;
  } else {
    organization = new Suspensive(promise);

    // for debug
    (window as any).$organization = organization;
  }

  return organization;
}

export function fetchUser(reload: boolean = false) {
  if (user && !reload) {
    return user;
  }

  const promise = fetchJsonApi({
    type: "getMe",
  }).then((res) => {
    if (!res.item) {
      throw new Error(`ユーザーの情報が見つかりませんでした。`);
    }

    return res.item;
  });

  if (user) {
    user.value = promise;
  } else {
    user = new Suspensive(promise);

    // for debug
    (window as any).$user = user;
  }

  return user;
}

export function fetchContentDefaultImageUrl(reload: boolean = false) {
  if (contentDefaultImageUrl && !reload) {
    return contentDefaultImageUrl;
  }

  let promise: Promise<string | undefined>;

  const contentDefaultImage = organization?.value.contentDefaultImage;

  if (!contentDefaultImage) {
    promise = Promise.resolve(undefined);
  } else {
    const [uuid, ext] = contentDefaultImage.split(".");

    promise = fetchJsonApi({
      type: "getSignedUrl",
      operation: "getObject",
      uuid,
      ext,
    }).then((res) => {
      if (!res.url) {
        throw new Error(`コンテンツデフォルト画像のURLが取得できません。`);
      }

      // 署名済み URL の期限切れ前に無効化する。
      setTimeout(() => {
        contentDefaultImageUrl = undefined;
      }, 3 * 3600 * 1000);

      return res.url;
    });
  }

  if (contentDefaultImageUrl) {
    contentDefaultImageUrl.value = promise;
  } else {
    contentDefaultImageUrl = new Suspensive(promise);

    // for debug
    (window as any).$contentDefaultImageUrl = contentDefaultImageUrl;
  }

  return contentDefaultImageUrl;
}

export let accountPreviousEmail = "";

export function preserveCurrentEmail() {
  if (!user) {
    throw new Error("アプリケーションエラーが発生しました。");
  }

  return fetchJsonApi({
    type: "getMe",
  }).then((res) => {
    if (!res.item) {
      throw new Error(`ユーザーの情報が見つかりませんでした。`);
    }

    accountPreviousEmail = res.item.email;
  });
}

/**
 * アカウント情報を保存する。
 */
export function saveAccount() {
  const organizationTask = fetchJsonApi({
    type: "postOrganization",
    item: organization.value,
  }).then((res) => {
    // コンテンツデフォルト画像変更に備えてキャッシュを無効化。
    contentDefaultImageUrl = undefined;

    return res;
  });

  const userTask = fetchJsonApi({
    type: "postMe",
    item: user.value,
  });

  return Promise.all([organizationTask, userTask]);
}

/**
 * 編集中のアカウント情報の検証。
 */
export function validateAccount() {
  const o = organization.value;
  const u = user.value;

  const errors: string[] = [];

  if (!o.name_ja) {
    errors.push("「施設名」を入力してください。");
  }

  if (o.postalCode?.length !== 7) {
    errors.push("「郵便番号」は７桁入力してください。");
  }

  if (!o.prefecture) {
    errors.push("「都道府県」を選択してください。");
  }

  if (!o.address1) {
    errors.push("「住所1」を入力してください。");
  }

  if (!o.tel) {
    errors.push("「電話番号」を入力してください。");
  }

  if (!u.name) {
    errors.push("「担当者名」を入力してください。");
  }

  if (!u.name_kana) {
    errors.push("「担当者名（フリガナ）」を入力してください。");
  }

  if (!u.email) {
    errors.push("「メールアドレス」を入力してください。");
  }

  return errors;
}

/**
 * サインインしているテナントに基づいたナビゲーションを行う関数を返す Hook。
 */
export function useTenantNavigator() {
  const history = useHistory();

  return (path: string) => {
    const { tenantId } = organization.value;

    if (!tenantId) {
      return;
    }

    history.push(`/${tenantId}${path}`);
  };
}

/**
 * 印刷ページを別ウィンドウで開く。
 *
 * @param path
 * @returns
 */
export function openPrintPage(path: string) {
  const { tenantId } = organization.value;

  return window.open(`/${tenantId}/print${path}`);
}

/**
 * ログイン中のユーザーが一般ユーザーかどうかを返す。
 */
export function isGeneralUser() {
  const user = fetchUser();
  return user.value.privilegedKind === "general";
}

/**
 * ログイン中のユーザーの所属組織が特権を持っているかどうかを返す。
 */
export function isPrivilegedOrganization() {
  const organization = fetchOrganization();
  return organization.value.privileged !== 0;
}
