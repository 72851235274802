import React from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import { QREdit } from "./QREdit";
import { QRList } from "./QRList";
import { SiteFooter } from "./SiteFooter";
import { SiteHeader } from "./SiteHeader";

/**
 * QRコード画面。
 *
 * QRコード管理系の各画面にルーティングする。
 */
export function QR() {
  const { path } = useRouteMatch();

  return (
    <>
      <SiteHeader />
      <div className="contentsWrapper">
        <div className="contents">
          <Switch>
            <Route path={path} exact component={QRList} />
            <Route
              path={`${path}/:qrCodeId`}
              render={(props) => <QREdit key={props.match.params.qrCodeId} />}
            />
          </Switch>
        </div>
      </div>
      <SiteFooter />
    </>
  );
}
