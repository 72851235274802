import { getSignedInUser, restoreSession } from "./cognito";
import type { Request, GetResponseType } from "./common";

export interface FetchJsonApiOptions {
  signal?: AbortSignal;
}

/**
 * API 呼び出し。
 *
 * @param req
 * @returns
 */
export async function fetchJsonApi<T extends Request>(
  req: T,
  options: FetchJsonApiOptions = {}
): Promise<GetResponseType<T>> {
  const res = await fetchJsonApiAsResponse(req, options);

  return res.json();
}

/**
 * ファイルダウンロード
 *
 * @param req
 * @returns
 */
export async function fetchJsonApiAsBlob<T extends Request>(
  req: T,
  options: FetchJsonApiOptions = {}
): Promise<Blob> {
  const res = await fetchJsonApiAsResponse(req, options);

  return res.blob();
}

/**
 * API 呼び出し。
 */
export async function fetchJsonApiAsResponse(
  req: Request,
  options: FetchJsonApiOptions = {}
) {
  const url = `/admin`;

  const headers: HeadersInit = {
    "content-type": "application/json",
  };

  const idToken = await getIdToken();
  if (idToken) {
    headers["authorization"] = `Bearer ${idToken}`;
  }

  const res = await fetch(url, {
    method: "POST",
    headers,
    mode: "cors",
    credentials: "include",
    body: JSON.stringify(req),
    signal: options.signal,
  });

  if (!res.ok) {
    throw await res.json().catch((_err) =>
      res
        .text()
        .then((text) => new Error(`${res.status} ${res.statusText} ${text}`))
        .catch((_) => new Error(`${res.status} ${res.statusText}`))
    );
  }

  return res;
}

async function getIdToken() {
  const user = getSignedInUser();
  let session = user.getSignInUserSession();

  if (!session) {
    return undefined;
  }

  if (!session.isValid()) {
    session = await restoreSession();
  }

  return session.getIdToken().getJwtToken();
}
