import React, { ChangeEvent, useCallback, useEffect, useState } from "react";
import { GetReportResponse } from "./common/GetReportResponse";
import { notifyError } from "./components/NotificationOverlay";
import { fetchJsonApi } from "./fetcher";
import { SiteFooter } from "./SiteFooter";
import { SiteHeader } from "./SiteHeader";
import { parse } from "papaparse";

interface QueryForm {
  duration: "currentMonth" | "prevMonth" | "custom";
  customStartYear: string;
  customStartMonth: string;
}

/**
 * 再生データのレポート画面。
 *
 * 現在は歴博専用。
 *
 * @returns
 */
export function ReportsPlayData() {
  const [queryState, setQueryState] = useState<QueryForm>(() => ({
    duration: "currentMonth",
    customStartYear: `${new Date().getFullYear()}`,
    customStartMonth: `${new Date().getMonth()}`,
  }));

  const [results, setResults] = useState<
    GetReportResponse | null | "not_found"
  >(null);

  const onChange = useCallback(
    (event: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
      const nextState = {
        ...queryState,
        [event.target.name]: event.target.value,
      };

      setQueryState(nextState);

      const { startDate } = getStartDate(nextState);
      fetchAndSetResults(startDate);
    },
    [queryState]
  );

  const downloadRawReport = useCallback(() => {
    const { startDate } = getStartDate(queryState);

    fetchJsonApi({
      type: "getSignedUrl",
      operation: "getObject",
      reportType: "raw",
      year: startDate.getFullYear(),
      month: startDate.getMonth() + 1,
    })
      .then((res) => {
        const anchor = document.createElement("a");
        anchor.download = "再生データ.csv";
        anchor.href = res.url;
        anchor.click();
      })
      .catch((err) => {
        notifyError({ message: "レポートの取得に失敗しました。", err });
      });
  }, [queryState]);

  useEffect(() => {
    const { startDate } = getStartDate(queryState);
    fetchAndSetResults(startDate);
  }, [queryState]);

  /*
   * 指定された月のサマリーを取得して results に反映。
   */
  function fetchAndSetResults(startDate: Date) {
    fetchJsonApi({
      type: "getSignedUrl",
      operation: "getObject",
      reportType: "daily",
      year: startDate.getFullYear(),
      month: startDate.getMonth() + 1,
    })
      .then(async (res) => {
        const res2 = await fetch(res.url, {
          mode: "cors",
        });

        if (!res2.ok) {
          if (res2.status === 404) {
            setResults("not_found");
            return;
          } else {
            throw new Error(`Status: ${res2.status}`);
          }
        }

        const csv = await res2.text();
        setResults(makeReportFromCSV(csv));
      })
      .catch((err) => {
        notifyError({ message: "レポートの取得に失敗しました。", err });
      });
  }

  return (
    <>
      <SiteHeader />

      <div className="contentsWrapper">
        <div className="contents">
          <div className="contentsInfoWrapper">
            <div className="contentsBox qr">
              <dl className="contentsInfo">
                <dt>期間</dt>
                <dd>
                  <input
                    type="radio"
                    name="duration"
                    id="type1"
                    value="prevMonth"
                    checked={queryState.duration === "prevMonth"}
                    onChange={onChange}
                  />
                  <label htmlFor="type1">先月</label>
                  <input
                    type="radio"
                    name="duration"
                    id="type2"
                    value="currentMonth"
                    checked={queryState.duration === "currentMonth"}
                    onChange={onChange}
                  />
                  <label htmlFor="type2">当月</label>
                  <input
                    type="radio"
                    name="duration"
                    id="type3"
                    value="custom"
                    checked={queryState.duration === "custom"}
                    onChange={onChange}
                  />
                  <label htmlFor="type3">年月指定</label>

                  {queryState.duration === "custom" && (
                    <div className="reportDuration">
                      <select
                        name="customStartYear"
                        id=""
                        value={queryState.customStartYear}
                        onChange={onChange}
                      >
                        {getYears(3).map((y) => (
                          <option value={y} key={y}>
                            {y}年
                          </option>
                        ))}
                      </select>
                      <span>年</span>
                      <select
                        name="customStartMonth"
                        id=""
                        value={queryState.customStartMonth}
                        onChange={onChange}
                      >
                        <option value="0">1月</option>
                        <option value="1">2月</option>
                        <option value="2">3月</option>
                        <option value="3">4月</option>
                        <option value="4">5月</option>
                        <option value="5">6月</option>
                        <option value="6">7月</option>
                        <option value="7">8月</option>
                        <option value="8">9月</option>
                        <option value="9">10月</option>
                        <option value="10">11月</option>
                        <option value="11">12月</option>
                      </select>
                      <span>月</span>
                    </div>
                  )}
                </dd>
              </dl>
            </div>
          </div>

          {results === null ? (
            false
          ) : results === "not_found" ? (
            <div>指定された期間のレポートがありません。</div>
          ) : (
            <>
              <table className="report">
                <thead>
                  <tr>
                    {results.headers.map((h, i) => (
                      <th key={i}>{h}</th>
                    ))}
                  </tr>
                </thead>

                <tfoot>
                  <tr>
                    {results.footers.map((f, i) => (
                      <th key={i}>{f}</th>
                    ))}
                  </tr>
                </tfoot>

                <tbody>
                  {results.data.map((columns, i) => (
                    <tr key={i}>
                      {columns.map((col, j) => (
                        <td key={j}>{col}</td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>

              <p className="addContents">
                <a href="javascript:void(0)" onClick={downloadRawReport}>
                  詳細データダウンロード
                </a>
              </p>
            </>
          )}
        </div>
      </div>

      <SiteFooter />
    </>
  );
}

function getStartDate(form: QueryForm) {
  const startDate = new Date();
  startDate.setDate(1);
  startDate.setHours(0);
  startDate.setMinutes(0);
  startDate.setSeconds(0);
  startDate.setMilliseconds(0);

  if (form.duration === "currentMonth") {
    // 今月
  } else if (form.duration === "prevMonth") {
    // 先月
    startDate.setMonth(startDate.getMonth() - 1);
  } else {
    // 期間指定
    startDate.setFullYear(parseInt(form.customStartYear, 10));
    startDate.setMonth(parseInt(form.customStartMonth, 10));
  }

  return { startDate };
}

function getYears(count: number): number[] {
  const d = new Date();
  const years = [] as number[];
  for (let i = 0; i < count; i++) {
    years.push(d.getFullYear());
    d.setFullYear(d.getFullYear() - 1);
  }

  return years;
}

function makeReportFromCSV(csv: string): GetReportResponse | "not_found" {
  const csvResult = parse<{
    date: string;
    guide_id: string;
    guide_number: string;
    guide_language_name: string;
    count: string;
  }>(csv, {
    header: true,
    skipEmptyLines: true,
  });

  console.log({ csvResult });

  if (csvResult.data.length === 0) {
    return "not_found";
  }

  if (csvResult.errors.length) {
    const [err] = csvResult.errors;
    throw new Error(`CSV エラー 行${err.row} ${err.message}`);
  }

  // 日付別／言語別のマップ、言語一覧、日付別合計、言語別合計の作成。
  const mapByDateAndLang: { [date: string]: { [lang: string]: number } } = {};
  const languageSet = new Set<string>();
  const totalByDate: { [date: string]: number } = {};
  const totalByLang: { [lang: string]: number } = {};

  csvResult.data.forEach(({ date, guide_language_name, count }) => {
    const value = parseInt(count, 10);

    // 日付別／言語別のカウント。
    const { [date]: mapByLang = (mapByDateAndLang[date] = {}) } =
      mapByDateAndLang;
    mapByLang[guide_language_name] =
      (mapByLang[guide_language_name] ?? 0) + value;

    // 日付別合計カウント。
    totalByDate[date] = (totalByDate[date] ?? 0) + value;

    // 言語別合計カウント。
    totalByLang[guide_language_name] =
      (totalByLang[guide_language_name] ?? 0) + value;

    // 言語一覧追加。
    languageSet.add(guide_language_name);
  });

  // ヘッダー作成。
  const headers: string[] = ["日付", ...languageSet, "合計"];

  // 日別データ作成。
  const data: string[][] = Object.keys(mapByDateAndLang)
    .sort()
    .map((date) => {
      const result = [date];
      const mapByLang = mapByDateAndLang[date];

      languageSet.forEach((lang) => {
        result.push((mapByLang[lang] ?? 0).toString());
      });

      result.push(totalByDate[date].toString());

      return result;
    });

  // フッター作成。
  const footers: string[] = ["合計"];
  let total = 0;

  languageSet.forEach((lang) => {
    const count = totalByLang[lang] ?? 0;

    footers.push(count.toString());
    total += count;
  });

  footers.push(total.toString());

  return {
    headers,
    footers,
    data,
  };
}
