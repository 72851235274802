export interface GetInputValuesOptions<T> {
  ids: (keyof T)[];
}

/**
 * 指定された条件で input 要素を見つけて、その値を取得する。
 *
 * @param options
 */
export function getInputValues<T>(options: GetInputValuesOptions<T>): T {
  const result: any = {};

  for (const id of options.ids) {
    const inputEl = document.getElementById(id as string);

    if (!inputEl) {
      throw new Error(`The element of the id ${id} not found`);
    }

    if (!(inputEl instanceof HTMLInputElement)) {
      throw new Error(`The element of the id ${id} is not the input elementt`);
    }

    result[id] = inputEl.value;
  }

  return result;
}

export function setSourceOfMedia(media: HTMLMediaElement, src: string) {
  const source = media.querySelector<HTMLSourceElement>("source");

  if (!source) {
    const source = document.createElement("source");
    source.src = src;
    media.appendChild(source);
  } else {
    source.src = src;
  }

  media.load();
}
