import React, { ChangeEvent, useReducer } from "react";
import { Content, GuideVersion } from "./common";
import { Input } from "./components/Input";

/**
 * 音声ガイド編集画面 - コンテンツ設定 - GPS情報。
 */
export function GuidesEditContentsGps(props: {
  content: Content;
  guide: GuideVersion;
}) {
  const { content } = props;

  const [playLimitEnabled, setPlayLimitEnabled] = useReducer(
    (_: boolean, event: ChangeEvent<HTMLInputElement>) => {
      const { checked } = event.target;

      if (!checked) {
        content.gpsPlayLimit = null;
      } else {
        content.gpsPlayLimit = 0;
      }

      return checked;
    },
    content,
    (content) => content.gpsPlayLimit !== null
  );

  return (
    <>
      <h2 className="must">GPS情報</h2>
      <div className="indent">
        <label htmlFor="lat">緯度</label>
        <Input
          id="lat"
          type="text"
          name="緯度"
          obj={content}
          propName="gpsLatitude"
        />
        &nbsp;
        <label htmlFor="lng">経度</label>
        <Input
          id="lng"
          type="text"
          name="経度"
          obj={content}
          propName="gpsLongitude"
        />
        &nbsp;
        <label htmlFor="area">半径</label>
        <Input
          id="area"
          type="text"
          name="半径"
          obj={content}
          propName="gpsRadius"
        />
        <h3>予備ポイント1</h3>
        <label htmlFor="lat1">緯度</label>
        <Input
          id="lat1"
          type="text"
          name="緯度"
          obj={content}
          propName="gpsLatitude1"
        />
        &nbsp;
        <label htmlFor="lng1">経度</label>
        <Input
          id="lng1"
          type="text"
          name="経度"
          obj={content}
          propName="gpsLongitude1"
        />
        &nbsp;
        <label htmlFor="area1">半径</label>
        <Input
          id="area1"
          type="text"
          name="半径"
          obj={content}
          propName="gpsRadius1"
        />
        <h3>予備ポイント2</h3>
        <label htmlFor="lat2">緯度</label>
        <Input
          id="lat2"
          type="text"
          name="緯度"
          obj={content}
          propName="gpsLatitude2"
        />
        &nbsp;
        <label htmlFor="lng2">経度</label>
        <Input
          id="lng2"
          type="text"
          name="経度"
          obj={content}
          propName="gpsLongitude2"
        />
        &nbsp;
        <label htmlFor="area2">半径</label>
        <Input
          id="area2"
          type="text"
          name="半径"
          obj={content}
          propName="gpsRadius2"
        />
        <h3>予備ポイント3</h3>
        <label htmlFor="lat3">緯度</label>
        <Input
          id="lat3"
          type="text"
          name="緯度"
          obj={content}
          propName="gpsLatitude3"
        />
        &nbsp;
        <label htmlFor="lng3">経度</label>
        <Input
          id="lng3"
          type="text"
          name="経度"
          obj={content}
          propName="gpsLongitude3"
        />
        &nbsp;
        <label htmlFor="area3">半径</label>
        <Input
          id="area3"
          type="text"
          name="半径"
          obj={content}
          propName="gpsRadius3"
        />
      </div>

      <div className="indent">
        <input
          type="checkbox"
          checked={playLimitEnabled}
          onChange={setPlayLimitEnabled}
        />
        <label htmlFor="repeat1">
          範囲から外れた後、再度範囲に入ったときに再生する
        </label>
      </div>
      {playLimitEnabled && (
        <div className="indent">
          <label htmlFor="view1">再生回数</label>
          <Input
            type="text"
            name="再生回数"
            obj={content}
            propName="gpsPlayLimit"
          />
        </div>
      )}
    </>
  );
}
