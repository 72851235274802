import React, { ChangeEvent, useLayoutEffect, useReducer, useRef } from "react";
import { GuideVersion } from "./common";
import { InputFile } from "./components/InputFile";
import { ACCEPT_AUDIO, useUploader } from "./hooks/useUploader";
import $ from "jquery";
import { isGeneralUser } from "./Account-store";

/**
 * 音声ガイド編集画面 - 基本設定 - 開始音。
 */
export function GuidesEditBasicStartupSound(props: { guide: GuideVersion }) {
  const { guide } = props;

  const mediaRef = useRef<HTMLAudioElement>(null);

  const uploaderProps = useUploader({
    guideId: guide.guideId,
    obj: guide,
    propName: "startupSound",
    mediaRef,
    accept: ACCEPT_AUDIO,
  });

  const [enabled, setEnabled] = useReducer(
    (_: boolean, event: ChangeEvent<HTMLInputElement>) => {
      const enabled = event.target.checked;

      if (!enabled) {
        guide.startupSound = null;
        $("div.beep").hide(100);
      } else {
        $("div.beep").show(100);
      }

      return enabled;
    },
    !!guide.startupSound
  );

  const readOnly = isGeneralUser();

  useLayoutEffect(() => {
    if (!guide.startupSound) {
      $("div.beep").hide();
    }
  }, [guide]);

  return (
    <>
      <dt>開始音</dt>
      <dd>
        <input
          type="checkbox"
          value="開始音"
          id="beep"
          checked={enabled}
          onChange={setEnabled}
        />
        <label htmlFor="beep">開始音を設定する</label>
        <div className="beep">
          {!readOnly && (
            <InputFile id="audio_lang1" name="日本語音声" {...uploaderProps} />
          )}
          <audio ref={mediaRef} controls />
        </div>
      </dd>
    </>
  );
}
