import { Suspensive } from "react-suspensive";
import { User } from "./common";
import { fetchJsonApi } from "./fetcher";
import { phantomId } from "./utils/phantom-id";

/**
 * 現在編集中のユーザー。
 */
export const currentUser = new Suspensive<User>(createUser(-1));

// for debug
(window as any).$adminUser = currentUser;

/**
 * 指定されたユーザーのデータを fetch して、編集用にセットする。
 *
 * @param userId 取得対象のユーザーの ID
 */
export function fetchUser(userId: string) {
  currentUser.value = fetchJsonApi({
    type: "adminGetUser",
    userId: parseInt(userId, 10),
  }).then((res) => {
    if (!res.item) {
      throw new Error(`ユーザー ${userId} が見つかりませんでした。`);
    }

    return res.item;
  });

  return currentUser;
}

/**
 * 編集中のユーザーを新規作成用にクリアする。
 */
export function clearUser(organizationId: number) {
  currentUser.set(createUser(organizationId));

  return currentUser;
}

function createUser(organizationId: number): User {
  return {
    userId: phantomId(),
    email: "",
    name: "",
    name_kana: "",
    organizationId,
    privilegedKind: "general",
  };
}

/**
 * 編集中の内容を検証する。
 */
export function validateUser(item: User) {
  const errors: string[] = [];

  if (!item.name) {
    errors.push("「名前」を入力してください。");
  }

  if (!item.email) {
    errors.push("「メールアドレス」を入力してください。");
  }

  return errors;
}
