import React, { useRef } from "react";
import { isGeneralUser } from "./Account-store";
import { Content, GuideVersion } from "./common";
import { InputFile } from "./components/InputFile";
import { ACCEPT_IMAGE, useUploader } from "./hooks/useUploader";

/**
 * 音声ガイド編集画面 - コンテンツ設定 - 画像。
 */
export function GuidesEditContentsImage(props: {
  content: Content;
  guide: GuideVersion;
  defaultImageUrl?: string;
}) {
  const { content, guide, defaultImageUrl } = props;

  const imageRef = useRef<HTMLImageElement>(null);

  const uploaderProps = useUploader({
    guideId: guide.guideId,
    obj: content,
    propName: "image",
    imageRef,
    accept: ACCEPT_IMAGE,
    defaultImageUrl,
  });

  const readOnly = isGeneralUser();

  return (
    <div className="picture_content">
      <h2>画像</h2>
      <div className="indent">
        {!readOnly && <InputFile name="画像" id="myImage" {...uploaderProps} />}
        <img id="preview" ref={imageRef} />
      </div>
    </div>
  );
}
