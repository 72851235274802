import React from "react";
import { getDateString, toJST } from "../utils/date-util";

/**
 * 日付を表示する。
 *
 * @param props.date 日付文字列。
 * @returns
 */
export function DateSpan(props: { date: string }) {
  const jstDate = toJST(new Date(props.date));

  // 日付部分を取り出して . 区切りに変換。
  const date = getDateString(jstDate).replace(/\-/g, ".");

  return <span>{date}</span>;
}
