import { ContentByLanguage, GuideLanguage, GuideVersion } from "./common";
import { fetchJsonApi } from "./fetcher";

/**
 * 音声ガイドのステータスに対応したテキストを取得。
 *
 * @param status
 * @returns
 */
export function getGuideStatusText(status: string) {
  switch (status) {
    case "opened":
      return "公開中";
    case "notOpened":
      return "非公開";
    case "closed":
      return "終了";
    default:
      return "";
  }
}

/**
 * sortKey を持つ要素の配列のソート用比較関数。
 *
 * @param lhs
 * @param rhs
 * @returns
 */
export function compareBySortKey(
  lhs: { sortKey: number },
  rhs: { sortKey: number }
) {
  return lhs.sortKey - rhs.sortKey;
}

/**
 * priority を持つ要素の配列のソート用比較関数。
 *
 * @param lhs
 * @param rhs
 * @returns
 */
export function compareByPriority(
  lhs: { priority: number },
  rhs: { priority: number }
) {
  return lhs.priority - rhs.priority;
}

/**
 * ContentByLanguage の配列から指定した言語の Content を取得して返す。
 *
 * @param contentsByLanguages
 * @param guideLanguage
 * @returns
 */
export function findContentByLanguage(
  contentsByLanguages: ContentByLanguage[],
  guideLanguage: GuideLanguage
) {
  const content = contentsByLanguages.find(
    (c) => c.guideLanguageId === guideLanguage.guideLanguageId
  );

  if (!content) {
    throw Error(
      `${guideLanguage.name_ja}(${guideLanguage.name_en})のコンテンツが見つかりません。`
    );
  }

  return content;
}

export function findGuideLanguage(
  guide: GuideVersion,
  guideLanguageId: number
) {
  const guideLanguage = guide.guideLanguages.find(
    (gl) => gl.guideLanguageId === guideLanguageId
  );

  if (!guideLanguage) {
    throw Error(`対象の言語が見つかりません。`);
  }

  return guideLanguage;
}

/**
 * 音声ガイドのメディアファイルを取得するための署名済み URL を取得する。
 *
 * メディアファイルが未設定の場合は undefined を返す。
 *
 * @param guideId
 * @param file
 */
export function fetchSignedUrlOfGuideFile(
  guideId: number,
  file: string | null
) {
  if (!file) {
    return Promise.resolve(undefined);
  }

  const [uuid, ext] = file.split(".");

  return fetchJsonApi({
    type: "getSignedUrl",
    operation: "getObject",
    guideId,
    uuid,
    ext,
  }).then((res) => {
    return res.url;
  });
}
