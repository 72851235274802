import React, { useCallback, useReducer, useRef, useState } from "react";
import { Content, GuideVersion } from "./common";
import { GuidesEditPreviewContents } from "./GuidesEditPreviewContents";
import { GuidesEditPreviewLanguages } from "./GuidesEditPreviewLanguages";
import { GuidesEditPreviewTopImage } from "./GuidesEditPreviewTopImage";
import $ from "jquery";

/**
 * 音声ガイド編集画面 - プレビュー。
 *
 * Slick.js (https://kenwheeler.github.io/slick/) を使ってスライド表示を行う。
 *
 * audio, movie 要素を節約するため、現在表示中のコンテンツとその前後のみ
 * メディアファイルを表示する。
 */
export function GuidesEditPreview(props: {
  guide: GuideVersion;
  initialSlide?: number | undefined;
}) {
  const { guide, initialSlide } = props;

  const [language, setLanguage] = useState(guide.guideLanguages[0]);

  const [currentSlide, setCurrentSlide] = useState(0);

  const callbackRef = useCallback(
    (node) => {
      if (node !== null) {
        $(node)
          .slick({
            autoplay: false,
            dots: false,
            infinite: true,
            speed: 1000,
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplaySpeed: 3000,
            pauseOnHover: false,
            adaptiveHeight: true,
            initialSlide,
          })
          .on("afterChange", (_event, _slick, currentSlide) => {
            setCurrentSlide(currentSlide);
          });
      }
    },
    [setCurrentSlide, initialSlide]
  );

  //
  // キーワード検索。
  //
  const keywordInputRef = useRef<HTMLInputElement>(null);

  const searchButtonListener = useCallback(() => {
    const keyword = keywordInputRef.current?.value;
    if (!keyword) {
      return;
    }

    const re = new RegExp(keyword.replace(/[.*+?^${}()|[\]\\]/g, "\\$&"));

    const index = guide.contents.findIndex((content: Content) => {
      const text = `${content.guideNumber}${content.contentByLanguages
        .flatMap((cbl) => `${cbl.title}$${cbl.text}`)
        .join()}`;

      return text.match(re) !== null;
    });

    if (index < 0) {
      return;
    }

    $("div.preImage").slick("slickGoTo", index, true);
  }, [keywordInputRef, guide]);

  const clearButtonListener = useCallback(() => {
    if (keywordInputRef.current) {
      keywordInputRef.current.value = "";
    }
  }, [keywordInputRef]);

  return (
    <>
      <div>
        <div className="fr">
          <input type="text" ref={keywordInputRef} />
          <input
            type="button"
            value="検索"
            className="ml10"
            onClick={searchButtonListener}
          />
          <input
            type="button"
            value="クリア"
            className="ml10"
            onClick={clearButtonListener}
          />
        </div>
      </div>

      <div className="previewWrapper">
        <div className="preview">
          <GuidesEditPreviewTopImage guide={guide} />
          <GuidesEditPreviewLanguages
            guide={guide}
            onSelectLanguage={setLanguage}
          />
        </div>

        <div className="preImage" ref={callbackRef}>
          {guide.contents.map((content, index) => (
            <GuidesEditPreviewContents
              key={content.contentId}
              content={content}
              language={language}
              guide={guide}
              mediaEnabled={Math.abs(currentSlide - index) <= 1}
            />
          ))}
        </div>
      </div>
    </>
  );
}
