import React, { MouseEvent, useCallback } from "react";
import {
  accountPreviousEmail,
  fetchOrganization,
  fetchUser,
  preserveCurrentEmail,
  saveAccount,
  validateAccount,
} from "./Account-store";
import { changePassword } from "./AccountEditPassword";
import { signOut } from "./cognito";
import { notifyError, notifyInfo } from "./components/NotificationOverlay";
import { setProgressTask } from "./components/ProgressOverlay";

/**
 * アカウント編集画面 - 操作メニュー。
 */
export function AccountEditOperations(props: { onDiscard: () => void }) {
  const { onDiscard } = props;

  const saveClickListener = useCallback((event: MouseEvent) => {
    event.preventDefault();

    const errors = validateAccount();

    if (errors.length > 0) {
      notifyError({
        message: "アカウント情報の保存ができません。",
        listItems: errors,
      });

      return;
    }

    const task = preserveCurrentEmail()
      .then(saveAccount)
      .then(changePassword)
      .then(() => {
        // 自身のメールアドレスを変更した場合は、認可エラーを防ぐためにログアウトする。
        if (fetchUser().value.email !== accountPreviousEmail) {
          confirm(
            "アカウント情報の保存が完了しました。メールアドレスが変更されたためログアウトします。"
          );

          signOut();
          document.location.pathname = "/";
        }

        notifyInfo({
          message: "アカウント情報の保存が完了しました。",
        });
      })
      .catch((err) => {
        notifyError({
          message: "アカウント情報の保存が失敗しました。",
          err,
        });
      });

    setProgressTask(task);
  }, []);

  const discardClickListener = useCallback(
    (event: MouseEvent) => {
      event.preventDefault();

      if (confirm("変更を保存せずに破棄しますか？")) {
        fetchOrganization(true);
        fetchUser(true);
        onDiscard();
      }
    },
    [onDiscard]
  );

  const logOutClickListener = useCallback((event: MouseEvent) => {
    event.preventDefault();

    if (confirm("ログアウトしますか？")) {
      signOut();
      document.location.pathname = "/";
    }
  }, []);

  return (
    <ul className="operation">
      <li className="discard">
        <a onClick={saveClickListener}>保存</a>
      </li>
      <li className="discard">
        <a onClick={discardClickListener}>変更を破棄</a>
      </li>
      <li className="discard">
        <a onClick={logOutClickListener}>ログアウト</a>
      </li>
    </ul>
  );
}
