import React, { ChangeEvent, useCallback } from "react";
import { useLocalSuspensive, Wait } from "react-suspensive";
import { Guide, QRCode } from "./common";
import { notifyError, notifyInfo } from "./components/NotificationOverlay";
import { QUERY_LIMIT } from "./constants";
import { fetchJsonApi } from "./fetcher";
import { isPhantomId, phantomId } from "./utils/phantom-id";

/**
 * QRコード編集画面 - 音声ガイド。
 */
export function QREditGuide(props: { qrCode: QRCode }) {
  const { qrCode } = props;

  const guides = useLocalSuspensive(
    () =>
      fetchJsonApi({
        type: "queryGuides",
        limit: QUERY_LIMIT,
        offset: 0,
        status: "opened",
      })
        .then(async (res) => {
          const { items } = res;

          const isNewQRCode = isPhantomId(qrCode.guideId);

          // 新規作成のQRコードではなく、かつ、
          // queryGuides した結果に関連する音声ガイドがない時のみ
          // QRコードに関連した音声ガイドも取得して選択肢として追加する。
          if (
            !isNewQRCode &&
            !items.find((guide) => guide.guideId === qrCode.guideId)
          ) {
            const guide = await fetchJsonApi({
              type: "getGuideVersion",
              guideId: qrCode.guideId,
            })
              .then((res) => {
                notifyError({
                  message: `音声ガイド ${qrCode.guideId} が存在しません。`,
                });

                return res.item;
              })
              .catch((err) => {
                notifyError({
                  message: `音声ガイド ${qrCode.guideId} の取得に失敗しました。`,
                  err,
                });

                return undefined;
              });

            if (!guide) {
              return [] as Guide[];
            }

            items.push({ ...guide, organizationId: phantomId(), key: "" });
          }

          if (!items.length) {
            notifyInfo({
              message: `公開済みの音声ガイドがありません。`,
            });
          }

          // 新規作成QRコードの場合、初期状態の選択肢を追加。
          if (isNewQRCode) {
            items.unshift({
              guideId: 0,
              name_ja: "--音声ガイドを選択してください--",
            } as unknown as Guide);
          }

          return items;
        })
        .catch((err) => {
          return [] as Guide[];
        }),
    [qrCode.qrCodeId, qrCode.guideId]
  );

  const changeListener = useCallback(
    (event: ChangeEvent<HTMLSelectElement>) => {
      qrCode.guideId = parseInt(event.target.value, 10);
    },
    [qrCode]
  );

  return (
    <>
      <dt className="must">音声ガイド名</dt>
      <Wait
        suspensive={guides}
        render={(guides) => (
          <dd>
            <select
              name="コンテンツ"
              defaultValue={isPhantomId(qrCode.guideId) ? 0 : qrCode.guideId}
              onChange={changeListener}
            >
              {guides.map((guide) => (
                <option key={guide.guideId} value={guide.guideId}>
                  {guide.name_ja}
                </option>
              ))}
            </select>
          </dd>
        )}
      />
    </>
  );
}
