import React from "react";
import { GuideVersion } from "./common";
import { Input } from "./components/Input";
import { TextArea } from "./components/TextArea";
import {
  MAX_LENGTH_GUIDES_AUX_GUIDE_ID,
  MAX_LENGTH_GUIDES_NAME,
} from "./constants";
import { GuidesEditBasicAntitheft } from "./GuidesEditBasicAntitheft";
import { GuidesEditBasicLanguages } from "./GuidesEditBasicLanguages";
import { GuidesEditBasicLimit } from "./GuidesEditBasicLimit";
import { GuidesEditBasicPeriod } from "./GuidesEditBasicPeriod";
import { GuidesEditBasicPlayMethods } from "./GuidesEditBasicPlayMethods";
import { GuidesEditBasicQRCode } from "./GuidesEditBasicQRCode";
import { GuidesEditBasicStartupSound } from "./GuidesEditBasicStartupSound";
import { GuidesEditBasicTopImage } from "./GuidesEditBasicTopImage";
import { GuidesEditBasicDelivery } from "./GuidesEditBasicDelivery";
import { isRekihaku } from "./utils/util";

/**
 * 音声ガイド編集画面 - 基本設定。
 */
export function GuidesEditBasic(props: { guide: GuideVersion }) {
  const { guide } = props;

  return (
    <div className="contentsInfoWrapper">
      <GuidesEditBasicTopImage guide={guide} />

      <dl className="contentsInfo">
        <dt className="must">音声ガイド名</dt>
        <dd>
          <label className="must">日本語表記</label>
          <Input
            type="text"
            name="音声ガイド名"
            className="full marginBottom"
            obj={guide}
            propName={"name_ja"}
            maxLength={MAX_LENGTH_GUIDES_NAME}
          />

          <label>英語表記</label>
          <Input
            type="text"
            name="音声ガイド名(英語表記)"
            className="full marginBottom"
            obj={guide}
            propName={"name_en"}
            maxLength={MAX_LENGTH_GUIDES_NAME}
          />
        </dd>

        {!isRekihaku() && (
          <>
            <dt>音声ガイド説明</dt>
            <dd>
              <label>日本語表記</label>
              <TextArea
                name="音声ガイド説明"
                className="full"
                obj={guide}
                propName={"description_ja"}
              />

              <label>英語表記</label>
              <TextArea
                name="音声ガイド説明(英語表記)"
                className="full"
                obj={guide}
                propName={"description_en"}
              />
            </dd>

            <dt>音声ガイドID</dt>
            <dd>
              <Input
                type="text"
                name="音声ガイドID"
                className="full"
                obj={guide}
                propName={"auxGuideId"}
                maxLength={MAX_LENGTH_GUIDES_AUX_GUIDE_ID}
              />
            </dd>
          </>
        )}

        <GuidesEditBasicPeriod guide={guide} />
        <GuidesEditBasicLanguages guide={guide} />
        <GuidesEditBasicStartupSound guide={guide} />
        <GuidesEditBasicPlayMethods guide={guide} />
        <GuidesEditBasicDelivery guide={guide} />
        {!isRekihaku() && <GuidesEditBasicQRCode guide={guide} />}
        {!isRekihaku() && <GuidesEditBasicLimit guide={guide} />}
        <GuidesEditBasicAntitheft guide={guide} />
      </dl>
    </div>
  );
}
