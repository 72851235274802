import React from "react";
import { useLocalSuspensive, Wait } from "react-suspensive";
import { fetchContentDefaultImageUrl } from "./Account-store";
import { Content, GuideLanguage, GuideVersion } from "./common";
import {
  fetchSignedUrlOfGuideFile,
  findContentByLanguage,
} from "./Guides-util";

/**
 * 音声ガイド編集画面 - プレビュー - コンテンツ。
 */
export function GuidesEditPreviewContents(props: {
  content: Content;
  language: GuideLanguage;
  guide: GuideVersion;
  mediaEnabled: boolean;
}) {
  const { content, language, guide, mediaEnabled } = props;
  const { contentByLanguages } = content;

  const contentByLanguage = findContentByLanguage(contentByLanguages, language);

  const imageUrl = useLocalSuspensive(
    () => fetchSignedUrlOfGuideFile(guide.guideId, content.image),
    [guide.guideId, content.image]
  );

  const movieUrl = useLocalSuspensive(
    () => fetchSignedUrlOfGuideFile(guide.guideId, content.movie),
    [guide.guideId, content.movie]
  );

  const audioUrl = useLocalSuspensive(
    () => fetchSignedUrlOfGuideFile(guide.guideId, contentByLanguage.voice),
    [guide.guideId, contentByLanguage.voice]
  );

  const contentDefaultImageUrl = fetchContentDefaultImageUrl();

  return (
    <div className="preContents">
      <dl className="preview">
        <dt>{content.guideNumber}</dt>
        <dd>{contentByLanguage.title}</dd>
      </dl>
      <div className="preImageWrapper">
        <Wait
          suspensive={imageUrl}
          render={(imageUrl) =>
            imageUrl ? (
              <img src={imageUrl} alt="" />
            ) : (
              <Wait
                suspensive={contentDefaultImageUrl}
                render={(contentDefaultImageUrl) =>
                  contentDefaultImageUrl && (
                    <img src={contentDefaultImageUrl} alt="" />
                  )
                }
              />
            )
          }
        />
      </div>
      {mediaEnabled && (
        <Wait
          suspensive={movieUrl}
          render={(movieUrl) => {
            // 動画がある時は動画、ない場合は音声のプレビュー。
            return movieUrl ? (
              <video controls src={movieUrl} />
            ) : (
              <Wait
                suspensive={audioUrl}
                render={(audioUrl) =>
                  audioUrl && (
                    <audio controls controlsList="nodownload" src={audioUrl} />
                  )
                }
              />
            );
          }}
        />
      )}
      <div className="preTextWrapper">{contentByLanguage.text}</div>
    </div>
  );
}
