"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Wait = exports.setDefaultFallback = void 0;
const react_1 = __importStar(require("react"));
const Observer_1 = require("./Observer");
let defaultFallback = 'Loading...';
function setDefaultFallback(node) {
    defaultFallback = node;
}
exports.setDefaultFallback = setDefaultFallback;
/**
 * `Wait` waits `Suspensive` value with using `Suspense`, and render the resolved value.
 *
 * After `Suspensive` is resolved, the `render` prop is called with the resolved value.
 * While waiting, it shows the `fallback` prop.
 *
 * ```
 * <Wait suspensive={suspensive}
 *   fallback={<div>Loading...</div>}
 *   render={value =>
 *     <MyComponent value={value} />
 *   } />
 * ```
 *
 * If the `fallback` prop omits, the default value, which can be set using
 * `setDefaultFallback()`, will be used.
 *
 * ```
 * <Wait suspensive={suspensive} render={value =>
 *   <MyComponent value={value} />
 * } />
 * ```
 *
 * The `renderAlways` prop can be used instead of `render` and `fallback` props.
 * It renders contents based on waiting status and the resolved value.
 *
 * ```
 * <Wait suspensive={suspensive} renderAlways={(waiting, value) =>
 *   <Button onClick={reload} disabled={waiting}>Reload</Button>
 *   { waiting || <MyComponent value={value} /> }
 * } />
 * ```
 */
function Wait(props) {
    var _a;
    const { suspensive, transient } = props;
    (0, Observer_1.useObserver)(suspensive);
    const pending = transient && suspensive.hasFallback();
    if ('renderAlways' in props) {
        return react_1.default.createElement(react_1.Suspense, { fallback: react_1.default.createElement(RenderAlways, { suspensive: suspensive, waiting: true, pending: pending, renderAlways: props.renderAlways }), children: react_1.default.createElement(RenderAlways, { suspensive: suspensive, renderAlways: props.renderAlways }) });
    }
    else {
        return react_1.default.createElement(react_1.Suspense, { fallback: pending
                ? react_1.default.createElement(Render, { suspensive: suspensive, pending: true, render: props.render })
                : ((_a = props.fallback) !== null && _a !== void 0 ? _a : defaultFallback), children: react_1.default.createElement(Render, { suspensive: suspensive, render: props.render }) });
    }
}
exports.Wait = Wait;
function Render({ suspensive, render, pending }) {
    return react_1.default.createElement(react_1.default.Fragment, null, render(pending ? suspensive.fallback : suspensive.value, pending));
}
function RenderAlways({ waiting, renderAlways, suspensive, pending }) {
    return react_1.default.createElement(react_1.default.Fragment, null, waiting
        ? renderAlways(true, undefined, pending)
        : renderAlways(false, pending ? suspensive.fallback : suspensive.value, pending));
}
