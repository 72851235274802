import React, { useCallback, useRef, useState } from "react";
import { Content, GuideVersion } from "./common";
import { Input } from "./components/Input";
import { GuidesEditContentsTitle } from "./GuidesEditContentsTitle";
import {
  GuidesEditContentsItemDetailCommands,
  GuidesEditContentsItemDetail,
} from "./GuidesEditContentsItemDetail";

/**
 * 音声ガイド編集画面 - コンテンツ設定 - コンテンツ。
 *
 * コンテンツ設定画面の１コンテンツ分。
 */
export function GuidesEditContentsItem(props: {
  content: Content;
  guide: GuideVersion;
  onDelete: (content: Content) => void;
  showPreview: (initialSlide: number) => void;
  index: number;
}) {
  const { content, guide, onDelete, showPreview, index } = props;

  const [contentsOptionVisible, setContentsOptionVisible] = useState(true);

  const detailCommandsRef = useRef<GuidesEditContentsItemDetailCommands>(null);

  const closeButtonListener = useCallback(() => {
    const next = !contentsOptionVisible;
    setContentsOptionVisible(next);
    detailCommandsRef.current?.show(next);
  }, [contentsOptionVisible, setContentsOptionVisible, detailCommandsRef]);

  return (
    <div className="contentsBox">
      <p className="close" onClick={closeButtonListener}>
        {contentsOptionVisible ? "閉じる" : "編集"}
      </p>
      <p className="delete" onClick={() => onDelete(content)}>
        削除
      </p>
      <p className="preview" onClick={() => showPreview(index)}>
        プレビュー
      </p>
      <div className="contentsHeader">
        <div className="number">
          <label htmlFor="number" className="must">
            ガイド番号
          </label>
          <Input
            type="number"
            name="ガイド番号"
            className="number full"
            min={0}
            obj={content}
            propName="guideNumber"
          />
        </div>
        <GuidesEditContentsTitle content={content} guide={guide} />
      </div>
      <GuidesEditContentsItemDetail
        content={content}
        guide={guide}
        commandsRef={detailCommandsRef}
      />
    </div>
  );
}
