import React from "react";
import { useRouteMatch } from "react-router";
import { Link } from "react-router-dom";
import { Suspensive, useLocalSuspensive, Wait } from "react-suspensive";
import { QRCode } from "./common";
import { QueryQRCodesResponse } from "./common/QueryQRCodesResponse";
import { DateSpan } from "./components/DateSpan";
import { fetchJsonApi } from "./fetcher";
import { getGuideStatusText } from "./Guides-util";
import { useQueryParams } from "./hooks/useQueryParams";
import { safeParseInt } from "./utils/util";

const limit = 20;

/**
 * QRコード一覧画面。
 */
export function QRList() {
  const query = useQueryParams<{ offset: string }>();

  const offset = safeParseInt(query.offset);

  const qrCodes = useLocalSuspensive(
    () =>
      fetchJsonApi({
        type: "queryQRCodes",
        limit,
        offset,
      }),
    [offset]
  );

  return (
    <>
      <table className="contentsList">
        <QRListHead />
        <QRListBody qrCodes={qrCodes} />
      </table>

      <Wait
        suspensive={qrCodes}
        render={(qrCodes) =>
          qrCodes.totalCount > 0 && (
            <div className="listPaginate">
              <span className="current">
                {offset + 1}-{Math.min(offset + limit, qrCodes.totalCount)}
              </span>
              /<span className="total">{qrCodes.totalCount}</span>
              <Link
                to={{ search: `?offset=${Math.max(0, offset - limit)}` }}
                className="prev"
              />
              <Link
                to={{
                  search: `?offset=${
                    offset + limit >= qrCodes.totalCount
                      ? offset
                      : offset + limit
                  }`,
                }}
                className="next"
              />
            </div>
          )
        }
      />
    </>
  );
}

function QRListHead() {
  return (
    <thead>
      <tr>
        <th>QRコード名</th>
        <th>QRコードID</th>
        <th>開始日</th>
        <th>終了日</th>
        <th></th>
        <th></th>
        <th>ステータス</th>
      </tr>
    </thead>
  );
}

function QRListBody(props: { qrCodes: Suspensive<QueryQRCodesResponse> }) {
  const { qrCodes } = props;

  return (
    <Wait
      suspensive={qrCodes}
      render={(qrCodes) => (
        <tbody>
          {qrCodes.items.map((qrCode) => (
            <QRListItem key={qrCode.qrCodeId} qrCode={qrCode} />
          ))}
        </tbody>
      )}
      fallback={<tbody></tbody>}
    />
  );
}

function QRListItem(props: { qrCode: QRCode }) {
  const { qrCode } = props;

  const { url } = useRouteMatch();

  return (
    <tr>
      <td>{qrCode.name}</td>
      <td>{qrCode.auxQRCodeId}</td>
      <td>
        <DateSpan date={qrCode.periodBegin} />
      </td>
      <td>
        <DateSpan date={qrCode.periodEnd} />
      </td>
      <td>
        <Link to={`${url}/${qrCode.qrCodeId}`}>
          {!qrCode.printedAt ? "編集" : "閲覧"}
        </Link>
      </td>
      <td>
        <Link to={`${url}/new?copy=${qrCode.qrCodeId}`}>
          コピーして新規作成
        </Link>
      </td>
      <td>
        <span className={qrCode.status === "opened" ? "publish" : undefined}>
          {getGuideStatusText(qrCode.status || "")}
        </span>
      </td>
    </tr>
  );
}
