import React, { ChangeEvent, Ref, useCallback } from "react";

export interface InputProps<T>
  extends Omit<React.InputHTMLAttributes<HTMLInputElement>, "type"> {
  type: "text" | "date" | "checkbox" | "number" | "password" | "radio";

  /**
   * 入力値をセットするオブジェクト。
   */
  obj: T;

  /**
   * 入力値をセットするプロパティ名。
   */
  propName: keyof T;

  inputRef?: Ref<HTMLInputElement>;
}

/**
 * input のカスタマイズ版。
 *
 * 入力された値を props で指定したオブジェクトの指定プロパティに反映する。
 *
 * type="checked" に対しては、対象のプロパティは number である必要があり、
 * 0: unchecked, 1: checked としてマッピングする。
 */
export function Input<T>(props: InputProps<T>) {
  const { type, obj, propName, inputRef, onChange, ...restProps } = props;

  const changeListener = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      if (type === "checkbox") {
        (obj[propName] as unknown as number) = event.target.checked ? 1 : 0;
      } else {
        (obj[propName] as unknown as string) = event.target.value;
      }

      if (onChange) {
        onChange(event);
      }
    },
    [type, obj, propName, onChange]
  );

  if (type === "checkbox") {
    return (
      <input
        ref={inputRef}
        type={type}
        defaultChecked={!!obj[propName]}
        onChange={changeListener}
        {...restProps}
      />
    );
  } else if (type === "radio") {
    return (
      <input
        ref={inputRef}
        type={type}
        defaultChecked={
          (obj[propName] as unknown as string) === restProps.value
        }
        onChange={changeListener}
        {...restProps}
      />
    );
  } else {
    return (
      <input
        ref={inputRef}
        type={type}
        defaultValue={obj[propName] as any}
        onChange={changeListener}
        {...restProps}
      />
    );
  }
}
