import React, { ChangeEvent, useReducer } from "react";
import { Content, GuideVersion } from "./common";
import { TextArea } from "./components/TextArea";
import { findContentByLanguage } from "./Guides-util";

/**
 * 音声ガイド編集画面 - コンテンツ設定 - テキスト。
 */
export function GuidesEditContentsText(props: {
  content: Content;
  guide: GuideVersion;
}) {
  const {
    content,
    guide: { guideLanguages },
  } = props;
  const { contentByLanguages, contentId } = content;

  // 「テキストを表示する」チェックボックス。
  // 初期状態は何か１つでもテキストあればチェック。
  // チェック外すと全ての言語のテキストをクリア。
  const [textVisible, textVisibleChangeListener] = useReducer(
    (_: boolean, event: ChangeEvent<HTMLInputElement>) => {
      const enabled = event.target.checked;

      if (!enabled) {
        content.textVisible = 0;
      } else {
        content.textVisible = 1;
      }

      return event.target.checked;
    },
    !!content.textVisible
  );

  const checkboxId = `${contentId}-checkbox`;

  return (
    <>
      <h2>テキスト</h2>
      <div className="indent">
        <input
          type="checkbox"
          value="テキストを表示する"
          id="checkboxId"
          checked={textVisible}
          onChange={textVisibleChangeListener}
        />
        <label htmlFor={checkboxId}>テキストを表示する</label>
      </div>
      {guideLanguages.map((guideLanguage) => {
        const contentByLanguage = findContentByLanguage(
          contentByLanguages,
          guideLanguage
        );
        const key = `${contentByLanguage.contentByLanguageId}-text:${textVisible}`;

        return (
          <div className="indent" key={key}>
            <label htmlFor={key}>
              {guideLanguage.name_ja} ({guideLanguage.name_en})
            </label>
            <TextArea
              id={key}
              disabled={!textVisible}
              obj={contentByLanguage}
              propName="text"
            />
          </div>
        );
      })}
    </>
  );
}
