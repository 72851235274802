import React from "react";

/**
 * Not Found ページ。
 */
export function NotFound() {
  return (
    <main>
      <section>
        <h2>Not Found</h2>
      </section>
    </main>
  );
}
