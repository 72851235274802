import React, { MouseEvent, useCallback, useRef, useState } from "react";
import { confirmPassword, forgotPassword } from "./cognito";
import { notifyError } from "./components/NotificationOverlay";
import logoSvg from "./images/logo.svg";
import { changeToPasswordInput } from "./utils/react-util";
import { isRekihaku } from "./utils/util";

export function ForgetPassword() {
  const emailInput = useRef<HTMLInputElement>(null);
  const codeInput = useRef<HTMLInputElement>(null);
  const newPasswordInput = useRef<HTMLInputElement>(null);
  const newPasswordConfirmInput = useRef<HTMLInputElement>(null);

  const [email, changeEmail] = useState("");
  const [formType, changeFormType] = useState<"email" | "password">("email");

  const forgotPasswordListener = useCallback(
    (event: MouseEvent) => {
      event.preventDefault();

      const email = emailInput.current?.value;

      if (!email) {
        return;
      }

      forgotPassword(email)
        .then(() => {
          changeFormType("password");
          changeEmail(email);
        })
        .catch((err: Error) => {
          if (err.name === "UserNotFoundException") {
            notifyError({
              message: "指定されたメールアドレスが見つかりません。",
            });
          } else if (err.name === "LimitExceededException") {
            notifyError({
              message:
                "一時的にロックされているため、パスワード変更ができません。時間をおいて再度実行してください。",
            });
          } else {
            notifyError({
              message: "メールの送信に失敗しました。",
              err,
            });
            throw err;
          }
        });
    },
    [changeFormType, changeEmail]
  );

  const changePasswordListener = useCallback(
    (event: MouseEvent) => {
      event.preventDefault();

      const code = codeInput.current!.value;
      const newPassword = newPasswordInput.current!.value;
      const newPasswordConfirm = newPasswordConfirmInput.current!.value;

      const errors = validateConfirmPassword(
        code,
        newPassword,
        newPasswordConfirm
      );

      if (errors.length > 0) {
        notifyError({
          message: "パスワードの変更が出来ません。",
          listItems: errors,
        });

        return;
      }

      //メールの文面から検証コードをコピーするとき末尾に空白文字を入れてしまうので自動的に削る。
      const trimedCode = code.trim();

      confirmPassword(email, trimedCode, newPassword)
        .then(() => {
          confirm(
            "パスワード変更が完了しました。ログイン画面に移動しますので新しいパスワードを入力して下さい。"
          );

          changeFormType("email");
          changeEmail("");

          document.location.pathname = "/";
        })
        .catch((err) => {
          if (err.name === "UserNotFoundException") {
            notifyError({
              message: "指定されたメールアドレスが見つかりません。",
            });
          } else if (err.name === "LimitExceededException") {
            notifyError({
              message:
                "一時的にロックされているため、パスワード変更ができません。時間をおいて再度実行してください。",
            });
          } else if (err.name === "CodeMismatchException") {
            notifyError({
              message: "検証コードが正しくありません。",
            });
          } else if (err.name === "ExpiredCodeException") {
            notifyError({
              message:
                "検証コードの有効期限が過ぎています。もう一度メールアドレスを送信して下さい。",
            });
          } else {
            notifyError({
              message: "パスワードの変更に失敗しました。",
              err,
            });
            throw err;
          }
        });
    },
    [email, changeEmail, changeFormType]
  );

  return (
    <div className="contentsWrapper">
      <div className="contents">
        <div className="login">
          {isRekihaku() ? (
            <span className="login_rehikaku_logo"></span>
          ) : (
            <img className="login_logo" src={logoSvg} alt="" />
          )}
          {formType === "email" ? (
            <>
              <p className="mb20">
                メールアドレスを入力して送信してください。
                <br />
                パスワード再設定のメールをお送りしますので、本文のリンクからパスワードを再設定してください。
              </p>

              <label htmlFor="email">メールアドレス</label>
              <input
                type="email"
                name="email"
                id="email"
                key="email"
                ref={emailInput}
              />

              <input
                type="submit"
                value="送信"
                className="login"
                onClick={forgotPasswordListener}
              />
            </>
          ) : (
            <>
              <p className="mb20">
                指定されたメールアドレスに検証コードをお送りしました。
                <br />
                検証コードと新しいパスワードを入力してください。
              </p>

              <label htmlFor="code">検証コード</label>
              <input
                type="text"
                name="code"
                id="code"
                key="code"
                ref={codeInput}
                autoComplete="off"
              />

              <label htmlFor="newPassword">新しいパスワード</label>
              <input
                type="text"
                name="newPassword"
                id="newPasswordConfirm"
                key="newPassword"
                ref={newPasswordInput}
                autoComplete="off"
                onFocus={changeToPasswordInput}
              />

              <label htmlFor="newPasswordConfirm">新しいパスワード(確認)</label>
              <input
                type="text"
                name="newPasswordConfirm"
                id="id"
                key="newPasswordConfirm"
                ref={newPasswordConfirmInput}
                autoComplete="off"
                onFocus={changeToPasswordInput}
              />

              <input
                type="submit"
                value="パスワードを再設定"
                className="login"
                onClick={changePasswordListener}
              />
            </>
          )}
        </div>
      </div>
    </div>
  );
}

function validateConfirmPassword(
  code: string = "",
  newPassword: string = "",
  newPasswordConfirm: string = ""
) {
  const errors: string[] = [];

  if (!code) {
    errors.push("検証コードを入力してください。");
  }

  if (newPassword !== newPasswordConfirm) {
    errors.push(
      "新しいパスワードと新しいパスワード（確認）には同じパスワードを入力してください。"
    );
  }

  if (newPassword.length < 8) {
    errors.push("パスワードは８文字以上入力してください。");
  }

  if (!newPassword.match(/[0-9]/)) {
    errors.push("パスワードには数字を１文字以上入力してください。");
  }

  if (!newPassword.match(/[a-z]/)) {
    errors.push(
      "パスワードには小文字アルファベットを１文字以上入力してください。"
    );
  }

  if (!newPassword.match(/[A-Z]/)) {
    errors.push(
      "パスワードには大文字アルファベットを１文字以上入力してください。"
    );
  }

  if (!newPassword.match(/[\^$*.\[\]{}\(\)?\-“!@#%&\/,><\’:;|_~`]/)) {
    errors.push(
      "パスワードには記号 = + - ^ $ * . [ ] { } ( ) ? \" ! @ # % & /  , > < ' : ; | _ ~ ` のいずれかを１文字以上入力してください。"
    );
  }

  return errors;
}
