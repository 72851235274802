import React, { ChangeEvent, useCallback } from "react";

export interface TextAreaProps<T>
  extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
  /**
   * 入力値をセットするオブジェクト。
   */
  obj: T;

  /**
   * 入力値をセットするプロパティ名。
   */
  propName: keyof T;
}

/**
 * textarea のカスタマイズ版。
 *
 * 入力された値を props で指定したオブジェクトの指定プロパティに反映する。
 */
export function TextArea<T>(props: TextAreaProps<T>) {
  const { obj, propName, ...restProps } = props;

  const changeListener = useCallback(
    (event: ChangeEvent<HTMLTextAreaElement>) => {
      (obj[propName] as unknown as string) = event.target.value;
    },
    [obj, propName]
  );

  return (
    <textarea
      defaultValue={obj[propName] as any}
      onChange={changeListener}
      {...restProps}
    />
  );
}
