import React, { createElement, ReactNode } from "react";
import {
  BrowserRouter,
  Redirect,
  Route,
  RouteProps,
  Switch,
  useRouteMatch,
} from "react-router-dom";
import { getSignedInUser, isSignedIn, restoreSession } from "./cognito";
import { ErrorBoundary } from "./components/ErrorBoundary";
import { ForgetPassword } from "./ForgotPassword";
import { NotFound } from "./NotFound";
import { RenderPromise } from "./components/RenderPromise";
import { LogIn } from "./LogIn";
import { Guides } from "./Guides";
import { Collections } from "./Collections";
import { QR } from "./QR";
import { Reports } from "./Reports";
import { ReportsPlayData } from "./ReportsPlayData";
import { Account } from "./Account";
import { Wait } from "react-suspensive";
import { fetchOrganization, fetchUser } from "./Account-store";
import { ProgressOverlay } from "./components/ProgressOverlay";
import { NotificationOverlay } from "./components/NotificationOverlay";
import { PrintQR } from "./PrintQR";
import { AdminOrganizations } from "./AdminOrganizations";
import { Users } from "./Users";
import { isRekihaku } from "./utils/util";

/**
 * アプリケーションのルートコンポーネント。
 *
 * パスに応じた画面を表示する。
 */
export function App() {
  const isPrint = window.matchMedia("print").matches;
  const className = !isPrint ? "min-h-screen bg-gray-200" : undefined;

  return (
    <div className={className}>
      <BrowserRouter>
        <ErrorBoundary>
          <Switch>
            <Route exact path={`/forgot-password`} component={ForgetPassword} />
            <Route exact path={`/login`} component={LogIn} />
            <ProtectedRoute exact path={`/`} component={Top} />
            <ProtectedRoute path={`/:tenantId`} component={Tenant} />
          </Switch>
          {!isPrint && (
            <>
              <ProgressOverlay />
              <NotificationOverlay />
            </>
          )}
        </ErrorBoundary>
      </BrowserRouter>
    </div>
  );
}

/**
 * トップページアクセス。
 *
 * テナントの音声ガイド画面へリダイレクトさせる。
 */
function Top() {
  const organization = fetchOrganization();

  return (
    <Wait
      suspensive={organization}
      render={(organization) => (
        <Redirect to={`/${organization.tenantId}/guides`} />
      )}
    />
  );
}

/**
 * テナント別のルーティング定義。
 */
export function Tenant() {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route path={`${path}/account`} component={Account} />
      <Route
        path={`${path}/admin/organizations`}
        component={AdminOrganizations}
      />
      <Route path={`${path}/collections`} component={Collections} />
      <Route path={`${path}/guides`} component={Guides} />
      <Route path={`${path}/print/qr/:qrCodeId`} component={PrintQR} />
      <Route path={`${path}/qr`} component={QR} />
      <Route
        path={`${path}/reports`}
        component={isRekihaku() ? ReportsPlayData : Reports}
      />
      <Route path={`${path}/users`} component={Users} />
      <Route path={`${path}*`} component={NotFound} />
    </Switch>
  );
}

/**
 * 保護されたページへのルートを定義するコンポーネント。
 *
 * サインインしていない場合、サインイン画面にリダイレクトする。
 *
 * サインインしている場合でもセッションが無効な場合、復元を試みる。
 * セッションの復元に失敗した場合はサインイン画面にリダイレクトする。
 */
function ProtectedRoute(
  props: Omit<RouteProps, "children"> & { component: React.ComponentType<any> }
) {
  const { component, ...rest } = props;

  return (
    <Route
      {...rest}
      render={({ location }) => {
        if (!isSignedIn()) {
          return renderRedirect();
        } else if (!getSignedInUser().getSignInUserSession()) {
          return (
            <RenderPromise
              promise={restoreSession()}
              renderOnResolved={renderContents}
              renderOnRejected={renderRedirect}
            />
          );
        } else {
          return renderContents();
        }

        function renderRedirect() {
          return (
            <Redirect
              to={{
                pathname: `/login`,
                state: { from: location },
              }}
            />
          );
        }

        function renderContents() {
          return <FetchAccount>{createElement(component)}</FetchAccount>;
        }
      }}
    />
  );
}

/**
 * サインイン済みの際にアカウント情報を取得した状態で
 * 子コンポーネントをレンダリングする。
 */
function FetchAccount(props: { children: ReactNode }) {
  const organization = fetchOrganization();
  const user = fetchUser();

  return (
    <Wait
      suspensive={organization}
      render={() => (
        <Wait suspensive={user} render={() => <>{props.children}</>} />
      )}
    />
  );
}
