import React from "react";
import { Input } from "./components/Input";
import { changePassword as cognitoChangePassword } from "./cognito";

export interface PasswordForm {
  oldPassword: string;
  newPassword: string;
  newPasswordConfirm: string;
}

export const passwordForm: PasswordForm = {
  oldPassword: "",
  newPassword: "",
  newPasswordConfirm: "",
};

/**
 * パスワード変更処理。
 */
export async function changePassword() {
  console.log(passwordForm);
  const { oldPassword, newPassword, newPasswordConfirm } = passwordForm;

  if (oldPassword) {
    if (!newPassword || !newPasswordConfirm) {
      throw new Error("新しいパスワードを入力してください。");
    }

    if (newPassword !== newPasswordConfirm) {
      throw new Error(
        "新しいパスワードと新しいパスワード（確認）には同じパスワードを入力してください。"
      );
    }

    if (newPassword.length < 8) {
      throw new Error("パスワードは８文字以上入力してください。");
    }

    if (!newPassword.match(/[0-9]/)) {
      throw new Error("パスワードには数字を１文字以上入力してください。");
    }

    if (!newPassword.match(/[a-z]/)) {
      throw new Error(
        "パスワードには小文字アルファベットを１文字以上入力してください。"
      );
    }

    if (!newPassword.match(/[A-Z]/)) {
      throw new Error(
        "パスワードには大文字アルファベットを１文字以上入力してください。"
      );
    }

    if (!newPassword.match(/[\^$*.\[\]{}\(\)?\-“!@#%&\/,><\’:;|_~`]/)) {
      throw new Error(
        "パスワードには記号 = + - ^ $ * . [ ] { } ( ) ? \" ! @ # % & /  , > < ' : ; | _ ~ ` のいずれかを１文字以上入力してください。"
      );
    }
  } else {
    if (!newPassword && !newPasswordConfirm) {
      // パスワード入力ない場合は何もしない。
      return;
    } else {
      throw new Error("現在のパスワードを入力してください。");
    }
  }

  return cognitoChangePassword(oldPassword, newPassword).catch((err) => {
    if (err.code === "LimitExceededException") {
      throw new Error(
        "一時的にロックされているため、パスワード変更ができません。時間をおいて再度実行してください。"
      );
    }

    return err;
  });
}

/**
 * アカウント編集画面 - パスワード。
 */
export function AccountEditPassword() {
  return (
    <>
      <dt>パスワード</dt>
      <dd>
        <Input
          type="password"
          className="half"
          placeholder="現在のパスワード"
          obj={passwordForm}
          propName="oldPassword"
          maxLength={99}
        />
        <br />
        <Input
          type="password"
          className="half"
          placeholder="新しいパスワード"
          obj={passwordForm}
          propName="newPassword"
          maxLength={99}
        />
        <br />
        <Input
          type="password"
          className="half"
          placeholder="新しいパスワード（確認）"
          obj={passwordForm}
          propName="newPasswordConfirm"
          maxLength={99}
        />
      </dd>
    </>
  );
}
