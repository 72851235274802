import React from "react";
import { Link, useRouteMatch } from "react-router-dom";
import { Wait } from "react-suspensive/lib";
import { fetchOrganization, fetchUser } from "./Account-store";
import { preventDefault } from "./utils/react-util";
import { isRekihaku } from "./utils/util";

/**
 * サイトトップのナビゲーター。
 */
export function SiteNav() {
  const match =
    useRouteMatch<{
      tenantId: string;
      type:
        | "guides"
        | "collections"
        | "qr"
        | "reports"
        | "account"
        | "admin"
        | "users";
    }>("/:tenantId/:type");

  if (!match) {
    throw new Error("Route not matched");
  }

  const { type, tenantId } = match.params;

  const organization = fetchOrganization();
  const user = fetchUser();

  return (
    <nav>
      <ul id="nav">
        <li className={type === "guides" ? "active" : undefined}>
          <Link to={`/${tenantId}/guides`}>音声ガイド管理</Link>
        </li>
        {!isRekihaku() && false && (
          <li className={type === "collections" ? "active" : undefined}>
            <Link to={`/${tenantId}/collections`} onClick={preventDefault}>
              収蔵品管理
            </Link>
          </li>
        )}
        {!isRekihaku() && (
          <li className={type === "qr" ? "active" : undefined}>
            <Link to={`/${tenantId}/qr`}>QRコード</Link>
          </li>
        )}
        <li className={type === "reports" ? "active" : undefined}>
          <Link to={`/${tenantId}/reports`}>レポート</Link>
        </li>
        <li className={type === "account" ? "active" : undefined}>
          <Link to={`/${tenantId}/account`}>
            {isRekihaku() ? "基本設定" : "アカウント管理"}
          </Link>
        </li>
        <Wait
          suspensive={user}
          render={(user) =>
            user.privilegedKind === "admin" && (
              <li className={type === "users" ? "active" : undefined}>
                <Link to={`/${tenantId}/users`}>ユーザー管理</Link>
              </li>
            )
          }
        />
        <Wait
          suspensive={organization}
          render={(organization) =>
            organization.privileged !== 0 ? (
              <li className={type === "admin" ? "active" : undefined}>
                <Link to={`/${tenantId}/admin/organizations`}>施設管理</Link>
              </li>
            ) : (
              <li>
                <Link to="/static/contact/contact.html" target="_blank">
                  問合せ
                </Link>
              </li>
            )
          }
        />
      </ul>
    </nav>
  );
}
